import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner, Card } from "react-bootstrap";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5";
import NoContent from "../Meeting/NoContent";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
import { askPermission } from "../../Utils/askPermission";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Avatar, Tooltip } from "antd";
import { AiOutlinePlaySquare } from "react-icons/ai";
import { RiEditBoxLine, RiPresentationFill } from "react-icons/ri";
import { IoCopyOutline } from "react-icons/io5";
import { useMeetings } from "../../../context/MeetingsContext";
import { useDraftMeetings } from "../../../context/DraftMeetingContext";
import { FaLink } from "react-icons/fa6";
import { LuLink } from "react-icons/lu";
import copy from "copy-to-clipboard";
import { openLinkInNewTab } from "../../Utils/openLinkInNewTab";
import { MdAssessment } from "react-icons/md";
import { useDestinations } from "../../../context/DestinationsContext";
import AddDestination from "./AddDestination";
import ConfirmationModal from "../../Utils/ConfirmationModal";

const NewDestinations = ({
  setActiveTab,
  selectedView,
  allCurrentDestinations,
}) => {
  console.log("allCurrentDestinations", allCurrentDestinations);
  //   const { isLoading, getDes, timeUnitsTotal } = useMeetings();
  const { getDestinations, isLoading, allDestinations } = useDestinations();
  const { language } = useDraftMeetings();
  useEffect(() => {
    getDestinations();
  }, []);

  const effectRan = React.useRef(false);
  const { title, pushHeaderTitle, popHeaderTitle, setHeaderTitle } =
    useHeaderTitle();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  // const [meetings, setMeetings] = useState([]);
  const moment = require("moment");
  require("moment/locale/fr");
  useEffect(() => {
    SpeechRecognition.stopListening();
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  //Delete Destination
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/destinations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Check for successful deletion (assuming HTTP status 200)
      if (response.status === 200) {
        toast.success(t("destination.destinationDeleteMsg"));
        getDestinations();
      } else {
        // Handle other status codes (e.g., 4xx, 5xx) appropriately
        throw new Error("Échec de la suppression de la réunion");
      }
    } catch (error) {
      // Improve error handling, provide informative messages
      toast.error(t(error.message));
    }
  };

  const confirmDelete = (e) => {
    e.stopPropagation();
    setShowConfirmationModal(false);
    handleDelete(itemIdToDelete);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);


  const handleEdit = (item) => {
    setCurrentItem(item);
    setShowEditModal(true);
  };
  const handleClose = () => {
    setCurrentItem(null);
    setShowEditModal(false);
  };

  const viewDestinationMeeting = (item) => {
    navigate(`/invitiesToMeeting/${item.id}`);
  };

  useEffect(() => {
    return () => {
      effectRan.current = true;
    };
  }, []);

  const sortedDestinations = [...allCurrentDestinations];
  const [openDropdownId, setOpenDropdownId] = useState(null);
  
  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log("user", user);

  const meetingsByMonth = {}; // Object to store meetings organized by month
  // Sort destinations by meeting_start_date in ascending order
  const sortedDestinationsByDate = [...sortedDestinations].sort((a, b) => {
    const dateA = moment(a.meeting_start_date, "DD/MM/YYYY"); // Adjust format if needed
    const dateB = moment(b.meeting_start_date, "DD/MM/YYYY");

    return dateA - dateB;
  });

  // / Filter out destinations with only one meeting in draft status
  const filteredDestinations = sortedDestinationsByDate.filter((item) => {
    const hasOnlyDraftMeeting =
      item?.meetings?.length === 1 && item?.meetings[0]?.status === "draft";
    return !hasOnlyDraftMeeting;
  });
  filteredDestinations?.forEach((item, index) => {
    console.log("item", item);
    let date;
    if (language === "en") {
      date = moment(item.meeting_start_date, "DD/MM/YYYY").locale("en-gb"); // Set locale to English
    } else {
      date = moment(item.meeting_start_date, "DD/MM/YYYY"); // Set locale to French if needed
    }
    const month = moment(date).format("MMMM");
    if (!meetingsByMonth[month]) {
      meetingsByMonth[month] = [];
    }
    const guideCount = item?.participant?.filter(
      (participant) => participant?.isCreator !== 1
    )?.length;
    // Check if the meeting buttons should be disabled
    const shouldDisableButtons =
      item?.user_id !== parseInt(sessionStorage.getItem("user_id")) &&
      item?.steps?.some((step) => step?.participant || step?.userPID);

    // Filter out meetings that are not closed
    const openMeetings = item?.meetings?.filter(
      (meeting) =>
        meeting.status !== "closed" &&
        meeting.status !== "abort" &&
        meeting.status !== "draft"
    );

    // Determine if any meetings are late

    // const isAnyMeetingLate = openMeetings?.some((meeting) =>
    //   moment().isAfter(
    //     moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
    //   )
    // );

    // Determine if any meetings are in progress
    const isAnyMeetingInProgress = openMeetings?.some(
      (meeting) => meeting.status === "in_progress"
    );

    // Determine if all meetings are closed or aborted
    const areAllMeetingsClosed = item?.meetings?.every(
      (meeting) => meeting.status === "closed" || meeting.status === "abort"
    );

    // Determine if any meetings are late
    const isAnyMeetingLate =
      !isAnyMeetingInProgress &&
      openMeetings?.some((meeting) =>
        moment().isAfter(
          moment(`${meeting.date} ${meeting.start_time}`, "YYYY-MM-DD HH:mm")
        )
      );

    // Determine the overall badge to show
    const overallBadge = areAllMeetingsClosed
      ? "active"
      : isAnyMeetingInProgress
      ? "inProgress"
      : isAnyMeetingLate
      ? "active"
      : "active";
    console.log("overallBadge", overallBadge);

    const badgeText = areAllMeetingsClosed
      ? t("badge.finished")
      : isAnyMeetingInProgress
      ? t("badge.inprogress")
      : isAnyMeetingLate
      ? t("badge.active")
      : t("badge.active");
    console.log("badge Text", badgeText);

    const userId = parseInt(sessionStorage.getItem("user_id"));

    const totalMeetings = item?.total_meetings;
    const completedMeetings = item?.completed_meetings;
    // const percentageCompleted = totalMeetings > 0 ? (completedMeetings / totalMeetings) * 100 : 0;
    const percentageCompleted =
      totalMeetings > 0
        ? Math.floor((completedMeetings / totalMeetings) * 100)
        : 0;

    meetingsByMonth[month].push(
      <Card
        className="mt-3 mb-2 scheduled"
        key={index}
        onClick={(e) => {
          e.stopPropagation();
          viewDestinationMeeting(item);
        }}
      >
        <div className="row">
          <div className="col-md-12 col-lg-12 first-row">
            <div className="first">
              <span className="destination_name">
                {item?.destination_name}{" "}
                {/* {item?.meetings?.length > 0 && (
                  <>
                    { */}
                      <span className="destination_status">
                        <span className={`badge ms-2 future`}>
                          {/* {badgeText} */}
                         { t("badge.new")}
                        </span>
                      </span>
                    {/* }
                  </>
                )} */}
              </span>

              <div className="options">
                {item.total_meetings > 0 && (
                  <span>
                    {percentageCompleted.toFixed(2)}%{" "}
                    {t("destinationPercentage")}
                  </span>
                )}
                  {(item?.user_id === userId || item.user_id === null) && (
                  <div className="dropdown dropstart">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded={openDropdownId === item.id}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "0px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(item.id);
                      }}
                    >
                      <BiDotsVerticalRounded color="black" size={"25px"} />
                    </button>
                    <ul
                      className={`dropdown-menu ${
                        openDropdownId === item.id ? "show" : ""
                      }`}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setHeaderTitle([
                              {
                                titleText: t("header.modification"),
                                link: `/meeting`,
                              },
                              {
                                titleText: `${item.title}`,
                                link: `/updateMeeting/${item?.id}`,
                              },
                            ]);
                            e.stopPropagation();
                            handleEdit(item);
                          }}
                        >
                          <RiEditBoxLine size={"20px"} /> &nbsp;
                          {t("dropdown.To modify")}
                        </a>
                      </li>
                    {/* {item?.total_meetings === 0 &&   <li>
                        <a
                          className="dropdown-item"
                          style={{ cursor: "pointer", color: "red" }}
                          onClick={(e) => handleDeleteClick(e, item?.id)}

                        >
                          <AiOutlineDelete size={"20px"} color="red" />
                          &nbsp; {t("dropdown.Delete")}
                        </a>
                      </li>} */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {(item?.meeting_start_date || item?.meeting_end_date) && (
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "16.41px",
                  textAlign: "left",
                  color: "#5c5c5c",
                }}
              >
                {item?.meeting_start_date} - {item?.meeting_end_date}
              </span>
            )}
          </div>
          <div className="col-md-12 col-lg-12 second-row">
            <div className="second">
              <p className="description">{item?.destination_description}</p>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 third-row">
            <div className="row">
              <div className="col-md-6">
                {item?.user_id && (
                  <span className="creator_label">Creator</span>
                )}
              </div>
              <div className="col-md-6">
                {/* {guideCount > 0 && (
                  <span className="creator_label">Participants</span>
                )} */}
              </div>
              <div className="col-md-6 d-flex align-items-center">
                {item?.user_id && (
                  <div className="guide">
                    <Avatar
                      size="large"
                      src={
                        item?.user?.image?.startsWith("users/")
                          ? Assets_URL + "/" + item?.user?.image
                          : item?.user?.image
                      }
                    />
                    <span className="creator-name">
                      {item?.user?.full_name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  });

  // Sort the keys (months) in descending order
  const sortedMonths = Object.keys(meetingsByMonth).sort((a, b) =>
    moment(a, "MMMM").isBefore(moment(b, "MMMM")) ? -1 : 1
  );
  return (
    <div className="current_destinations">
      <div className="my-2 container-fluid">
        {sortedDestinations?.length === 0 && !isLoading ? (
          <NoContent title="New Destinations" />
        ) : sortedDestinations?.length > 0 ? (
          <>
            {sortedMonths.map((month) => (
              <div key={month}>
                {/* <span className="month">{month}</span> */}
                {meetingsByMonth[month].map((meeting) => (
                  <React.Fragment key={meeting.key}>{meeting}</React.Fragment>
                ))}
              </div>
            ))}
          </>
        ) : (
          <Spinner
            animation="border"
            role="status"
            className="center-spinner"
          ></Spinner>
        )}
      </div>
      <AddDestination
        show={showEditModal}
        handleClose={handleClose}
        currentItem={currentItem}
      />

      {showConfirmationModal && (
        <ConfirmationModal
          message={t("destination.destinationDeleteConfirmMsg")}
          onConfirm={(e) => confirmDelete(e)}
          onCancel={(e) => {
            e.stopPropagation();
            setShowConfirmationModal(false);
          }}
        />
      )}
    </div>
  );
};

export default NewDestinations;
