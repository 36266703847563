import React from "react";
import Heros from "../Components/Elements/landingPages/Heros";
// import Brands from "../Component/Elements/landingPages/Brands";
import Whatistektime from "../Components/Elements/landingPages/Whatistektime";
import Trackmoment from "../Components/Elements/landingPages/Trackmoment";
import Participantsandassign from "../Components/Elements/landingPages/Participantsandassign";
import Givingservices from "../Components/Elements/landingPages/Givingservices";
import Pricingandplans from "../Components/Elements/landingPages/Pricingandplans";
import Whychooseus from "../Components/Elements/landingPages/Whychooseus";
import Features from "../Components/Elements/landingPages/Features";
import Streamllineyourmeeting from "../Components/Elements/landingPages/Streamllineyourmeeting";
import Testimonials from "../Components/Elements/landingPages/Testimonials";
import Percentage from "../Components/Elements/landingPages/Percentage";
import UseCases from "../Components/Elements/landingPages/UseCases";
function Home() {
  return (
    <div>
      <Heros />
      {/* <Brands /> */}
      <Whatistektime />
      <Percentage />
      <Trackmoment />
      <Participantsandassign />
      <Givingservices />
      <UseCases />
      <Pricingandplans
        heading="Our Pricing and plans"
        mainheading={
          <>
            We offer a comprehensive meeting{" "}
            <br className="d-none d-md-block" /> platform designed for your
            needs.
          </>
        }
      />
      <Whychooseus />
      <Features />
      <Streamllineyourmeeting />
      <Testimonials />
    </div>
  );
}

export default Home;
