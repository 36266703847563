import React, { createContext, useContext, useState } from 'react';

const StepContext = createContext();

export const useSteps = () => useContext(StepContext);

export const StepProvider1 = ({ children }) => {
  const [steps, setSteps] = useState([]);

  const updateSteps = (newSteps) => {
    setSteps(newSteps);
  };

  return (
    <StepContext.Provider value={{ steps, updateSteps }}>
      {children}
    </StepContext.Provider>
  );
};
