import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_BASE_URL } from "../../Apicongfig";
import axios from "axios";
import { toast } from "react-toastify";
import { useDestinations } from "../../../context/DestinationsContext";
import { useTranslation } from "react-i18next";

const AddDestination = ({
  show,
  handleClose,
  currentItem,
  refreshedDestination,
}) => {
  const [loading, setLoading] = useState(false);
  const [destinationName, setDestinationName] = useState(null);
  const [destinationDescription, setDestinationDescription] = useState(null);
  const { getDestinations } = useDestinations();
  const [t] = useTranslation("global");
  const [isDisabled, setIsDisabled] = useState(false);
  console.log("disabled", isDisabled);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  useEffect(() => {
    if (currentItem) {
      setDestinationName(currentItem.destination_name);
      setDestinationDescription(currentItem.destination_description);
      if (currentItem?.user_id === userId || currentItem?.user_id === null) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setDestinationName("");
      setDestinationDescription("");
      setIsDisabled(false);
    }
  }, [currentItem]);
  const user_id = sessionStorage.getItem("user_id");
  const createDestination = async () => {
    if (!destinationName) {
      toast.error(t("destination.destinationName"));
      return;
    }
    setLoading(true);
    try {
      const payload = {
        destination_name: destinationName,
        destination_description: destinationDescription,
        user_id: user_id,
      };
      const response = await axios.post(
        `${API_BASE_URL}/destinations`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        handleClose();
        getDestinations();
        toast.success(t("destination.destinationCreateMsg"));
      }
    } catch (error) {
      console.log("error creating destination", error);
    } finally {
      setLoading(false);
      setDestinationName("");
      setDestinationDescription("");
    }
  };
  const updateDestination = async (item) => {
    if (!destinationName) {
      toast.error(t("destination.destinationName"));
      return;
    }
    setLoading(true);
    try {
      const payload = {
        destination_name: destinationName,
        destination_description: destinationDescription,
        // user_id: user_id,
      };
      const response = await axios.put(
        `${API_BASE_URL}/destinations/${item.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        handleClose();
        getDestinations();
        refreshedDestination();
        toast.success(t("destination.destinationUpdateMsg"));
      }
    } catch (error) {
      console.log("error creating destination", error);
    } finally {
      setLoading(false);
      setDestinationName("");
      setDestinationDescription("");
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="create-destination-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="destination-modal-title">
          {currentItem ? "Update" : `${t("invities.create")}`} Destination
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form">
          <div className="name">
            <label htmlFor="" className="form-label">Destination
            <small style={{ color: 'red',fontSize:'15px',marginLeft:'2px' }}>*</small> 

            </label>
            
            <input
              type="text"
              placeholder="Enter Destination Name"
              value={destinationName}
              onChange={(e) => setDestinationName(e.target.value)}
              disabled={isDisabled}
            />
          </div>
          <div className="description">
            <label htmlFor="">Description</label>
            <textarea
              placeholder="Enter Description"
              value={destinationDescription}
              onChange={(e) => setDestinationDescription(e.target.value)}
              disabled={isDisabled}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="destination-modal-footer">
        <button
          onClick={() =>
            currentItem ? updateDestination(currentItem) : createDestination()
          }
          disabled={isDisabled}
        >
          {" "}
          {currentItem ? "Update" : `${t("invities.create")}`} Destination
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDestination;
