import React, { useEffect, useState } from "react";
import { Container, Row, Col, Accordion, Form, Spinner, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../../../../../context/CreateMeetingContext";
import { toast } from "react-toastify";
import { useMeetings } from "../../../../../context/MeetingsContext";

function Location({ setActiveTab }) {
  const { formState, setFormState, handleInputBlur, meeting ,
    isUpdated,
    handleCloseModal,
  } =
    useFormContext();
  const { getMeetings } = useMeetings();

  const [t] = useTranslation("global");
  const [selectedLocation, setSelectedLocation] = useState("Meeting location");
  const [expanded, setExpanded] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingQuit, setLoadingQuit] = useState(false); 

  const handleSelect = (location) => {
    setSelectedLocation(location);
    setFormState((prevState) => ({
      ...prevState,
      location: location === "None" ? "None" : location,
    }));
  };

  // Effect to automatically expand if the meeting object has a location
  useEffect(() => {
    if (meeting) {
      const { location, city, country, street, zip, room_details } = meeting;
      setFormState((prevState) => ({
        ...prevState,
        location: location || "",
        city: city || "",
        country: country || "",
        street: street || "",
        zip: zip || "",
        room_details: room_details || "",
      }));

      // Automatically expand if location is present
      if (location) {
        setSelectedLocation(location);
        setExpanded(true); // Expand automatically
      }
    }
  }, [meeting, setFormState]);

  // Toggle accordion expansion
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // Form validation logic
  const validateForm = () => {
    let validationErrors = {};

    if (!formState.location) {
      validationErrors.location = t("meeting.formState.location");
      toast.error(validationErrors.location);
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  // Save and continue function
  const handleSaveAndContinue = async () => {
    // if (validateForm()) {
    //   handleInputBlur();
    //   setActiveTab("tab5");
    // }
    if (validateForm()) {
      setLoading(true); // Show loader
      try {
        await handleInputBlur();
        setActiveTab("tab5");
      } catch (error) {
        // Handle error (if any)
        toast.error("Error occurred");
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };

  const handleSaveAndQuit = async () => {
    if (validateForm()) {
      setLoadingQuit(true); // Show loader
      try {
        await handleInputBlur();
        // setActiveTab("tab2");
        handleCloseModal();
      } catch (error) {
        // Handle error (if any)
        toast.error("Error occurred");
      } finally {
        setLoadingQuit(false); // Hide loader
        await getMeetings();
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  const isGmailExists = user?.is_gmail === true ? true : false;
  console.log("isGmailExists", isGmailExists);
  return (
    <div className=" col-md-12 p-4 pt-0 modal-height d-flex flex-column">
      <Row className="flex-grow-1">
        <Col md={12}>
          <Accordion className="mt-4" activeKey={expanded ? "0" : null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                className={`py-0`}
                style={{
                  borderRadius: "8px",
                  backgroundColor: expanded
                    ? "transparent"
                    : "background: rgba(241, 245, 255, 0.70);", // Background color when expanded or collapsed
                  border: expanded ? "none" : "none", // Only show border when collapsed
                }}
                onClick={handleToggle}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  {expanded ? (
                    <path
                      d="M19.5 3.375H4.5C3.80381 3.375 3.13613 3.65156 2.64384 4.14384C2.15156 4.63613 1.875 5.30381 1.875 6V16.5C1.875 17.1962 2.15156 17.8639 2.64384 18.3562C3.13613 18.8484 3.80381 19.125 4.5 19.125H19.5C19.8447 19.125 20.1861 19.0571 20.5045 18.9252C20.823 18.7933 21.1124 18.5999 21.3562 18.3562C21.5999 18.1124 21.7933 17.823 21.9252 17.5045C22.0571 17.1861 22.125 16.8447 22.125 16.5V6C22.125 5.65528 22.0571 5.31394 21.9252 4.99546C21.7933 4.67698 21.5999 4.3876 21.3562 4.14384C21.1124 3.90009 20.823 3.70673 20.5045 3.57482C20.1861 3.4429 19.8447 3.375 19.5 3.375ZM19.875 16.5C19.875 16.5995 19.8355 16.6948 19.7652 16.7652C19.6948 16.8355 19.5995 16.875 19.5 16.875H4.5C4.40054 16.875 4.30516 16.8355 4.23484 16.7652C4.16451 16.6948 4.125 16.5995 4.125 16.5V6C4.125 5.90054 4.16451 5.80516 4.23484 5.73484C4.30516 5.66451 4.40054 5.625 4.5 5.625H19.5C19.5995 5.625 19.6948 5.66451 19.7652 5.73484C19.8355 5.80516 19.875 5.90054 19.875 6V16.5ZM16.125 21.375C16.125 21.6734 16.0065 21.9595 15.7955 22.1705C15.5845 22.3815 15.2984 22.5 15 22.5H9C8.70163 22.5 8.41548 22.3815 8.2045 22.1705C7.99353 21.9595 7.875 21.6734 7.875 21.375C7.875 21.0766 7.99353 20.7905 8.2045 20.5795C8.41548 20.3685 8.70163 20.25 9 20.25H15C15.2984 20.25 15.5845 20.3685 15.7955 20.5795C16.0065 20.7905 16.125 21.0766 16.125 21.375Z"
                      fill="#E0E6F1"
                    />
                  ) : (
                    <path
                      d="M19.5 3.375H4.5C3.80381 3.375 3.13613 3.65156 2.64384 4.14384C2.15156 4.63613 1.875 5.30381 1.875 6V16.5C1.875 17.1962 2.15156 17.8639 2.64384 18.3562C3.13613 18.8484 3.80381 19.125 4.5 19.125H19.5C19.8447 19.125 20.1861 19.0571 20.5045 18.9252C20.823 18.7933 21.1124 18.5999 21.3562 18.3562C21.5999 18.1124 21.7933 17.823 21.9252 17.5045C22.0571 17.1861 22.125 16.8447 22.125 16.5V6C22.125 5.65528 22.0571 5.31394 21.9252 4.99546C21.7933 4.67698 21.5999 4.3876 21.3562 4.14384C21.1124 3.90009 20.823 3.70673 20.5045 3.57482C20.1861 3.4429 19.8447 3.375 19.5 3.375ZM19.875 16.5C19.875 16.5995 19.8355 16.6948 19.7652 16.7652C19.6948 16.8355 19.5995 16.875 19.5 16.875H4.5C4.40054 16.875 4.30516 16.8355 4.23484 16.7652C4.16451 16.6948 4.125 16.5995 4.125 16.5V6C4.125 5.90054 4.16451 5.80516 4.23484 5.73484C4.30516 5.66451 4.40054 5.625 4.5 5.625H19.5C19.5995 5.625 19.6948 5.66451 19.7652 5.73484C19.8355 5.80516 19.875 5.90054 19.875 6V16.5ZM16.125 21.375C16.125 21.6734 16.0065 21.9595 15.7955 22.1705C15.5845 22.3815 15.2984 22.5 15 22.5H9C8.70163 22.5 8.41548 22.3815 8.2045 22.1705C7.99353 21.9595 7.875 21.6734 7.875 21.375C7.875 21.0766 7.99353 20.7905 8.2045 20.5795C8.41548 20.3685 8.70163 20.25 9 20.25H15C15.2984 20.25 15.5845 20.3685 15.7955 20.5795C16.0065 20.7905 16.125 21.0766 16.125 21.375Z"
                      fill="#0000FF"
                    />
                  )}
                </svg>
                <span
                  className="w-100 text-start solutioncards"
                  style={{ color: expanded ? "#E0E6F1" : "#3D57B5" }}
                >
                  {t("meeting.formState.Meeting Location")}
                </span>
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <div className="list-group">
                  {isGmailExists && (
                    <button
                      className={`list-group-item list-group-item-action p-3 ${
                        selectedLocation === "Google Meet"
                          ? "border-primary"
                          : ""
                      }`}
                      onClick={() => handleSelect("Google Meet")}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="20"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_1_1764)">
                            <path
                              d="M13.577 9.98308L15.9167 12.6871L19.0632 14.7198L19.6105 10.0001L19.0632 5.38696L15.8565 7.17265L13.577 9.98308Z"
                              fill="#00832D"
                            />
                            <path
                              d="M0 14.2806V18.3024C0 19.2206 0.73725 19.9662 1.64569 19.9662H5.6235L6.44719 16.9275L5.6235 14.2806L2.89444 13.4478L0 14.2806Z"
                              fill="#0066DA"
                            />
                            <path
                              d="M5.6235 0L0 5.68569L2.89462 6.51649L5.6235 5.68569L6.43219 3.07479L5.6235 0Z"
                              fill="#E94235"
                            />
                            <path
                              d="M0.00012207 14.2823H5.62353V5.68555H0.00012207V14.2823Z"
                              fill="#2684FC"
                            />
                            <path
                              d="M22.6555 2.40735L19.0631 5.38707V14.7198L22.6703 17.711C23.2103 18.1387 24.0003 17.749 24.0003 17.0549V3.0502C24.0003 2.34849 23.1915 1.96062 22.6554 2.40745"
                              fill="#00AC47"
                            />
                            <path
                              d="M13.5771 9.98315V14.2806H5.62354V19.9663H17.4177C18.3261 19.9663 19.0633 19.2207 19.0633 18.3024V14.7198L13.5771 9.98315Z"
                              fill="#00AC47"
                            />
                            <path
                              d="M17.4177 0H5.62354V5.68569H13.5771V9.98313L19.0633 5.38692V1.66398C19.0633 0.745498 18.3261 9.47867e-05 17.4177 9.47867e-05"
                              fill="#FFBA00"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_1764">
                              <rect width="24" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Google Meet
                    </button>
                  )}
                  {/* <div className="d-flex">
                    <button
                      className={`list-group-item list-group-item-action p-3 ${
                        selectedLocation === "Phone" ? "border-primary" : ""
                      }`}
                      onClick={() => handleSelect("Phone")}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M19.95 21C17.8667 21 15.8083 20.546 13.775 19.638C11.7417 18.73 9.89167 17.4423 8.225 15.775C6.55833 14.1077 5.271 12.2577 4.363 10.225C3.455 8.19233 3.00067 6.134 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07933 8.725 3.238C8.90833 3.39667 9.01667 3.584 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.704 12.1123 8.162 12.687C8.62 13.2617 9.12433 13.816 9.675 14.35C10.1917 14.8667 10.7333 15.346 11.3 15.788C11.8667 16.23 12.4667 16.634 13.1 17L15.45 14.65C15.6 14.5 15.796 14.3877 16.038 14.313C16.28 14.2383 16.5173 14.2173 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1377 20.775 15.313C20.925 15.4883 21 15.684 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21Z"
                            fill="#3D57B5"
                          />
                        </svg>
                      </span>
                      Phone
                    </button>
                  </div> */}
                  <button
                    className={`list-group-item list-group-item-action p-3 ${
                      selectedLocation === "Room" ? "border-primary" : ""
                    }`}
                    onClick={() => handleSelect("Room")}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M19.5 3.375H4.5C3.80381 3.375 3.13613 3.65156 2.64384 4.14384C2.15156 4.63613 1.875 5.30381 1.875 6V16.5C1.875 17.1962 2.15156 17.8639 2.64384 18.3562C3.13613 18.8484 3.80381 19.125 4.5 19.125H19.5C19.8447 19.125 20.1861 19.0571 20.5045 18.9252C20.823 18.7933 21.1124 18.5999 21.3562 18.3562C21.5999 18.1124 21.7933 17.823 21.9252 17.5045C22.0571 17.1861 22.125 16.8447 22.125 16.5V6C22.125 5.65528 22.0571 5.31394 21.9252 4.99546C21.7933 4.67698 21.5999 4.3876 21.3562 4.14384C21.1124 3.90009 20.823 3.70673 20.5045 3.57482C20.1861 3.4429 19.8447 3.375 19.5 3.375ZM19.875 16.5C19.875 16.5995 19.8355 16.6948 19.7652 16.7652C19.6948 16.8355 19.5995 16.875 19.5 16.875H4.5C4.40054 16.875 4.30516 16.8355 4.23484 16.7652C4.16451 16.6948 4.125 16.5995 4.125 16.5V6C4.125 5.90054 4.16451 5.80516 4.23484 5.73484C4.30516 5.66451 4.40054 5.625 4.5 5.625H19.5C19.5995 5.625 19.6948 5.66451 19.7652 5.73484C19.8355 5.80516 19.875 5.90054 19.875 6V16.5ZM16.125 21.375C16.125 21.6734 16.0065 21.9595 15.7955 22.1705C15.5845 22.3815 15.2984 22.5 15 22.5H9C8.70163 22.5 8.41548 22.3815 8.2045 22.1705C7.99353 21.9595 7.875 21.6734 7.875 21.375C7.875 21.0766 7.99353 20.7905 8.2045 20.5795C8.41548 20.3685 8.70163 20.25 9 20.25H15C15.2984 20.25 15.5845 20.3685 15.7955 20.5795C16.0065 20.7905 16.125 21.0766 16.125 21.375Z"
                          fill="#3D57B5"
                        />
                      </svg>
                    </span>
                    Room
                    {selectedLocation === "Room" && (
                      <div
                        className={`p-4 pb-1 create-moment-modal Meeting-detail ${
                          selectedLocation === "Room" ? "open" : ""
                        }`}
                      >
                        <div className="row form">
                          <div className="mb-2 col-lg-6 col-sm-12">
                            <label className="form-label">
                              {t("meeting.formState.RoomDetail")}
                            </label>
                            <textarea
                              required
                              className="form-control"
                              value={formState.room_details || ""}
                              onChange={handleInputChange}
                              name="room_details"
                              placeholder={t(
                                "meeting.formState.RoomPlaceholder"
                              )}
                              rows="3" // Optionally set the number of visible text lines
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                  <button
                    className={`list-group-item list-group-item-action p-3 ${
                      selectedLocation === "Address" ? "border-primary" : ""
                    }`}
                    onClick={() => handleSelect("Address")}
                  >
                    <span>
                      <svg
                        width="24"
                        height="24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 255.856 255.856"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            fill="#3D57B5"
                            d="M127.928,38.8c-30.75,0-55.768,25.017-55.768,55.767s25.018,55.767,55.768,55.767
          s55.768-25.017,55.768-55.767S158.678,38.8,127.928,38.8z M127.928,135.333c-22.479,0-40.768-18.288-40.768-40.767
          S105.449,53.8,127.928,53.8s40.768,18.288,40.768,40.767S150.408,135.333,127.928,135.333z"
                          />
                          <path
                            fill="#3D57B5"
                            d="M127.928,0C75.784,0,33.362,42.422,33.362,94.566c0,30.072,25.22,74.875,40.253,98.904
          c9.891,15.809,20.52,30.855,29.928,42.365c15.101,18.474,20.506,20.02,24.386,20.02c3.938,0,9.041-1.547,24.095-20.031
          c9.429-11.579,20.063-26.616,29.944-42.342c15.136-24.088,40.527-68.971,40.527-98.917C222.495,42.422,180.073,0,127.928,0z
          M171.569,181.803c-19.396,31.483-37.203,52.757-43.73,58.188c-6.561-5.264-24.079-26.032-43.746-58.089
          c-22.707-37.015-35.73-68.848-35.73-87.336C48.362,50.693,84.055,15,127.928,15c43.873,0,79.566,35.693,79.566,79.566
          C207.495,112.948,194.4,144.744,171.569,181.803z"
                          />
                        </g>
                      </svg>
                    </span>
                    {t("meeting.formState.address")}
                    {selectedLocation === "Address" && (
                      <div className="p-4 pb-1 create-moment-modal">
                        <div className="row form">
                          <div className="mb-2 col-lg-6 col-sm-12">
                            <label className="form-label">
                              {t("meeting.formState.city")}
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              value={formState.city || ""}
                              name="city"
                              onChange={handleInputChange}
                              placeholder={t(
                                "meeting.formState.cityPlaceholder"
                              )}
                            />
                          </div>
                          <div className="mb-2 col-lg-6 col-sm-12">
                            <label className="form-label">
                              {t("meeting.formState.country")}
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              name="country"
                              value={formState.country || ""}
                              onChange={handleInputChange}
                              placeholder={t(
                                "meeting.formState.countryPlaceholder"
                              )}
                            />
                          </div>
                          <div className="mb-2 col-lg-6 col-sm-12">
                            <label className="form-label">
                              {t("meeting.formState.street")}
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              value={formState.street || ""}
                              name="street"
                              onChange={handleInputChange}
                              placeholder={t(
                                "meeting.formState.streetPlaceholder"
                              )}
                            />
                          </div>
                          <div className="mb-2 col-lg-6 col-sm-12">
                            <label className="form-label">
                              {t("meeting.formState.zip")}
                            </label>
                            <input
                              type="text"
                              required
                              name="zip"
                              className="form-control"
                              value={formState.zip || ""}
                              onChange={handleInputChange}
                              placeholder={t(
                                "meeting.formState.zipPlaceholder"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                  <button
                    className={`list-group-item list-group-item-action p-3 ${
                      selectedLocation === "None" ? "border-primary" : ""
                    }`}
                    onClick={() => handleSelect("None")}
                  >
                    <span>
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 15.00 15.00"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#0026b1"
                        stroke-width="0.51"
                        transform="rotate(90)"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0" />

                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />

                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.49991 0.877075C3.84222 0.877075 0.877075 3.84222 0.877075 7.49991C0.877075 11.1576 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1576 14.1227 7.49991C14.1227 3.84222 11.1576 0.877075 7.49991 0.877075ZM3.85768 3.15057C4.84311 2.32448 6.11342 1.82708 7.49991 1.82708C10.6329 1.82708 13.1727 4.36689 13.1727 7.49991C13.1727 8.88638 12.6753 10.1567 11.8492 11.1421L3.85768 3.15057ZM3.15057 3.85768C2.32448 4.84311 1.82708 6.11342 1.82708 7.49991C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C8.88638 13.1727 10.1567 12.6753 11.1421 11.8492L3.15057 3.85768Z"
                            fill="#0026b1"
                          />{" "}
                        </g>
                      </svg>
                    </span>
                    None
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <div className="modal-footer d-flex justify-content-end px-4 mt-3 modal-save-button gap-4">
      {isUpdated && (
            <Button
            variant="danger"
              // className="btn "
              onClick={handleSaveAndQuit}
              disabled={loadingQuit}
              style={{ padding: "9px" }}
            >
              {loadingQuit ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "white",
                      margin: "5px 82px",
                    }}
                  />
                </>
              ) : (
                <>
                  &nbsp;{t("meeting.formState.Save and Quit")}
                  {/* <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4707 8.52991C13.397 8.46125 13.3379 8.37845 13.2969 8.28645C13.2559 8.19445 13.2338 8.09513 13.2321 7.99443C13.2303 7.89373 13.2488 7.7937 13.2865 7.70031C13.3243 7.60692 13.3804 7.52209 13.4516 7.45087C13.5228 7.37965 13.6077 7.32351 13.7011 7.28579C13.7945 7.24807 13.8945 7.22954 13.9952 7.23132C14.0959 7.23309 14.1952 7.25514 14.2872 7.29613C14.3792 7.33712 14.462 7.39622 14.5307 7.46991L18.5307 11.4699C18.6711 11.6105 18.75 11.8012 18.75 11.9999C18.75 12.1987 18.6711 12.3893 18.5307 12.5299L14.5307 16.5299C14.462 16.6036 14.3792 16.6627 14.2872 16.7037C14.1952 16.7447 14.0959 16.7667 13.9952 16.7685C13.8945 16.7703 13.7945 16.7518 13.7011 16.714C13.6077 16.6763 13.5228 16.6202 13.4516 16.549C13.3804 16.4778 13.3243 16.393 13.2865 16.2996C13.2488 16.2062 13.2303 16.1062 13.2321 16.0055C13.2338 15.9048 13.2559 15.8055 13.2969 15.7135C13.3379 15.6215 13.397 15.5387 13.4707 15.4699L16.1907 12.7499H6C5.80109 12.7499 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 11.9999C5.25 11.801 5.32902 11.6103 5.46967 11.4696C5.61032 11.329 5.80109 11.2499 6 11.2499H16.1907L13.4707 8.52991Z"
                      fill="white"
                    />
                  </svg>
                </span> */}
                </>
              )}
            </Button>
          )}
        <button
          className={`btn moment-btn`}
          onClick={handleSaveAndContinue}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "white",
                  margin: "5px 82px",
                }}
              />
            </>
          ) : (
            <>
              &nbsp;{t("meeting.formState.Save and Continue")}
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4707 8.52991C13.397 8.46125 13.3379 8.37845 13.2969 8.28645C13.2559 8.19445 13.2338 8.09513 13.2321 7.99443C13.2303 7.89373 13.2488 7.7937 13.2865 7.70031C13.3243 7.60692 13.3804 7.52209 13.4516 7.45087C13.5228 7.37965 13.6077 7.32351 13.7011 7.28579C13.7945 7.24807 13.8945 7.22954 13.9952 7.23132C14.0959 7.23309 14.1952 7.25514 14.2872 7.29613C14.3792 7.33712 14.462 7.39622 14.5307 7.46991L18.5307 11.4699C18.6711 11.6105 18.75 11.8012 18.75 11.9999C18.75 12.1987 18.6711 12.3893 18.5307 12.5299L14.5307 16.5299C14.462 16.6036 14.3792 16.6627 14.2872 16.7037C14.1952 16.7447 14.0959 16.7667 13.9952 16.7685C13.8945 16.7703 13.7945 16.7518 13.7011 16.714C13.6077 16.6763 13.5228 16.6202 13.4516 16.5489C13.3804 16.4777 13.3243 16.3929 13.2865 16.2995C13.2488 16.2061 13.2303 16.1061 13.2321 16.0054C13.2338 15.9047 13.2559 15.8054 13.2969 15.7134C13.3379 15.6214 13.397 15.5386 13.4707 15.4699L16.1907 12.7499H6.50066C6.30175 12.7499 6.11098 12.6709 5.97033 12.5302C5.82968 12.3896 5.75066 12.1988 5.75066 11.9999C5.75066 11.801 5.82968 11.6102 5.97033 11.4696C6.11098 11.3289 6.30175 11.2499 6.50066 11.2499H16.1907L13.4707 8.52991Z"
                    fill="white"
                  />
                </svg>
              </span>
            </>
          )}
        </button>
      </div>
    </div>
  );
}

export default Location;
