import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Spinner, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReportStepCard from "./ReportStepCard";
import DecisionCard from "./DecisionCard";
// import * as d3 from "d3";
import StepCard from "./StepCard";
import ParticipantCard from "./ParticipantCard";
import HostCard from "./HostCard";
import Invite from "./Invite";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
import moment from "moment";
import { Avatar } from "antd";
import { IoArrowBackSharp } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import ParticipantCardProfile from "../Profile/ParticipantCardProfile";
import SignIn from "../AuthModal/SignIn";
import SignUp from "../AuthModal/SignUp";
import ForgotPassword from "../AuthModal/ForgotPassword";
import VisibilityMessageModal from "../Profile/VisibilityMessageModal";
import VisibilityMeetingMessageModal from "./VisibilityMeetingMessageModal";
import { useSidebarContext } from "../../../context/SidebarContext";

const Report = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let fromMeeting = false;
  if (location?.state?.from === "meeting") {
    fromMeeting = true;
  }
  const { id } = useParams();
  const [t] = useTranslation("global");
  const { meeting_id: meetId } = useParams();
  const [meeting, setMeeting] = useState();
  // console.log('meeting',meeting)
  const [isLoading, setIsLoading] = useState(false);
  const [startTime1, setStartTime1] = useState(null);
  const [time, setTime] = useState(null);

  const [objective, setObjective] = useState(null);
  const [userId, setUserID] = useState(null);
  const [checkStatus, setCheckStatus] = useState(null);
  const { setHeaderTitle } = useHeaderTitle();
  const pageId = `${userId}/${meetId}/${objective}`;
  const [pageViews, setPageViews] = useState(0);

  useEffect(() => {
    const incrementPageView = async () => {
      // setIsLoading(true)
      try {
        const response = await axios.get(
          `${API_BASE_URL}/page-views/${pageId}`
        );
        console.log("response views", response);
        if (response.status === 200) {
          setPageViews(response?.data?.data?.views);
          // setIsLoading(false);
        }
      } catch (error) {
        console.error("Error incrementing page view:", error);
      }
    };
    incrementPageView();
  }, [pageId]);
  const [estimateTime, setEstimateTime] = useState(null);
  const [estimateDate, setEstimateDate] = useState(null);
  const [meetingData, setMeetingData] = useState([]);
  const [visibilityMessage, setVisibilityMessage] = useState(null);

  console.log("meetingData", meetingData);
  console.log("estimateTime: ", estimateTime);
  console.log("estimateDate: ", estimateDate);

  useEffect(() => {
    if (!meetingData) return;

    const { estimate_time, type, steps, start_time } = meetingData;
    console.log("meetingDAta", meetingData);
    // Ensure estimate_time exists before splitting
    if (estimate_time) {
      const estimateDateOnly = estimate_time?.split("T")[0] || "";
      const estimateTimeOnly =
        estimate_time?.split("T")[1]?.split(".")[0] || "";

      const formattedEstimateDate = estimateDateOnly
        ?.split("-")
        .reverse()
        .join("/");

      if (estimateTimeOnly) {
        const [hours1, minutes1, seconds1] = estimateTimeOnly?.split(":");
        // const hours12 = hours1 % 12 || 12;
        // const amPm = hours1 >= 12 ? "PM" : "AM";

        const formattedEstimateTime = `${hours1}h${minutes1}${
          type === "Quiz" ? `m${seconds1}` : ""
        }`;

        setEstimateTime(formattedEstimateTime);
        setEstimateDate(formattedEstimateDate);
      }
    }

    // Ensure start_time exists before manipulating
    if (start_time) {
      const totalCount2 = steps?.reduce((acc, step) => acc + step.count2, 0);
      const [hours, minutes, seconds] = start_time.split(":").map(Number);

      if (!isNaN(hours) && !isNaN(minutes)) {
        const startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(seconds || 0); // Handle case where seconds might be missing
        startDate.setMinutes(startDate.getMinutes() + totalCount2);

        const formattedStartTime = startDate.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        setStartTime1(formattedStartTime);
      }
    }
  }, [meetingData, id, meetId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (visibilityMessage) {
      setIsModalOpen(true);
    }
  }, [visibilityMessage]);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const dateActive = new Date(meeting?.date);
  const formattedDateActive = dateActive.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const startTimeActive = meeting?.start_time;
  const formattedTimeActive = new Date(
    `1970-01-01T${startTimeActive}`
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const meetingById = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const REQUEST_URL = `${API_BASE_URL}/meetingView/${meetId}?current_time=${formattedTime}&current_date=${formattedDate}`;
      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        setObjective(response.data?.data?.objective);
        setUserID(response.data?.data?.user.id);
        setCheckStatus(response?.data?.data?.status);
        return response?.data?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getMeetingReport = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setIsLoading(true);

    try {
      const response = await axios.get(
        `${API_BASE_URL}/get-meeting-report/${meetId}?current_time=${formattedTime}&current_date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Check if the response status indicates success
      if (response.status === 200) {
        setHeaderTitle({ titleText: " " });
        const steps = response.data.data?.steps;
        const time = response?.data?.data?.start_time;
        setMeeting(response.data?.data);
        setTime(response?.data?.data?.start_time);

        const date = response?.data?.data?.estimate_time;
        const dateOnly = date?.split("T")[0];

        // Split the date into year, month, and day
        const [year, month, day] = dateOnly?.split("-");

        // Format the date as dd/mm/yyyy
        const formattedDate1 = `${day}/${month}/${year}`;
        const timeOnly = date?.split("T")[1].split(".")[0];

        // Convert the time to a Date object for easy 12-hour conversion
        const [hours1, minutes1, seconds1] = timeOnly?.split(":");
        const formattedTime = `${hours1}h${minutes1}${
          response?.data?.data?.type === "Quiz" ? `m${seconds1}` : ""
        }`;
        console.log("formattedTime", formattedTime);

        setEstimateTime(formattedTime);
        setEstimateDate(formattedDate1);

        // Calculate the total count2
        const totalCount2 = steps?.reduce((acc, step) => acc + step.count2, 0);
        console.log("totalCount2: ", totalCount2);

        // Convert start time to a Date object
        const [hours, minutes, seconds] = time.split(":").map(Number);
        const startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(seconds);

        // Add totalCount2 minutes to the start date
        startDate.setMinutes(startDate.getMinutes() + totalCount2);

        // Format the new time
        const formattedTimeAfterAddingStepsTime = startDate.toLocaleTimeString(
          "en-US",
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        );
        setStartTime1(formattedTimeAfterAddingStepsTime);

        // Return the response for further checks
        return response;
      } else {
        // If the status is not 200, return an object indicating the error
        return { status: response.status, message: response.data.message };
      }
    } catch (error) {
      console.log("error while fetching meeting data", error);
      const message = error?.response?.data?.message || "An error occurred";
      setVisibilityMessage(message);
      return { status: error.response?.status, message }; // Return status and message
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await meetingById();
        const reportResponse = await getMeetingReport();
        console.log("reportResponse", reportResponse);
        // Check for a visibility message or any error that indicates further calls should stop
        if (reportResponse?.message || reportResponse?.status === 403) {
          // If there's a visibility message or 403 error, log it and return early (skip the next API calls)
          console.error(
            "Visibility message or Forbidden status found:",
            reportResponse?.message
          );
          setVisibilityMessage(reportResponse?.message);
          return; // Exit early, preventing the next API calls
        } else {
          reportResponse?.data?.data?.status !== "closed"
            ? await getMeetingByID()
            : await getActiveMeetingByID();
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };
    fetchData();
  }, [userId]);

  const params = useParams();
  // const objective = location?.state?.objective
  const [currentIndex, setCurrentIndex] = useState(0);
  const [allMeetings, setAllMeetings] = useState([]);

  const getMeetingByID = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setIsLoading(true);
    // const response = await meetingById();
    // if (response.status === "active" || response.status === "in_progress") {
    //   setIsLoading(false);
    //   return;
    // }
    // // if (response?.status === "closed") {
    // const userId = response?.user_id;
    // const objective = response?.objective;
    try {
      // setIsLoading(true);
      const token = sessionStorage.getItem("token");
      const REQUEST_URL = `${API_BASE_URL}/get-public-meetings/${userId}/${objective}`;

      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const meetings = response.data?.data;
        // const filteredMeetings = meetings?.filter(
        //   (item) => parseInt(item.id) !== parseInt(meetId)
        // );
        // setAllMeetings(filteredMeetings);
        setAllMeetings(meetings);
        const meetingByIdIndex = meetings?.findIndex(
          (meeting) => meeting.id.toString() === meetId
        );
        console.log("meetingByIndex", meetingByIdIndex);
        // const matchingMeeting = meetings?.find(meeting => meeting.id.toString() === meetingId);
        if (meetingByIdIndex !== -1) {
          setCurrentIndex(meetingByIdIndex);
          setMeetingData(meetings[meetingByIdIndex]);
        }
      } else {
        toast.error("Échec de la récupération du rapport");
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("error", error);
    } finally {
      setIsLoading(false);
    }
    // } else {
    //   return false;
    // }
  };

  const getActiveMeetingByID = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setIsLoading(true);
    // const response = await meetingById();
    // if (response.status === "closed") {
    //   setIsLoading(false);
    //   return;
    // }
    // // if (response?.status === "active" || response?.status === "in_progress") {
    // const userId = response?.user_id;
    // const objective = response?.objective;
    try {
      const token = sessionStorage.getItem("token");
      const REQUEST_URL = `${API_BASE_URL}/get-public-active-meetings/${userId}/${objective}`;

      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const meetings = response.data?.data;
        // const filteredMeetings = meetings?.filter(
        //   (item) => parseInt(item.id) !== parseInt(meetId)
        // );
        // setAllMeetings(filteredMeetings);
        setAllMeetings(meetings);
        const meetingByIdIndex = meetings?.findIndex(
          (meeting) => meeting.id.toString() === meetId
        );
        console.log("meetingByIndex", meetingByIdIndex);
        // const matchingMeeting = meetings?.find(meeting => meeting.id.toString() === meetingId);
        if (meetingByIdIndex !== -1) {
          setCurrentIndex(meetingByIdIndex);
          setMeetingData(meetings[meetingByIdIndex]);
        }
      } else {
        toast.error("Échec de la récupération du rapport");
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("error", error);
    } finally {
      setIsLoading(false);
    }
    // } else {
    //   return;
    // }
  };

  const getMeeting = async (meetingId) => {
    try {
      // setIsLoading(true);
      const token = sessionStorage.getItem("token");
      const REQUEST_URL = `${API_BASE_URL}/meetingView/${meetingId}`;

      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        const meetings = response.data?.data;

        const date = response?.data?.data?.estimate_time;
        const dateOnly = date?.split("T")[0];

        // Split the date into year, month, and day
        const [year, month, day] = dateOnly?.split("-");

        // Format the date as dd/mm/yyyy
        const formattedDate1 = `${day}/${month}/${year}`;
        const timeOnly = date?.split("T")[1]?.split(".")[0];

        // Convert the time to a Date object for easy 12-hour conversion
        const [hours1, minutes1, seconds1] = timeOnly?.split(":");
        // let hours12 = hours1 % 12 || 12; // Convert to 12-hour format, handle 00 as 12
        // const amPm = hours1 >= 12 ? "PM" : "AM";
        console.log("dateOnly", formattedDate);
        console.log("timeOnly", timeOnly);
        const formattedTime = `${hours1}h${minutes1}${
          response?.data?.data?.type === "Quiz" ? `m${seconds1}` : ""
        }`;
        console.log("formattedTime", formattedTime);

        setEstimateTime(formattedTime);
        setEstimateDate(formattedDate1);
        setMeetingData(meetings);
      } else {
        toast.error("Échec de la récupération du rapport");
        // setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const date = new Date(meetingData?.date);
  // Get individual components of the date
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date in dd:mm:yyyy
  const formattedDate = `${day}/${month}/${year}`;
  // const formattedDate = date.toLocaleDateString("en-US", {
  //   month: "short",
  //   day: "2-digit",
  //   year: "numeric",
  // });
  const startTime = meetingData?.starts_at;
  const formattedTime = new Date(`1970-01-01T${startTime}`).toLocaleTimeString(
    "en-US",
    { hour: "2-digit", minute: "2-digit", hour12: true }
  );
  const endTime = meetingData?.real_end_time;
  const formattedEndTime = new Date(`1970-01-01T${endTime}`).toLocaleTimeString(
    "en-US",
    { hour: "2-digit", minute: "2-digit", hour12: true }
  );

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedTitleOrder, setSelectedTitleOrder] = useState(
    meetingData?.title_order
  );
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const applyFilter = async (item) => {
    try {
      setIsLoading(true);
      await getMeeting(item.id); // Fetch meeting data
      setSelectedTitleOrder(item.title_order);
      setDropdownVisible(!dropdownVisible);
      navigate(`/destination/${item.unique_id}/${item.id}`);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false); // Hide loader whether successful or not
    }
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };
  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  // Calculate total action days
  const totalDays = meetingData?.plan_d_actions?.reduce(
    (acc, item) => acc + item.action_days,
    0
  );
  // d.assigned_to_image.startsWith("users") ?  Assets_URL + '/'+ d.assigned_to_image : d.assigned_to_image

  const chartEndDate = new Date(meetingData?.date);
  chartEndDate.setDate(chartEndDate.getDate() + totalDays);
  const formattedChartEndDate = `${chartEndDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${chartEndDate.toLocaleString("default", {
    month: "long",
  })}-${chartEndDate.getFullYear()}`;

  const data = meetingData?.plan_d_actions;
  // Sort data by order
  const sortedData = data?.sort((a, b) => a.order - b.order);

  // useEffect(() => {
  //   if (!sortedData) {
  //     return;
  //   }

  //   const svgWidth = 1000;
  //   const svgHeight = 500;
  //   const margin = { top: 70, right: 20, bottom: 0, left: 50 };
  //   const width = svgWidth - margin.left - margin.right;
  //   const height = svgHeight - margin.top - margin.bottom;

  //   const topBarMargin = 30; // Add margin for the top of the bars

  //   // Calculate total action days
  //   const totalActionDays = sortedData?.reduce(
  //     (acc, item) => acc + item.action_days,
  //     0
  //   );

  //   // Add an extra day to the totalActionDays
  //   const extendedTotalActionDays = totalActionDays + 1;

  //   // Define a color scale for the orders
  //   const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

  //   // Clear any existing svg
  //   d3.select("#gantt-chart").selectAll("*").remove();

  //   const svg = d3
  //     .select("#gantt-chart")
  //     .append("svg")
  //     .attr("width", svgWidth)
  //     .attr("height", svgHeight);

  //   const chart = svg
  //     .append("g")
  //     .attr("transform", `translate(${margin.left},${margin.top})`);

  //   const xScale = d3
  //     .scaleLinear()
  //     .domain([1, extendedTotalActionDays])
  //     .range([0, width]);

  //   const yScale = d3
  //     .scaleBand()
  //     .domain(sortedData?.map((d) => d.action + d.startDate))
  //     .range([height, 0]);

  //   const oddTickValues = Array.from(
  //     { length: extendedTotalActionDays },
  //     (_, i) => i + 1
  //   ).filter((d) => d % 2 !== 0);

  //   const xAxis = d3
  //     .axisTop(xScale)
  //     .tickSize(-height)
  //     .tickFormat((d) => `D${d}`)
  //     .ticks(sortedData.length <= 3 ? extendedTotalActionDays : undefined)
  //     .tickValues(oddTickValues);

  //   const calculateDx = (d) => {
  //     if (d >= 11) {
  //       return "-3em";
  //     } else {
  //       return "-1.7em";
  //     }
  //   };

  //   chart
  //     .append("g")
  //     .call(xAxis)
  //     .selectAll("text")
  //     .style("font-size", "12px")
  //     .style("color", "#B9B9C7")
  //     .style("text-anchor", "middle")
  //     .attr("dx", (d) => calculateDx(d))
  //     .attr("dy", "-2.5em");

  //   chart.selectAll(".domain").remove();

  //   const tooltip = d3
  //     .select("body")
  //     .append("div")
  //     .attr("class", "tooltip")
  //     .style("position", "absolute")
  //     .style("background", "#fff")
  //     .style("border", "1px solid #ccc")
  //     .style("padding", "10px")
  //     .style("display", "none");

  //   let cumulativeDays = {};
  //   let nextAvailableDay = 1;
  //   const ordersWithOverlap = new Set();

  //   sortedData?.forEach((d) => {
  //     if (!cumulativeDays[d.order]) {
  //       cumulativeDays[d.order] = nextAvailableDay;
  //     } else {
  //       ordersWithOverlap.add(d.order);
  //     }
  //     nextAvailableDay = Math.max(
  //       nextAvailableDay,
  //       cumulativeDays[d.order] + d.action_days
  //     );
  //   });

  //   chart
  //     .selectAll(".bar")
  //     .data(sortedData)
  //     .enter()
  //     .append("rect")
  //     .attr("x", (d) => xScale(cumulativeDays[d.order]))
  //     .attr(
  //       "y",
  //       (d, i) =>
  //         yScale(d.action + d.startDate) +
  //         (ordersWithOverlap.has(d.order) ? 10 * (i % 2) : 0) +
  //         topBarMargin
  //     )
  //     .attr("width", (d) => xScale(d.action_days) - xScale(0))
  //     .attr(
  //       "height",
  //       sortedData?.length > 3
  //         ? yScale.bandwidth() / 2
  //         : sortedData.length === 1
  //         ? yScale.bandwidth() / 13
  //         : sortedData.length === 3
  //         ? yScale.bandwidth() / 4
  //         : yScale.bandwidth() / 8
  //     )
  //     .attr("fill", (d) => colorScale(d.order))
  //     .attr(
  //       "rx",
  //       sortedData?.length >= 3
  //         ? yScale.bandwidth() / 5
  //         : yScale.bandwidth() / 20
  //     )
  //     .attr(
  //       "ry",
  //       sortedData?.length >= 3
  //         ? yScale.bandwidth() / 5
  //         : yScale.bandwidth() / 20
  //     );

  //   svg
  //     .append("defs")
  //     .append("clipPath")
  //     .attr("id", "clip-path-rounded")
  //     .append("rect")
  //     .attr("width", 25)
  //     .attr("height", 25)
  //     .attr("rx", 5)
  //     .attr("ry", 5);

  //   // chart
  //   //   .selectAll(".bar")
  //   //   .data(sortedData)
  //   //   .enter()
  //   //   .append("image")
  //   //   .attr("x", (d) =>
  //   //     sortedData.length === 1
  //   //       ? xScale(cumulativeDays[d.order]) + 5
  //   //       : xScale(cumulativeDays[d.order]) - 3
  //   //   )
  //   //   .attr("y", (d, i) =>
  //   //     yScale(d.action + d.startDate) + (ordersWithOverlap.has(d.order) ? 10 * (i % 2) : 0) + yScale.bandwidth() / 5.9 - 12
  //   //   )
  //   //   .attr("width", 30)
  //   //   // .attr("width", (d) => xScale(d.action_days) - xScale(0))

  //   //   .attr("height", 30)
  //   //   .attr("xlink:href", (d) => `${d.assigned_to_image.startsWith("users") ?  Assets_URL + '/'+ d.assigned_to_image : d.assigned_to_image}`)
  //   //   .attr("clip-path", "circle(50%)");

  //   chart
  //     .selectAll(".bar")
  //     .data(sortedData)
  //     .enter()
  //     .append("text")
  //     .attr("x", (d) => xScale(cumulativeDays[d.order]) + 35)
  //     .attr(
  //       "y",
  //       (d, i) =>
  //         yScale(d.action + d.startDate) +
  //         (ordersWithOverlap.has(d.order) ? 10 * (i % 2) : 0) +
  //         yScale.bandwidth() / 4
  //     )
  //     .attr("dy", ".35em")
  //     .style("fill", "white")
  //     .style("font-size", "12px")
  //     .style("text-anchor", "start");

  //   chart.selectAll(".tick text").attr("x", function () {
  //     return (this.getBoundingClientRect().width / 2) * +6;
  //   });

  //   sortedData?.forEach((d) => {
  //     cumulativeDays[d.order] += d.action_days;
  //   });

  //   // Step 1: Filter out duplicate action names
  //   const uniqueActions = sortedData.filter(
  //     (action, index, self) =>
  //       index === self.findIndex((t) => t.action === action.action)
  //   );
  //   const textContainer = d3.select("#text-container").html(""); // Clear any existing content

  //   uniqueActions?.forEach((d, i) => {
  //     const textGroup = textContainer
  //       .append("div")
  //       .attr("class", "text-group")
  //       .style("display", "flex")
  //       .style("align-items", "center")
  //       .style("gap", `10px`)
  //       .style("color", colorScale(d.order))
  //       .style("justify-content", "space-between")
  //       .style("font-weight", "700")
  //       .style("font-size", "14px")
  //       .style(
  //         "height",
  //         `${
  //           sortedData?.length >= 3
  //             ? `${yScale.bandwidth()}px`
  //             : `${yScale.bandwidth()}px`
  //         }`
  //       );
  //     textGroup
  //       .append("img")
  //       .attr(
  //         "src",
  //         d.assigned_to_image?.startsWith("users")
  //           ? Assets_URL + "/" + d.assigned_to_image
  //           : d.assigned_to_image
  //       )
  //       .attr("alt", "Assigned to image")
  //       .style("height", "40px")
  //       .style("width", "40px")
  //       .style("border-radius", "50px");
  //     textGroup
  //       .append("p")
  //       .text(
  //         `${d.action_days} ${t("actions.day")}${d.action_days > 1 ? "s" : ""}`
  //       );
  //     textGroup.append("p").text(d.action);
  //   });
  // }, [sortedData]);

  const steps = meetingData?.steps || [];

  const lastStep = steps[steps.length - 1];
  const lastStepTime = lastStep?.end_time;
  const lastStepFormattedTime = new Date(
    `1970-01-01T${lastStepTime}`
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const calculateTotalDays = (steps) => {
    if (!steps) {
      return;
    }
    return steps?.reduce((total, step) => {
      return total + step.count2;
    }, 0);
  };
  const addDaysToDate = (date, days) => {
    if (!date || !days) {
      return;
    }
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const formatDateInFrench = (dateString) => {
    if (!dateString) {
      return;
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // const startDate = new Date(meetingData?.date);
  const startDate = new Date(`${meetingData?.date}T${meetingData?.start_time}`);

  const totalDays1 = calculateTotalDays(meetingData?.steps);

  let endDateForHour;
  if (meetingData?.type === "Action") {
    // If the meeting type is "Active", treat totalDays as total days
    endDateForHour = new Date(
      startDate.getTime() + totalDays1 * 24 * 60 * 60 * 1000
    );
  } else if (meetingData?.type === "Task") {
    // If the meeting type is "Task", treat totalDays as total hours
    endDateForHour = new Date(
      startDate.getTime() + totalDays1 * 60 * 60 * 1000
    );
  } else if (meetingData?.type === "Quiz") {
    // If the meeting type is "Quiz", treat totalDays as total seconds
    endDateForHour = new Date(startDate.getTime() + totalDays1 * 1000);
  } else {
    // Otherwise, treat totalDays as total minutes
    endDateForHour = new Date(startDate.getTime() + totalDays1 * 60 * 1000);
  }

  const formattedEndDateInHours = formatDateInFrench(endDateForHour);

  const parseTimeTaken = (timeTaken) => {
    if (!timeTaken) {
      return;
    }
    let totalSeconds = 0;

    const parts = timeTaken.split(" - ");

    parts.forEach((part) => {
      const [value, unit] = part?.split(" ");

      switch (unit) {
        case "days":
        case "day":
          totalSeconds += parseInt(value, 10) * 86400; // 1 day = 86400 seconds
          break;
        case "hours":
        case "hour":
          totalSeconds += parseInt(value, 10) * 3600;
          break;
        case "mins":
        case "min":
          totalSeconds += parseInt(value, 10) * 60;
          break;
        case "secs":
        case "sec":
          totalSeconds += parseInt(value, 10);
          break;
        default:
          totalSeconds += parseInt(value, 10) * 60;
          break;
      }
    });

    return totalSeconds;
  };
  function calculateEndDate(steps, initialDate) {
    if (!steps || !initialDate) {
      return;
    }

    // Parse the formatted initial date (yyyy-MM-dd) into a Date object
    const [year, month, day] = initialDate.split("-").map(Number);
    const date = new Date(year, month - 1, day); // month is zero-based

    // Calculate the total time in seconds from the steps
    let totalSeconds = 0;

    steps.forEach((step) => {
      const timeTaken = step.time_taken;
      if (timeTaken) {
        totalSeconds += parseTimeTaken(timeTaken);
      }
    });

    // Convert total seconds into days, hours, minutes, and seconds
    const days = Math.floor(totalSeconds / 86400);
    const hrs = Math.floor((totalSeconds % 86400) / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    // Add the calculated time to the initial date
    date.setDate(date.getDate() + days);
    date.setHours(date.getHours() + hrs);
    date.setMinutes(date.getMinutes() + mins);
    date.setSeconds(date.getSeconds() + secs);

    // Format the date as yyyy-MM-dd
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;

    return formattedDate;
  }

  const formattedEndDateInHoursForEmailInvite = calculateEndDate(
    steps,
    meetingData?.date
  );
  console.log(
    "formattedEndDateInHoursForEmailInvite",
    formattedEndDateInHoursForEmailInvite
  );
  const endDate = addDaysToDate(startDate, totalDays1);
  const formattedEndDate = formatDateInFrench(endDate);

  const convertTo12HourFormat = (time, steps) => {
    if (!time || !steps) {
      return;
    }
    // Check if any step has time unit in seconds
    const hasSeconds = steps?.some((step) => step.time_unit === "seconds");
    console.log("time", time);
    // Extract hours and minutes from the time string
    // let [hour, minute] = time?.split(":").map(Number);

    // Extract hours, minutes and seconds from the time string
    let [hour, minute, second] = time?.split(":").map(Number);

    // Determine AM or PM suffix
    // let suffix = hour >= 12 ? "PM" : "AM";

    // // Convert hour from 24-hour format to 12-hour format
    // hour = hour % 12 || 12; // The hour '0' should be '12'

    // Format the minute to always be two digits
    minute = minute?.toString()?.padStart(2, "0");

    second = second?.toString()?.padStart(2, "0");

    const endTime = hasSeconds
      ? `${hour}h${minute}m${second}`
      : `${hour}h${minute}`;

    return endTime;
  };

  const convertTo12HourFormat1 = (time, steps) => {
    if (!time || !steps) {
      return;
    }
    // Check if any step has time unit in seconds
    const hasSeconds = steps.some((step) => step.time_unit === "seconds");
    console.log("time", time);
    // Extract hours and minutes from the time string
    // let [hour, minute] = time?.split(":").map(Number);

    // Extract hours, minutes and seconds from the time string
    let [hour, minute, second] = time.split(":").map(Number);

    // Determine AM or PM suffix
    let suffix = hour >= 12 ? "PM" : "AM";

    // Convert hour from 24-hour format to 12-hour format
    hour = hour % 12 || 12; // The hour '0' should be '12'

    // Format the minute to always be two digits
    minute = minute.toString().padStart(2, "0");

    second = second.toString().padStart(2, "0");

    const endTime = hasSeconds
      ? `${hour}:${minute}:${second} ${suffix}`
      : `${hour}:${minute} ${suffix}`;

    return endTime;
  };
  const timeUnitsToSeconds = (time, unit) => {
    switch (unit) {
      case "hours":
        return time * 3600;
      case "minutes":
        return time * 60;
      case "seconds":
        return time;
      case "days":
        return time * 86400; // 24 * 60 * 60
      default:
        console.error("Invalid time unit:", unit);
        return 0;
    }
  };
  const calculateEndTime = (start, steps) => {
    if (!start || !Array.isArray(steps)) {
      console.error("Invalid start time or steps:", { start, steps });
      return "Invalid time";
    }

    // Check if any step has time unit in seconds
    const hasSeconds = steps.some((step) => step.time_unit === "seconds");

    // Calculate total seconds from all steps
    const totalSeconds = steps.reduce((total, step) => {
      return total + timeUnitsToSeconds(step.count2, step.time_unit);
    }, 0);

    // Convert start time to seconds
    const [startHours, startMinutes, startSeconds] = start
      .split(":")
      .map(Number);
    const startInSeconds =
      startHours * 3600 + startMinutes * 60 + (startSeconds || 0);

    // Calculate end time in seconds
    const endInSeconds = startInSeconds + totalSeconds;

    // Convert end time in seconds back to HH:MM:SS
    let endHours = Math.floor(endInSeconds / 3600) % 24;
    const endMinutes = Math.floor((endInSeconds % 3600) / 60);
    const endSeconds = endInSeconds % 60;

    // Determine AM/PM
    const period = endHours >= 12 ? "PM" : "AM";
    endHours = endHours % 12 || 12; // Convert to 12-hour format, ensuring 0 becomes 12

    // Format end time to HH:MM or HH:MM:SS based on hasSeconds
    const formattedEndHours = endHours.toString().padStart(2, "0");
    const formattedEndMinutes = endMinutes.toString().padStart(2, "0");
    const formattedEndSeconds = endSeconds.toString().padStart(2, "0");

    const endTime = hasSeconds
      ? `${formattedEndHours}:${formattedEndMinutes}:${formattedEndSeconds} ${period}`
      : `${formattedEndHours}:${formattedEndMinutes} ${period}`;

    return endTime;
  };

  const calculateRealEndTime = (steps) => {
    if (steps?.length === 0 || !steps) return null;

    const convertToSeconds = (timeTaken) => {
      if (!timeTaken) return 0;
      let totalSeconds = 0;
      const parts = timeTaken.split(" - ");
      parts.forEach((part) => {
        if (part.includes("day")) {
          totalSeconds += parseInt(part) * 86400; // 1 day = 86400 seconds
        } else if (part.includes("hour")) {
          totalSeconds += parseInt(part) * 3600; // 1 hour = 3600 seconds
        } else if (part.includes("min")) {
          totalSeconds += parseInt(part) * 60; // 1 minute = 60 seconds
        } else if (part.includes("sec")) {
          totalSeconds += parseInt(part); // seconds
        }
      });
      return totalSeconds;
    };

    const totalTimeInSeconds = steps.reduce(
      (acc, step) => acc + convertToSeconds(step.time_taken),
      0
    );

    const firstStepCurrentTime = steps[0].current_time
      ? moment(steps[0]?.current_time, "HH:mm:ss")
      : moment(meeting?.starts_at, "HH:mm:ss");
    if (!firstStepCurrentTime) return null;

    const realEndTime = firstStepCurrentTime
      .add(totalTimeInSeconds, "seconds")
      .format("HH:mm:ss");

    return realEndTime;
  };
  const convertTo12HourFormatDate = (time, steps) => {
    if (!time) return "N/A";
    // Assuming time is in HH:mm:ss format
    const timeMoment = moment(time, "HH:mm:ss");
    return timeMoment.isValid() ? timeMoment.format("hh:mm A") : "N/A";
  };
  const realEndTime = calculateRealEndTime(meetingData?.steps);

  // const processStepTime = (stepTime, type) => {
  //   if (type === "Task") {
  //      // Remove seconds from the time
  //      const [time, period] = stepTime.split(' ');
  //      const [hours, minutes] = time.split(':').slice(0, 2);
  //      return `${hours}:${minutes} ${period}`;
  //   } else {
  //     // Return time as is
  //     return stepTime;
  //   }
  // };

  // // Ensure steps is defined and has at least one element
  // const lastStepEndTime = meetingData?.steps && meetingData?.steps.length > 0
  //   ? processStepTime(meetingData?.steps[meetingData?.steps.length - 1]?.step_time, meetingData?.type)
  //   : '';
  // Function to convert time_taken string to minutes

  const convertTimeToMinutes = (timeTaken) => {
    if (!timeTaken) return;
    const parts = timeTaken.split(" - ");
    let totalMinutes = 0;

    parts.forEach((part) => {
      const [amount, unit] = part.split(" ");
      const value = parseInt(amount, 10);
      switch (unit) {
        case "days":
        case "day":
          totalMinutes += value * 24 * 60;
          break;
        case "hours":
        case "hour":
          totalMinutes += value * 60;
          break;
        case "minutes":
        case "min":
        case "mins":
          totalMinutes += value;
          break;
        case "seconds":
        case "sec":
        case "secs":
          totalMinutes += value / 60;
          break;
        default:
          break;
      }
    });

    return totalMinutes;
  };

  // Function to calculate end time
  const calculateLastStepEndTime = (meeting) => {
    if (
      !meeting ||
      !meeting.start_time ||
      !meeting.steps ||
      meeting.steps.length === 0
    ) {
      return "";
    }

    // Convert start time to a moment object
    let endTime = moment(
      `${meeting.date} ${meeting.start_time}`,
      "YYYY-MM-DD HH:mm:ss"
    );

    // Add each step's time_taken to the endTime
    meeting.steps.forEach((step) => {
      const stepMinutes = convertTimeToMinutes(step.time_taken);
      endTime = endTime.add(stepMinutes, "minutes");
    });

    // Format endTime to 12-hour format with leading zeros and AM/PM
    return endTime.format("hh:mm A");
  };

  const lastStepEndTime = calculateLastStepEndTime(meeting);

  const filteredActions = meetingData?.plan_d_actions?.reduce((acc, item) => {
    // Find if an entry with the same action, order, and action_days already exists
    const existing = acc.find(
      (obj) =>
        obj.action === item.action &&
        obj.order === item.order &&
        obj.action_days === item.action_days
    );

    // If it exists, we skip adding the current item to the accumulator
    if (!existing) {
      acc.push(item);
    }

    return acc;
  }, []);

  const guideEmails = new Set(meetingData?.guides?.map((guide) => guide.email));

  //Participant
  const [showProfile, setShowProfile] = useState(false);
  const handleShow = () => {
    setShowProfile(true);
  };
  const hideShow = () => {
    setShowProfile(false);
  };

  //Host
  const [showHostProfile, setShowHostProfile] = useState(false);
  const handleHostShow = () => {
    setShowHostProfile(true);
  };
  const hideHostShow = () => {
    setShowHostProfile(false);
  };

  //Closed Report Participant
  const [uuid, setUuid] = useState(null);
  console.log("uuid", uuid);
  const [show, setShow] = useState(false);
  const handleProfile = (userId) => {
    setShow(true);
    setUuid(userId);
  };
  const handleHide = () => {
    setShow(false);
  };

  //Closed Report Host
  const [hostUuid, setHostUuid] = useState(null);
  const [showHost, setShowHost] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const handleHostProfile = (userId) => {
    setShowHost(true);
    setHostUuid(userId);
  };
  const handleHostHide = () => {
    setShowHost(false);
  };

  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgot, setShowForgot] = useState(false);

  const handleShowSignIn = () => {
    setShowSignUp(false);
    setShowSignIn(true);
  };

  const handleShowSignUp = () => {
    setShowSignIn(false);
    setShowSignUp(true);
  };

  const handleShowForgot = () => {
    setShowSignIn(false);
    setShowSignUp(false);
    setShowForgot(true);
  };

  const handleCloseSignIn = () => setShowSignIn(false);
  const handleCloseSignUp = () => setShowSignUp(false);
  const handleCloseForgot = () => setShowForgot(false);


  const { toggle } = useSidebarContext();
  const handleClose = () => {
    console.log("handleClose");
    toggle(true);
    setIsModalOpen(false);
    // onClose();
  };

  const handleLogin = () => {
    navigate("/login", {
      state: {
        fromInvitePage: true,
        invitePageURL: window.location.pathname + window.location.search,
      },
    });
  };

  const [password, setPassword] = useState(null);
  const checkPassword = async () => {
    const payload = {
      password: password,
      meeting_id: meetId,
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/check-meeting-password`,
        payload
      );
      console.log("password checking response", response);
      if (response?.status) {
        toggle(true);
        handleClose();
        // Proceed to call the next APIs after successful password verification
        // if(response?.data?.data?.status === "active" || response?.data?.data?.status === "in_progress"){

        // } else{
        await getActiveMeetingByID();
        await getMeetingByID();
        // }
        setMeetingData(response?.data?.data);
        // getRefreshMeeting()
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Incorrect password");
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          className="center-spinner"
        ></Spinner>
      ) : (
        <>
          {meetingData?.status === "closed" ? (
            <>
              <div
                className="email-invite report w-100"
                style={{ position: fromMeeting ? "static" : "relative" }}
              >
                {!fromMeeting && (
                  <div className="gradient-header">
                    <svg
                      width="100%"
                      height="338"
                      viewBox="0 0 1453 338"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_902_7997)">
                        <rect
                          width="100%"
                          height="271"
                          fill="url(#paint0_linear_902_7997)"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_902_7997"
                          x="-66.5"
                          y="-66.5"
                          width="1573"
                          height="404"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="33.25"
                            result="effect1_foregroundBlur_902_7997"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_902_7997"
                          x1="856"
                          y1="281.934"
                          x2="863.131"
                          y2="-138.913"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" />
                          <stop offset="1" stop-color="#76C3EE" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                )}
                <div className="content">
                  {!fromMeeting && (
                    <div className="row" style={{ height: "35vh" }}>
                      <div className="col-md-8 d-flex flex-column">
                        <h5 className="content-heading fw-bold">
                          {meetingData?.objective}
                        </h5>
                      </div>

                      <div className="col-md-4">
                        <div className="position-relative">
                          {/* <HostCard data={meeting?.user} /> */}
                          <Card className="mt-3 host-card">
                            <Card.Body>
                              <div className="d-flex justify-content-center">
                                <div className="profile-logo">
                                  <Card.Img
                                    className="avatar-img"
                                    src={
                                      meetingData?.user?.enterprise?.logo
                                        ? Assets_URL +
                                          "/" +
                                          meetingData?.user?.enterprise?.logo
                                        : "/Assets/logo2.png"
                                    }
                                  />
                                </div>
                              </div>

                              <Card.Title className="text-center mt-3 card-heading">
                                {meetingData?.user?.enterprise?.name}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                      {/* ------------------------------------------------ HOST */}
                    </div>
                  )}
                  <div className="row">
                    <div className={`col-md-6 ${fromMeeting ? "w-100" : ""}`}>
                      <div className="dropdown-container" ref={dropdownRef}>
                        <h5 className="content-heading-title">
                          {/* {selectedTitleOrder}&nbsp; */}
                          {selectedTitleOrder && (
                            <>{selectedTitleOrder}&nbsp;</>
                          )}

                          {meetingData.title}
                          {allMeetings?.length > 1 ? (
                            <span>
                              <MdKeyboardArrowDown
                                size={30}
                                onClick={toggleDropdown}
                                style={{
                                  cursor: "pointer",
                                  // marginLeft: ".6rem",
                                  marginBottom: "6px",
                                }}
                              />
                            </span>
                          ) : null}
                        </h5>
                        {dropdownVisible && (
                          <div className="dropdown-content-filter">
                            <div className="dropdown-section">
                              {allMeetings
                                // ?.filter((item) => item.id !== meetId)
                                ?.filter(
                                  (item) =>
                                    item.id.toString() !== meetId.toString()
                                )
                                ?.map((item, index) => {
                                  return (
                                    <>
                                      <div
                                        key={index}
                                        onClick={() => applyFilter(item)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <b>{item.title_order}</b>.&nbsp;{" "}
                                        {item.title}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* // )} */}
                      <div className="d-flex align-items-center gap-2 content-body">
                        <div className="d-flex align-items-center gap-2">
                          <img src="/Assets/invite-date.svg" />
                          {/* <span className="fw-bold formate-date">
                            {formattedDate}
                          </span>
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.14258 2.5C4.83008 2.5 2.14258 5.1875 2.14258 8.5C2.14258 11.8125 4.83008 14.5 8.14258 14.5C11.4551 14.5 14.1426 11.8125 14.1426 8.5C14.1426 5.1875 11.4551 2.5 8.14258 2.5Z"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M8.14258 4.5V9H11.1426"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                          {/* <span className="fw-bold formate-date">
                            {formattedTime}
                          </span>{" "}
                          -
                          <span className="fw-bold formate-date">
                            {formattedEndTime}
                          </span> */}
                          {meetingData?.type === "Action" ||
                          meetingData?.type === "Newsletter" ? (
                            <>
                              {/* <span className="fw-bold formate-date">
                                {formatDateInFrench(meetingData?.current_date)}
                              </span>
                              - */}
                              <>
                                <span className="fw-bold formate-date">
                                  {formattedDate}
                                  &nbsp; -
                                </span>
                                <span className="fw-bold formate-date">
                                  {/* {formattedDateActive} */}
                                  {formattedEndDateInHours}
                                </span>
                              </>
                              {/* <span className="fw-bold formate-date">
                                {
                                  meetingData?.steps[
                                    meetingData?.steps.length - 1
                                  ]?.end_date
                                }
                              </span> */}
                            </>
                          ) : (
                            <>
                              {/* <img src="/Assets/invite-date.svg" /> */}
                              <span className="fw-bold formate-date">
                                {formattedDate}
                                &nbsp; {t("at")}
                              </span>
                              <span className="fw-bold formate-date">
                                {convertTo12HourFormat(
                                  meetingData?.starts_at,
                                  meetingData?.steps
                                )}{" "}
                                -{" "}
                              </span>
                              <span className="fw-bold formate-date">
                                {formattedEndDateInHours}
                                &nbsp; {t("at")}
                              </span>
                              <span className="fw-bold formate-date">
                                {/* {lastStepFormattedTime} */}
                                {/* {realEndTime
                                  ? convertTo12HourFormat(
                                      realEndTime,
                                      meetingData?.steps
                                    )
                                  : "N/A"} */}
                                {estimateTime}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      {/* <div className="paragraph-parent">
                  <h3 className="moment-summary">Moments Summary</h3>
                  <span className="paragraph">{meetingData?.description}</span>
                </div> */}
                    </div>
                  </div>
                  <div className="row">
                    {show ? (
                      <div
                        style={{ background: "white", padding: "29px 13px" }}
                      >
                        <div className="paragraph-parent">
                          <h3 className="moment-summary">
                            <IoArrowBackSharp
                              onClick={handleHide}
                              size={25}
                              style={{
                                cursor: "pointer",
                                marginRight: "1rem",
                              }}
                            />
                            {t("momentsSummary")}
                          </h3>
                        </div>
                        <ParticipantCardProfile
                          userId={uuid}
                          handleHide={handleHide}
                          isUser={isUser}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="col-md-8">
                          <div className="paragraph-parent">
                            <h3 className="moment-summary">
                              {t("momentsSummary")}
                            </h3>
                            <p className="paragraph">
                              {/* {meetingData?.description} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: meetingData?.description || "",
                                }}
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div style={{ marginTop: "6rem" }}>
                            <span className="participant-heading">Guide</span>
                            {meetingData?.type === "Newsletter" && (
                              <Card className="mt-4 participant-card">
                                <Card.Body className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="profile-logo">
                                      <Card.Img
                                        className="user-img"
                                        src={
                                          meetingData?.user?.image?.startsWith(
                                            "users/"
                                          )
                                            ? Assets_URL +
                                              "/" +
                                              meetingData?.user?.image
                                            : meetingData?.user?.image
                                        }
                                      />
                                      <Card.Img
                                        className="logout-icon"
                                        src="/Assets/Avatar_company.svg"
                                        height="15px"
                                        width="15px"
                                        alt="tektime"
                                      />
                                    </div>

                                    <div className="d-flex flex-column align-items-start">
                                      <Card.Title className="text-center mt-4 card-heading">
                                        {meetingData?.user?.name +
                                          " " +
                                          meetingData?.user?.last_name}
                                      </Card.Title>
                                      <Card.Subtitle className="mb-2 card-subtext">
                                        {meetingData?.user?.post}
                                      </Card.Subtitle>
                                      <Card.Subtitle className="mb-2 card-subtext">
                                        {meetingData?.user?.teams?.map(
                                          (item) => item.name
                                        )}
                                      </Card.Subtitle>
                                      <div
                                        className="visiting-card-link"
                                        onClick={() => {
                                          handleProfile(
                                            meetingData?.user?.nick_name
                                          );
                                          setIsUser(true);
                                        }}
                                      >
                                        {t("viewVisitingCard")} &nbsp;{" "}
                                        <FaArrowRight />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <svg
                                      width="21"
                                      height="21"
                                      viewBox="0 0 21 21"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.4965 9.99542C18.534 9.99542 20.9965 12.4578 20.9965 15.4954C20.9965 18.5329 18.534 20.9954 15.4965 20.9954C12.4589 20.9954 9.99649 18.5329 9.99649 15.4954C9.99649 12.4578 12.4589 9.99542 15.4965 9.99542ZM10.0187 11.9947C9.37159 13.0051 8.99649 14.2064 8.99649 15.4954C8.99649 17.1257 9.59669 18.6158 10.5882 19.7569C9.79269 19.917 8.92779 19.9964 7.99649 19.9964C4.57549 19.9964 2.05252 18.9243 0.51036 16.7599C0.1784 16.294 0 15.7362 0 15.1641V14.2442C0 13.0021 1.00685 11.9953 2.24887 11.9953L10.0187 11.9947ZM12.85 15.1418C12.6548 14.9465 12.3382 14.9465 12.1429 15.1418C11.9476 15.3371 11.9476 15.6537 12.1429 15.8489L14.1429 17.8489C14.3382 18.0442 14.6548 18.0442 14.85 17.8489L18.85 13.8489C19.0453 13.6537 19.0453 13.3371 18.85 13.1418C18.6548 12.9465 18.3382 12.9465 18.1429 13.1418L14.4965 16.7882L12.85 15.1418ZM7.99649 0C10.7579 0 12.9965 2.23858 12.9965 5C12.9965 7.76143 10.7579 10 7.99649 10C5.23503 10 2.99645 7.76143 2.99645 5C2.99645 2.23858 5.23503 0 7.99649 0Z"
                                        fill="#58985B"
                                      />
                                    </svg>
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                          </div>
                          <div className="">
                            {meetingData?.type !== "Newsletter" &&
                              meetingData?.guides
                                // ?.filter((guide) => guide.isCreator !== 1)
                                ?.map((guide, index) => (
                                  <Card
                                    key={index}
                                    className="mt-4 participant-card"
                                  >
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center gap-3">
                                        <div className="profile-logo">
                                          <Card.Img
                                            className="user-img"
                                            src={
                                              guide.participant_image.startsWith(
                                                "users/"
                                              )
                                                ? Assets_URL +
                                                  "/" +
                                                  guide.participant_image
                                                : guide.participant_image
                                            }
                                          />
                                          <Card.Img
                                            className="logout-icon"
                                            src="/Assets/Avatar_company.svg"
                                            height="15px"
                                            width="15px"
                                            alt="tektime"
                                          />
                                        </div>

                                        <div className="d-flex flex-column align-items-start">
                                          <Card.Title className="text-center mt-4 card-heading">
                                            {guide?.first_name +
                                              " " +
                                              guide?.last_name}
                                          </Card.Title>
                                          <Card.Subtitle className="mb-2 card-subtext">
                                            {guide?.post}
                                          </Card.Subtitle>
                                          <Card.Subtitle className="mb-2 card-subtext">
                                            {guide?.team_names
                                              ?.map((item) => item)
                                              .join(", ")}
                                          </Card.Subtitle>
                                          {guide?.user_id && (
                                            <div
                                              className="visiting-card-link"
                                              onClick={() => {
                                                handleProfile(guide?.id);
                                                setIsUser(false);
                                              }}
                                            >
                                              {t("viewVisitingCard")} &nbsp;{" "}
                                              <FaArrowRight />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <svg
                                          width="21"
                                          height="21"
                                          viewBox="0 0 21 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M15.4965 9.99542C18.534 9.99542 20.9965 12.4578 20.9965 15.4954C20.9965 18.5329 18.534 20.9954 15.4965 20.9954C12.4589 20.9954 9.99649 18.5329 9.99649 15.4954C9.99649 12.4578 12.4589 9.99542 15.4965 9.99542ZM10.0187 11.9947C9.37159 13.0051 8.99649 14.2064 8.99649 15.4954C8.99649 17.1257 9.59669 18.6158 10.5882 19.7569C9.79269 19.917 8.92779 19.9964 7.99649 19.9964C4.57549 19.9964 2.05252 18.9243 0.51036 16.7599C0.1784 16.294 0 15.7362 0 15.1641V14.2442C0 13.0021 1.00685 11.9953 2.24887 11.9953L10.0187 11.9947ZM12.85 15.1418C12.6548 14.9465 12.3382 14.9465 12.1429 15.1418C11.9476 15.3371 11.9476 15.6537 12.1429 15.8489L14.1429 17.8489C14.3382 18.0442 14.6548 18.0442 14.85 17.8489L18.85 13.8489C19.0453 13.6537 19.0453 13.3371 18.85 13.1418C18.6548 12.9465 18.3382 12.9465 18.1429 13.1418L14.4965 16.7882L12.85 15.1418ZM7.99649 0C10.7579 0 12.9965 2.23858 12.9965 5C12.9965 7.76143 10.7579 10 7.99649 10C5.23503 10 2.99645 7.76143 2.99645 5C2.99645 2.23858 5.23503 0 7.99649 0Z"
                                            fill="#58985B"
                                          />
                                        </svg>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                ))}
                          </div>

                          {meetingData?.type !== "Newsletter" && (
                            <>
                              {meetingData?.participants?.filter(
                                // (item) => item.isCreator === 0
                                (item) =>
                                  // item.isCreator === 0 &&
                                  !guideEmails.has(item.email)
                              )?.length > 0 && (
                                <div className="mt-4">
                                  <span className="participant-heading">
                                    {t("invite")}
                                  </span>{" "}
                                  <span
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: "16px",
                                      fontWeight: 400,
                                      lineHeight: "18.75px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {"(" +
                                      meetingData?.participants?.filter(
                                        (item) =>
                                          // item.attandance === 0 &&
                                          item.isCreator === 0 &&
                                          !guideEmails.has(item.email)
                                      ).length +
                                      "/" +
                                      meetingData?.participants?.filter(
                                        (item) =>
                                          // item.isCreator === 0 &&
                                          !guideEmails.has(item.email)
                                      ).length +
                                      ")"}
                                  </span>
                                  {meetingData?.participants
                                    ?.filter(
                                      // (item) => item.isCreator === 0
                                      (item) =>
                                        // item.isCreator === 0 &&
                                        !guideEmails.has(item.email)
                                    )
                                    ?.map((item, index) => {
                                      // if (item.isCreator === 1) {
                                      //   return null;
                                      // }
                                      return (
                                        <>
                                          <Card className="mt-4 participant-card">
                                            <Card.Body className="d-flex justify-content-between align-items-center">
                                              <div className="d-flex align-items-center gap-3">
                                                <div className="profile-logo">
                                                  {item.participant_image?.startsWith(
                                                    "users/"
                                                  ) ? (
                                                    <Card.Img
                                                      className="user-img"
                                                      src={`${Assets_URL}/${item?.participant_image}`}
                                                    />
                                                  ) : item.participant_image ? (
                                                    <Card.Img
                                                      className="user-img"
                                                      src={
                                                        item?.participant_image
                                                      }
                                                    />
                                                  ) : (
                                                    <Card.Img
                                                      className="user-img"
                                                      src="/Assets/avatar.jpeg"
                                                    />
                                                  )}
                                                  <Card.Img
                                                    className="logout-icon"
                                                    src="/Assets/Avatar_company.svg"
                                                    height="15px"
                                                    width="15px"
                                                    alt="tektime"
                                                  />
                                                </div>

                                                <div className="d-flex flex-column align-items-start">
                                                  <Card.Title className="text-center mt-4 card-heading">
                                                    {item.first_name +
                                                      " " +
                                                      item.last_name}
                                                  </Card.Title>
                                                  <Card.Subtitle className="mb-2 card-subtext">
                                                    {item?.post}
                                                  </Card.Subtitle>
                                                  <Card.Subtitle className="mb-2 card-subtext">
                                                    {item?.team_names
                                                      ?.map((item) => item)
                                                      .join(", ")}
                                                  </Card.Subtitle>
                                                  {item?.user_id && (
                                                    <div
                                                      className="visiting-card-link"
                                                      onClick={() =>
                                                        handleProfile(item?.id)
                                                      }
                                                    >
                                                      {t("viewVisitingCard")}{" "}
                                                      &nbsp; <FaArrowRight />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div>
                                                {item.attandance == 0 ? (
                                                  <svg
                                                    width="21"
                                                    height="21"
                                                    viewBox="0 0 21 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M15.4965 9.99542C18.534 9.99542 20.9965 12.4578 20.9965 15.4954C20.9965 18.5329 18.534 20.9954 15.4965 20.9954C12.4589 20.9954 9.99649 18.5329 9.99649 15.4954C9.99649 12.4578 12.4589 9.99542 15.4965 9.99542ZM10.0187 11.9947C9.37159 13.0051 8.99649 14.2064 8.99649 15.4954C8.99649 17.1257 9.59669 18.6158 10.5882 19.7569C9.79269 19.917 8.92779 19.9964 7.99649 19.9964C4.57549 19.9964 2.05252 18.9243 0.51036 16.7599C0.1784 16.294 0 15.7362 0 15.1641V14.2442C0 13.0021 1.00685 11.9953 2.24887 11.9953L10.0187 11.9947ZM12.85 15.1418C12.6548 14.9465 12.3382 14.9465 12.1429 15.1418C11.9476 15.3371 11.9476 15.6537 12.1429 15.8489L14.1429 17.8489C14.3382 18.0442 14.6548 18.0442 14.85 17.8489L18.85 13.8489C19.0453 13.6537 19.0453 13.3371 18.85 13.1418C18.6548 12.9465 18.3382 12.9465 18.1429 13.1418L14.4965 16.7882L12.85 15.1418ZM7.99649 0C10.7579 0 12.9965 2.23858 12.9965 5C12.9965 7.76143 10.7579 10 7.99649 10C5.23503 10 2.99645 7.76143 2.99645 5C2.99645 2.23858 5.23503 0 7.99649 0Z"
                                                      fill="#58985B"
                                                    />
                                                  </svg>
                                                ) : (
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M17.5004 12.0003C20.5379 12.0003 23.0004 14.4627 23.0004 17.5003C23.0004 20.5378 20.5379 23.0003 17.5004 23.0003C14.4628 23.0003 12.0004 20.5378 12.0004 17.5003C12.0004 14.4627 14.4628 12.0003 17.5004 12.0003ZM12.023 13.999C11.3757 15.0096 11.0004 16.2111 11.0004 17.5003C11.0004 19.144 11.6105 20.6452 12.6166 21.7898C11.8153 21.9316 10.9423 22.0013 10.0004 22.0013C7.11087 22.0013 4.87205 21.3447 3.30918 20.001C2.48056 19.2887 2.00391 18.2503 2.00391 17.1575V16.2502C2.00391 15.0075 3.01127 14.0002 4.25391 14.0002L12.023 13.999ZM20.8096 15.2525L15.2526 20.8095C15.8932 21.2454 16.667 21.5003 17.5004 21.5003C19.7095 21.5003 21.5004 19.7094 21.5004 17.5003C21.5004 16.6669 21.2455 15.8931 20.8096 15.2525ZM17.5004 13.5003C15.2912 13.5003 13.5004 15.2911 13.5004 17.5003C13.5004 18.3336 13.7552 19.1074 14.1912 19.748L19.7481 14.1911C19.1075 13.7551 18.3337 13.5003 17.5004 13.5003ZM10.0004 2.00488C12.7618 2.00488 15.0004 4.24346 15.0004 7.00488C15.0004 9.76631 12.7618 12.0049 10.0004 12.0049C7.23894 12.0049 5.00036 9.76631 5.00036 7.00488C5.00036 4.24346 7.23894 2.00488 10.0004 2.00488Z"
                                                      fill="#ED2F2F"
                                                    />
                                                  </svg>
                                                )}
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        </>
                                      );
                                    })}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {meetingData?.steps?.length > 0 && (
                    <div className="cards-section">
                      <div>
                        <span className="participant-heading">
                          {`${t("meeting.newMeeting.labels.Program")} `}
                        </span>
                        <ReportStepCard
                          data={meetingData?.steps}
                          meeting={meetingData}
                          startTime={formattedTime}
                          users={{
                            ...meetingData?.user,
                            firstName: meetingData?.user?.name,
                            lastName: meetingData?.user?.last_name,
                            image:
                              meetingData?.user?.assigned_to_image ||
                              meetingData?.user?.image ||
                              "/Assets/avatar.jpeg",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {meetingData.step_decisions &&
                    meetingData.step_decisions.some(
                      (decision) => decision !== null
                    ) && (
                      <div className="decision-section">
                        <div style={{ marginTop: "2rem" }}>
                          <span className="participant-heading">
                            {`${t("meeting.newMeeting.labels.decisions")} `}
                          </span>
                          <DecisionCard
                            data={meetingData?.step_decisions}
                            meeting={meetingData}
                          />
                        </div>
                      </div>
                    )}
                  {meetingData?.plan_d_actions?.length > 0 && (
                    <div className="chart-section">
                      <div style={{ margin: "5rem 0 5rem 0" }}>
                        <span className="participant-heading">
                          {`${t("Strategy")} `}
                        </span>
                      </div>

                      {/* <div style={{ display: "flex" }}>
                        <div>
                          <div className="d-flex gap-4 justify-content-between">
                            <div>
                              <strong style={{ color: "transparent" }}>
                                Participants
                              </strong>
                            </div>

                            <div>
                              <strong>{t("actions.days")}</strong>
                              <p>
                                {data
                                  ?.reduce(
                                    (acc, item) =>
                                      acc + parseFloat(item.action_days),
                                    0
                                  )
                                  .toFixed(0)}{" "}
                                {t("actions.days")}
                              </p>
                            </div>
                            <div>
                              <strong>Completion</strong>

                              <p>{formattedChartEndDate}</p>
                            </div>
                          </div>
                          <div
                            id="text-container"
                            className="d-flex flex-column-reverse"
                          ></div>
                        </div>

                        <div id="gantt-chart"></div>
                      </div> */}

                      <div className="col-md-12">
                        <Table bordered className="action-table">
                          <thead>
                            <tr className="table-row">
                              {/* <th className="table-row-head"></th> */}
                              <th
                                className="table-row-head"
                                style={{
                                  paddingLeft: "38px",
                                }}
                              >
                                {t("tasks")}
                              </th>
                              {/* <th className="table-row-head text-center">
                              {t("order")}
                            </th>
                            <th className="table-row-head text-center">
                              {t("status")}
                            </th> */}
                              <th className="table-row-head text-center">
                                {t("duration")}
                                {/* &nbsp; ({t("actions.days")}) */}
                              </th>
                              <th className="table-row-head text-center">
                                {t("participant")}
                              </th>
                              {/* <th>Status</th> */}
                              {/* {role !== "User" && <th className="text-center">Action</th>} */}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredActions?.map((user, index) => (
                              <tr className="table-data" key={user?.id}>
                                {/* <td
                                style={{
                                  paddingLeft: "1rem",
                                  textAlign: "center",
                                }}
                                className="table-data-row"
                              >
                                <label className="custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={!!selectedUsers[user.id]}
                                    onChange={() =>
                                      handleCheckboxChange(user.id)
                                    }
                                  />
                                </label>
                              </td> */}
                                <td
                                  style={{ width: "70%", paddingLeft: "28px" }}
                                  className="table-data-row"
                                >
                                  {user?.action}
                                </td>
                                {/* <td className="text-center table-data-row">
                             
                              </td>
                              <td className="text-center table-data-row">
                                {user?.status}
                              </td> */}
                                <td className="text-center table-data-row ">
                                  <span className="duree">
                                    {String(user?.action_days).split(".")[0]}
                                  </span>
                                  {/* {t('days')} */}
                                </td>
                                <td className="text-center table-data-row">
                                  <Avatar
                                    src={
                                      user?.participant_image?.includes("users")
                                        ? Assets_URL +
                                          "/" +
                                          user?.participant_image
                                        : user?.participant_image
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}

                  {/* {hasFiles && (
              <div className="files-section">
                <div style={{ marginTop: "5rem" }}>
                  <span className="participant-heading">
                    {`${t("meeting.newMeeting.labels.file")} `}
                  </span>
                  <div className="d-flex gap-3">
                    {meetingData?.steps?.map((item, index) => {
                      if (!item.file) {
                        return null;
                      }
                      const fileName = item?.file?.split("-")[1];
                      console.log("fileName", fileName);
                      return (
                        <Card
                          key={index}
                          style={{ width: "max-content" }}
                          className="mt-4 participant-card"
                        >
                          <Card.Body>
                            <div className="d-flex flex-column gap-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex">
                                  <div className="file-icon">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5ZM14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5ZM18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5ZM12 10.5H13V13.5H12V10.5ZM7 10.5H8V11.5H7V10.5Z"
                                        fill="#D43E3E"
                                      />
                                    </svg>
                                  </div>
                                  <div className="file-name">{fileName}</div>
                                </div>

                                <div>
                                  <BsThreeDotsVertical />
                                </div>
                              </div>

                              <div className="d-flex flex-column align-items-center">
                                <div
                                  style={{
                                    background: "#eaeaef",
                                    borderRadius: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Card.Img
                                    className="fileReport-img img-fluid"
                                    src="/Assets/pdf-svgrepo-com.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })}
                  </div>
                </div>
              </div>
            )} */}

                  <div className="d-flex justify-content-center align-items-center view-count">
                    <div className="d-flex flex-column align-items-center">
                      <span>
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
                            stroke="black"
                            stroke-width="2"
                          />
                          <path
                            d="M21 12.5C21 12.5 20 4.5 12 4.5C4 4.5 3 12.5 3 12.5"
                            stroke="black"
                            stroke-width="2"
                          />
                        </svg>
                      </span>
                      <p className="page-count">{pageViews} page views</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {isModalOpen ? (
                <div className="confirmation-modal">
                <div className="confirmation-modal-content">
                  <p>{visibilityMessage}</p>
                  {visibilityMessage ===
                  "Please log in to view meeting." ? (
                    <button
                      className="btn btn-primary"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  ) : visibilityMessage ===
                    "Please enter password to view meeting." ? (
                    <div className="d-flex flex-column gap-2">
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            checkPassword();
                          }
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={checkPassword}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}
                  {!window.location.href.includes("destination") && (
                    <button className="btn-no" onClick={handleClose}>
                      {t("confirmationModal.close")}
                    </button>
                  )}
                </div>
              </div>
              ) : (
                <>
                  <div className="email-invite w-100">
                    <div className="gradient-header">
                      <svg
                        width="100%"
                        height="338"
                        viewBox="0 0 1453 338"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_f_902_7997)">
                          <rect
                            width="100%"
                            height="271"
                            fill="url(#paint0_linear_902_7997)"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_f_902_7997"
                            x="-66.5"
                            y="-66.5"
                            width="1573"
                            height="404"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feGaussianBlur
                              stdDeviation="33.25"
                              result="effect1_foregroundBlur_902_7997"
                            />
                          </filter>
                          <linearGradient
                            id="paint0_linear_902_7997"
                            x1="856"
                            y1="281.934"
                            x2="863.131"
                            y2="-138.913"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#76C3EE" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="content">
                      <div className="row" style={{ height: "35vh" }}>
                        <div className="col-12 col-md-8 d-flex flex-column flex-md-row align-items-center align-items-md-start text-center text-md-start">
                          <h5 className="content-heading fw-bold">
                            {meetingData?.objective}
                          </h5>
                        </div>

                        <div className="col-md-4">
                          <div className="position-relative">
                            <Card className="mt-3 host-card">
                              <Card.Body>
                                <div className="d-flex justify-content-center">
                                  <div className="profile-logo">
                                    <Card.Img
                                      className="avatar-img"
                                      src={
                                        meetingData?.user?.enterprise?.logo
                                          ? Assets_URL +
                                            "/" +
                                            meetingData?.user?.enterprise?.logo
                                          : "/Assets/logo2.png"
                                      }
                                    />
                                  </div>
                                </div>

                                <Card.Title className="text-center mt-3 card-heading">
                                  {/* {meeting?.user?.name + " " + meeting?.user?.last_name} */}
                                  {meetingData?.user?.enterprise?.name}
                                </Card.Title>
                                {/* <Card.Subtitle className="mb-2 card-subtext">
                        {meeting?.user?.post} -{" "}
                        {meeting?.user?.teams?.map((item) => item.name)}
                      </Card.Subtitle> */}
                              </Card.Body>
                            </Card>
                          </div>
                        </div>

                        {/* ------------------------------------------------ HOST */}
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="dropdown-container" ref={dropdownRef}>
                            <h5 className="content-heading-title">
                              {selectedTitleOrder}&nbsp;
                              {meetingData?.title}
                              {/* {allMeetings && allMeetings?.length < 2 ? null : ( */}
                              {allMeetings?.length > 1 ? (
                                <span>
                                  <MdKeyboardArrowDown
                                    size={30}
                                    onClick={toggleDropdown}
                                    style={{
                                      cursor: "pointer",
                                      // marginLeft: ".6rem",
                                      marginBottom: "6px",
                                    }}
                                  />
                                </span>
                              ) : null}
                            </h5>
                            {dropdownVisible && (
                              <div className="dropdown-content-filter">
                                <div className="dropdown-section">
                                  {allMeetings
                                    // ?.filter((item) => item.id !== meetId)
                                    ?.filter(
                                      (item) =>
                                        item.id.toString() !== meetId.toString()
                                    )
                                    ?.map((item, index) => {
                                      console.log("item", item);
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            onClick={() => applyFilter(item)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <b>{item.title_order}.</b>&nbsp;
                                            {item.title}
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-2 content-body">
                            <div className="d-flex align-items-center gap-2">
                              {meetingData?.type === "Action" ||
                              meetingData?.type === "Newsletter" ? (
                                <>
                                  <img src="/Assets/invite-date.svg" />
                                  <span className="fw-bold formate-date">
                                    {formattedDate}
                                    &nbsp; -
                                  </span>
                                  <span className="fw-bold formate-date">
                                    {/* {formattedDateActive} */}
                                    {formattedEndDate}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img src="/Assets/invite-date.svg" />

                                  <span className="fw-bold formate-date">
                                    {formattedDate}
                                    &nbsp; {t("at")}
                                  </span>
                                  <span className="fw-bold formate-date">
                                    {/* {formattedTime} */}
                                    {/* {convertTo12HourFormat(
                                  meetingData?.start_time,
                                  meetingData?.steps
                                )}{" "} */}
                                    {meetingData?.status === "in_progress"
                                      ? convertTo12HourFormat(
                                          meetingData?.starts_at,
                                          meetingData?.steps
                                        )
                                      : convertTo12HourFormat(
                                          meetingData?.start_time,
                                          meetingData?.steps
                                        )}{" "}
                                    -{" "}
                                  </span>

                                  <span className="fw-bold formate-date">
                                    {/* {formattedEndDateInHoursForEmailInvite} */}
                                    {estimateDate}
                                    &nbsp; {t("at")}
                                  </span>
                                  <span className="fw-bold formate-date">
                                    {/* {lastStepEndTime} */}
                                    {estimateTime}
                                  </span>
                                  {/* <span className="fw-bold formate-date">
                                {convertTo12HourFormat1(
                                  meetingData?.start_time,
                                  meetingData?.steps
                                )}{" "}
                                -{" "}
                              </span>
                              <span className="fw-bold formate-date">
                                {calculateEndTime(
                                  meetingData?.start_time?.slice(0, 5),
                                  meetingData?.steps
                                )}
                              </span> */}
                                </>
                              )}
                            </div>

                            <div className="d-flex align-items-center gap-2 content-meet-section">
                              {/* <img src="/Assets/invite-link.svg" alt="" />
                          <span className="fw-bold formate-date">
                            Google Meet
                          </span>
                          <Link
                            to={meetingData?.meet_link}
                            style={{ color: "#3873CC" }}
                            className="formate-link"
                          >
                            {meetingData?.meet_link}
                          </Link> */}
                            </div>
                          </div>
                          <div className="paragraph-parent">
                            <span className="paragraph">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: meetingData?.description || "",
                                }}
                              />
                            </span>
                          </div>
                        </div>

                        {meetingData?.type === "Newsletter" && (
                          <>
                            <div className="col-md-4">
                              <Card>
                                <Card.Body className="d-flex flex-column">
                                  <h4
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: "20px",
                                      fontWeight: 600,
                                      lineHeight: "20px",
                                      textAlign: "left",
                                      color: "#344054",
                                    }}
                                  >
                                    {t("Join Now")}
                                  </h4>
                                  <h6
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      lineHeight: "16.41px",
                                      textAlign: "left",
                                      color: "#92929D",
                                    }}
                                  >
                                    {t("Sign up to be a part of this moment.")}
                                  </h6>
                                  <div className="subscribe-button mt-2">
                                    <button
                                      className="btn moment-btn w-100"
                                      style={{
                                        padding: "12px 20px 12px 20px",
                                        gap: "8px",
                                        borderRadius: "8px",
                                        background: "#2C48AE",
                                        border: "1px solid #E2E2E2",
                                        color: "white",
                                      }}
                                      onClick={() => handleShowSignUp()}
                                    >
                                      {t("Subscribe")}{" "}
                                    </button>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="cards-section">
                        <div style={{ marginTop: "5rem" }}>
                          <span className="participant-heading">
                            {showHostProfile && (
                              <IoArrowBackSharp
                                onClick={hideHostShow}
                                size={25}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "1rem",
                                }}
                              />
                            )}
                            Guide
                          </span>
                          <div
                            className="host"
                            style={{ background: showHostProfile && "white" }}
                          >
                            <HostCard
                              data={meetingData?.user}
                              guides={meetingData?.guides}
                              handleShow={handleHostShow}
                              handleHide={hideHostShow}
                              showProfile={showHostProfile}
                              meeting={meetingData}
                            />
                          </div>
                        </div>
                        {/* ------------------------------------------------ Participants */}
                        {meetingData?.type !== "Newsletter" && (
                          <>
                            {meetingData?.participants?.filter(
                              (item) =>
                                // item.isCreator === 0 &&
                                !guideEmails.has(item.email)
                            )?.length > 0 && (
                              <div style={{ marginTop: "5rem" }}>
                                <span className="participant-heading">
                                  {showProfile && (
                                    <IoArrowBackSharp
                                      onClick={hideShow}
                                      size={25}
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "1rem",
                                      }}
                                    />
                                  )}
                                  {t("invite")}
                                </span>
                                <div className="row">
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                  <div className="col-md-3">
                                    <ParticipantCard />
                                  </div>
                                </div>

                                <div
                                  style={{ background: showProfile && "white" }}
                                  className="participant"
                                >
                                  <ParticipantCard
                                    data={meetingData?.participants}
                                    guides={meetingData?.guides}
                                    handleShow={handleShow}
                                    handleHide={hideShow}
                                    showProfile={showProfile}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {/* ------------------------------------------------ Steps */}

                        {
                          <div style={{ marginTop: "5rem" }}>
                            <span className="participant-heading">
                              {`${t("meeting.newMeeting.labels.Program")} `}
                            </span>
                            {/* <StepCard data={meeting?.steps} startTime={formattedTime} users={{ ...meeting?.user, image: meeting?.user?.image || '/Assets/avatar.jpeg' }}  /> */}
                            <StepCard
                              data={meetingData?.steps}
                              startTime={formattedTime}
                              users={{
                                ...meetingData?.user,
                                firstName: meetingData?.user?.name,
                                lastName: meetingData?.user?.last_name,
                                image:
                                  meetingData?.user?.assigned_to_image ||
                                  meetingData?.user?.image ||
                                  "/Assets/avatar.jpeg",
                              }}
                              meeting1={meetingData?.id}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* SignIn Modal */}
                  {/* <SignIn
                show={showSignIn}
                handleClose={handleCloseSignIn}
                handleShowSignUp={handleShowSignUp}
                handleShowForgot={handleShowForgot}
              /> */}

                  {/* SignUp Modal */}
                  <SignUp
                    show={showSignUp}
                    handleClose={handleCloseSignUp}
                    handleShowSignIn={handleShowSignIn}
                    meetingId={meetId}
                  />
                  {/* Forgot Password Modal */}
                  <ForgotPassword
                    show={showForgot}
                    handleClose={handleCloseForgot}
                    handleShowForgot={handleShowForgot}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Report;
