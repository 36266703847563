import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../Components/Apicongfig";
import { askPermission } from "../Components/Utils/askPermission";

const MeetingsContext = createContext();

export const useMeetings = () => useContext(MeetingsContext);

export const MeetingsProvider = ({ children }) => {
  const [allMeetings, setAllMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allClosedMeetings, setAllClosedMeetings] = useState([]);
  const [status,setStatus] = useState(null);

  const getMeetings = async () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
    // Format date
    const year = currentTime.getFullYear();
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const day = currentTime.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/meetings?current_time=${formattedTime}&current_date=${formattedDate}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setAllMeetings(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getClosedMeetings = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/closed/meetings`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.status) {
        setAllClosedMeetings(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleDelete = async (id) => {
    const permissionGranted = askPermission(
      "Êtes-vous sûr de vouloir supprimer cette réunion ?" ||
        "Are you sure you want to delete this meeting?"
    );

    if (!permissionGranted) return;

    try {
      const response = await axios.delete(`${API_BASE_URL}/meetings/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });

      if (response.status === 200) {
        toast.success("Réunion supprimée avec succès");
        getMeetings();
      } else {
        throw new Error("Échec de la suppression de la réunion");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <MeetingsContext.Provider
      value={{
        allMeetings,
        isLoading,
        getMeetings,
        allClosedMeetings,
        getClosedMeetings,
        handleDelete,
        status,
        setStatus, 
      }}
    >
      {children}
    </MeetingsContext.Provider>
  );
};
