import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin, FaSquareFacebook } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import CalendlyLink from "../Elements/landingPages/CalendlyLink";

function Footer() {
  const { t } = useTranslation("global");

  const openCalendlyLink = CalendlyLink(
    "https://www.instagram.com/tektime.io/"
  );
  const openCalendlyLink1 = CalendlyLink(
    "https://www.linkedin.com/company/tektimesolutions"
  );

  return (
    <div id="footer" className="footer">
      <div className="footer-top py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center text-md-start mt-4">
              <img
                src="Assets/landing/White-logo 1.png"
                alt="Logo"
                className="img-fluid"
              />
              <div className="d-flex gap-4 flex-wrap mt-4 social-icons">
                <FaSquareFacebook
                  onClick={() => window.open("https://www.facebook.com/tektime.io", "_blank")}
                  className="icons"
                  size={20}
                />
                <FaInstagram
                  onClick={openCalendlyLink}
                  className="icons"
                  size={20}
                />
                <FaLinkedin
                  onClick={openCalendlyLink1}
                  className="icons"
                  size={20}
                />
              </div>
            </div>
            <div className="col-md-3 col-6 mt-4">
              <div>
                <h4 className="text-secondary mb-3 fs-5">{t("footer.company")}</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to="/about"
                      className="text-decoration-none footer-li-color"
                    >
                      {t("footer.about_us")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className="text-decoration-none footer-li-color"
                    >
                      {t("footer.contact_us")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-4">
              <div>
                <h4 className="text-secondary mb-3 fs-5">{t("footer.product")}</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/" className="text-white text-decoration-none">
                      {t("footer.features")}
                    </Link>
                  </li>
                  <li className="mt-2">
                    <Link
                      to="/pricing"
                      className="text-white text-decoration-none"
                    >
                      {t("footer.pricing")}
                    </Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/" className="text-white text-decoration-none">
                      {t("footer.news")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-4">
              <div>
                <h4 className="text-secondary mb-3 fs-5">{t("footer.legal")}</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to="/privacy-policy"
                      className="text-white text-decoration-none"
                    >
                      {t("footer.privacy_policy")}
                    </Link>
                  </li>
                  <li className="mt-2">
                    <Link
                      to="/terms&conditions"
                      className="text-white text-decoration-none"
                    >
                      {t("footer.terms_conditions")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;














