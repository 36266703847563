import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../Apicongfig";
import axios from "axios";
import ErrorBoundary from "../../../Utils/ErrorBoundary";

const CounterContext = createContext();

export const useCounterContext = () => {
  const context = useContext(CounterContext);
  if (!context) {
    throw new Error(
      "useCounterContext must be used within a HeaderTitleProvider"
    );
  }
  return context;
};

export const CounterContextProvider = ({ children }) => {
  //centralized states for count down timer.
  const { id } = useParams();
  const [meetingData, setMeetingData] = useState("");
  console.log("meetingData->counter context", meetingData);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  console.log("activeStepIndex on CounterContext", activeStepIndex);
  const [savedTime, setSavedTime] = useState(0);
  console.log("saved time on CounterContext", savedTime);
  const [negativeTimes, setNegativeTimes] = useState(Array().fill(0));
  console.log("negativeTimes array on CounterContext", negativeTimes);

  const [stepDelay, setStepDelay] = useState(null); // New state for step delay
  console.log("stepDelay", stepDelay);
  const [nextStepTrigger, setNextStepTrigger] = useState(0);
  const [previousStepTrigger, setPreviousStepTrigger] = useState(0);
  /**------------------------------------------------------------------------------------------------------------------------------------------------ */
  // const getMeetingByID = async (meetingID) => {
  //   try {
  //     const response = await axios.get(
  //       `${API_BASE_URL}/meetings/${meetingID}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       // console.log("response from getMeeting by ID ->", response?.data?.data);
  //       setMeetingData(response?.data?.data);
  //       // setActiveStepIndex(0);
  //       const inProgressIndex = response.data?.data?.steps.findIndex(step => step.status === 'in_progress');
  //       if (inProgressIndex !== -1) {
  //         setActiveStepIndex(inProgressIndex);
  //       }
  //       const negativeTimes = response?.data?.data?.steps?.map((step) => {
  //         if (step.negative_time) return parseInt(step.negative_time);
  //       });
  //       // console.log("negativeTimes", negativeTimes);
  //       setNegativeTimes(negativeTimes);
  //       const inProgressStep = response?.data?.data?.steps[activeStepIndex];
  //       console.log('inProgressStep', inProgressStep)
  //       setStepDelay(inProgressStep?.delay || null);
  //       return response;
  //     }
  //   } catch (error) {
  //     // console.log("error", error);
  //     return error.response;
  //   }
  // };
  const intervalRef = useRef(null);

  // useEffect(() => {
  //   if (meetingData) {
  //     const sendCurrentTime = async () => {
  //       const inProgressStep = meetingData?.steps[activeStepIndex];
  //       const inProgressStepId = inProgressStep?.id;

  //       // Exit if there's no in-progress step
  //       if (!inProgressStepId) return;

  //       const currentTime = new Date();
  //       const hours = currentTime.getHours();
  //       const minutes = currentTime.getMinutes();
  //       const seconds = currentTime.getSeconds();
  //       const ampm = hours >= 12 ? "PM" : "AM";
  //       const formattedHours = hours % 12 || 12;
  //       const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  //       const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
  //       const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

  //       const year = currentTime.getFullYear();
  //       const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
  //       const day = currentTime.getDate().toString().padStart(2, "0");
  //       const formattedDate = `${year}-${month}-${day}`;

  //       const payload = {
  //         delay_seconds: 20,
  //         step_id: inProgressStepId,
  //         current_time: formattedTime,
  //         current_date: formattedDate,
  //       };

  //       try {
  //         const response = await axios.post(
  //           `${API_BASE_URL}/update-delay`,
  //           payload,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //             },
  //           }
  //         );

  //         if (response?.status) {
  //           console.log("Time sent successfully", response.data);
  //           const delay = response?.data?.data;
  //           setStepDelay(delay);
  //         }
  //       } catch (error) {
  //         console.log("error while sending current time", error);
  //       }
  //     };

  //     // Initial condition check and sendCurrentTime call
  //     if (meetingData?.steps[activeStepIndex]?.delay !== null  ||negativeTimes[activeStepIndex] > 0) {
  //       sendCurrentTime();
  //     }

  //     // Set up interval to check condition every 20 seconds
  //     if (!intervalRef.current) {
  //       intervalRef.current = setInterval(() => {
  //         if (meetingData?.steps[activeStepIndex]?.delay !== null  || negativeTimes[activeStepIndex] > 0) {
  //           console.log("Negative time, sending current time");
  //           sendCurrentTime();
  //         }
  //       }, 20000); // 20 seconds
  //     }

  //     // Cleanup interval on unmount
  //     return () => {
  //       if (intervalRef.current) {
  //         clearInterval(intervalRef.current);
  //         intervalRef.current = null;
  //       }
  //     };
  //   }
  // }, [meetingData, activeStepIndex, negativeTimes]);
  useEffect(() => {
    if (meetingData) {
      const sendCurrentTime = async () => {
        const inProgressStep = meetingData?.steps[activeStepIndex];
        const inProgressStepId = inProgressStep?.id;

        // Exit if there's no in-progress step
        if (!inProgressStepId) return;

        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        const seconds = currentTime.getSeconds();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

        const year = currentTime.getFullYear();
        const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
        const day = currentTime.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        const payload = {
          delay_seconds: 1,
          step_id: inProgressStepId,
          current_time: formattedTime,
          current_date: formattedDate,
        };

        try {
          const response = await axios.post(
            `${API_BASE_URL}/update-delay`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          );

          if (response?.status) {
            console.log("Time sent successfully", response.data);
            const delay = response?.data?.data;
            setStepDelay(delay);
          }
        } catch (error) {
          console.log("error while sending current time", error);
        }
      };
      if (meetingData?.steps[activeStepIndex]?.delay !== null  || negativeTimes[activeStepIndex] > 0) {
        sendCurrentTime();
      }

      const intervalId = setInterval(() => {
        // if (meetingData?.steps[activeStepIndex]?.delay !== null) {
      if (meetingData?.steps[activeStepIndex]?.delay !== null || negativeTimes[activeStepIndex] > 0) {
          sendCurrentTime();
        }
      }, 1000); //1 second

      return () => clearInterval(intervalId);
    }
  }, [meetingData, activeStepIndex,negativeTimes]);

  useEffect(() => {
    const getMeetingByID = async (meetingID) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/meetings/${meetingID}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status) {
          // console.log("response from getMeeting by ID ->", response?.data?.data);
          setMeetingData(response?.data?.data);
          // setActiveStepIndex(0);
          const inProgressIndex = response.data?.data?.steps.findIndex(
            (step) => step.status === "in_progress"
          );
          if (inProgressIndex !== -1) {
            setActiveStepIndex(inProgressIndex);
          }
          const negativeTimes = response?.data?.data?.steps?.map((step) => {
            if (step.negative_time) return parseInt(step.negative_time);
          });
          // console.log("negativeTimes", negativeTimes);
          setNegativeTimes(negativeTimes);
          return response;
        }
      } catch (error) {
        // console.log("error", error);
        return error.response;
      }
    };
    getMeetingByID(id);
  }, [id]);

  /**------------------------------------------------------------------------------------------------------------------------------------------------ */
  // HANDLER FUNCTIONS:
  const handleSetSavedTime = useCallback((time) => {
    setSavedTime(time);
  }, []);

  const setNextActiveStep = useCallback(() => {
    if (!meetingData) return;
    const stepsArray = meetingData?.steps;
    if (activeStepIndex !== stepsArray?.length - 1) {
      let stepsArray = meetingData?.steps;
      const activeStep = stepsArray[activeStepIndex];
      activeStep.savedTime = parseInt(savedTime);
      stepsArray[activeStepIndex] = activeStep;
      setMeetingData((prevState) => {
        return {
          ...prevState,
          steps: stepsArray,
        };
      });

      // STEP 2: SET ACTIVE STEP:
      const nextIndex = activeStepIndex + 1;
      setActiveStepIndex(nextIndex);
      setNextStepTrigger(nextStepTrigger + 1);
    } else {
      return;
    }
  }, [meetingData, activeStepIndex, savedTime]);

  const setPreviousActiveStep = useCallback(() => {
    if (!meetingData) return;
    // console.log("Saved Time", savedTime);
    const stepsArray = meetingData?.steps;
    if (activeStepIndex !== 0) {
      let stepsArray = meetingData?.steps;
      const activeStep = stepsArray[activeStepIndex];
      activeStep.savedTime = parseInt(savedTime);
      stepsArray[activeStepIndex] = activeStep;
      setMeetingData((prevState) => {
        return {
          ...prevState,
          steps: stepsArray,
        };
      });
      //STEP@: SET ACTIVE STEP:
      const previousIndex = activeStepIndex - 1;
      setActiveStepIndex(previousIndex);
      setPreviousStepTrigger(previousStepTrigger + 1);
    }
  }, [meetingData, activeStepIndex, savedTime]);

  // // const setNextActiveStep = useCallback(, [meetingData]);
  // const setNextActiveStep = () => {
  //     if (!meetingData) return;
  //     const stepsArray = meetingData?.steps;
  //     if (activeStepIndex !== stepsArray?.length - 1) {
  //         let stepsArray = meetingData?.steps;
  //         const activeStep = stepsArray[activeStepIndex];
  //         activeStep.savedTime = parseInt(savedTime);
  //         stepsArray[activeStepIndex] = activeStep;
  //         setMeetingData((prevState) => {
  //             return {
  //                 ...prevState,
  //                 steps: stepsArray,
  //             };
  //         });

  //         // STEP 2: SET ACTIVE STEP:
  //         const nextIndex = activeStepIndex + 1;
  //         setActiveStepIndex(nextIndex);
  //     } else {
  //         return;
  //     }
  // };//
  // // const setPreviousActiveStep = useCallback(, [meetingData]);
  // const setPreviousActiveStep = () => {
  //     if (!meetingData) return;
  //     // console.log("Saved Time", savedTime);
  //     const stepsArray = meetingData?.steps;
  //     if (activeStepIndex !== 0) {
  //         let stepsArray = meetingData?.steps;
  //         const activeStep = stepsArray[activeStepIndex];
  //         activeStep.savedTime = parseInt(savedTime);
  //         stepsArray[activeStepIndex] = activeStep;
  //         setMeetingData((prevState) => {
  //             return {
  //                 ...prevState,
  //                 steps: stepsArray,
  //             };
  //         });
  //         //STEP@: SET ACTIVE STEP:
  //         const previousIndex = activeStepIndex - 1;
  //         setActiveStepIndex(previousIndex);
  //     }
  // };

  return (
    <ErrorBoundary>
      <CounterContext.Provider
        value={{
          // COUNT DOWN TIMER STATES:
          meetingData,
          activeStepIndex,
          savedTime,
          negativeTimes,
          nextStepTrigger,
          previousStepTrigger,
          stepDelay,

          setNextStepTrigger,
          setPreviousStepTrigger,
          setNegativeTimes,
          setActiveStepIndex,
          setMeetingData,
          setSavedTime,
          handleSetSavedTime,
          setNextActiveStep,
          setPreviousActiveStep,
          setStepDelay,
        }}
      >
        {children}
      </CounterContext.Provider>
    </ErrorBoundary>
  );
};

//const updateStepTime = async () => {
//     try {
//         console.log("API CALL-->");
//         const response = await axios.put(
//             `${API_BASE_URL}/meetings/${id}`,
//             meetingData,
//             {
//                 headers: {
//                     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//                 },
//             }
//         );
//         if (response.status) {
//             console.log("response from updateMeeting ->", response.data.data);
//             return response;
//         }

//     } catch (error) {
//         console.log("error", error);
//         return error.response;
//     }
// }
