import React from "react";
import UserTabs from "./UserTabs";
const Users = () => {
  return (
    <>
      <UserTabs />
    </>
  );
};

export default Users;
