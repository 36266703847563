import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Assets_URL } from "../../Apicongfig";
import ParticipantCardProfile from "../Profile/ParticipantCardProfile";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const HostCard = ({
  data,
  guides,
  fromMeeting,
  participants,
  handleShow,
  handleHide,
  showProfile,
  meeting
}) => {
  const [id, setId] = useState(null);
  const [isUser, setIsUser] = useState(false);
  const [t] = useTranslation("global");

  console.log('meeting',meeting)
  const handleShowProfile = (userId) => {
    setId(userId);
    handleShow();
    setIsUser(true);
  };
  const handleShowGuideProfile = (userId) => {
    setId(userId);
    handleShow();
    setIsUser(false);
  };
  return (
    <>
      {showProfile ? (
        <div>
          <ParticipantCardProfile
            userId={id}
            handleHide={handleHide}
            isUser={isUser}
          />
        </div>
      ) : (
        <div className="row">
          {(meeting && meeting?.type === "Newsletter") && (
            <div className="col-md-3">
              <Card
                className={`participant-card ${
                  fromMeeting ? "participant-card-meeting" : ""
                } position-relative`}
                style={{
                  marginTop: "4rem",
                }}
              >
                <Card.Body
                  style={{
                    padding: "20px 0px 20px 0",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <div className="participant-card-position">
                      <div className="profile-logo">
                        <Card.Img
                          className="user-img"
                          src={
                            data?.image?.startsWith("users/")
                              ? `${Assets_URL}/${data?.image}`
                              : data?.image
                          }
                          alt="User Avatar"
                        />
                        <Card.Img
                          className="logout-icon"
                          src="/Assets/Avatar_company.svg"
                          height="20px"
                          width="20px"
                          alt="Company Logo"
                        />
                      </div>
                    </div>
                  </div>

                  <Card.Title className="text-center mt-4 card-heading">
                    {data?.name} {data?.last_name}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 card-subtext">
                    {data?.post} -{" "}
                    {data?.teams?.map((item) => item.name).join(", ")}
                  </Card.Subtitle>

                  {data?.contribution && (
                              <>
                                <Card.Subtitle className="mb-2 mt-3 card-subtext">
                                  {data.contribution}
                                </Card.Subtitle>
                              </>
                            )}
                  <div
                    className="visiting-card-link"
                    onClick={() => {
                      handleShowProfile(data?.nick_name);
                    }}
                  >
                    {t("viewVisitingCard")} &nbsp; <FaArrowRight />
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}

          {/* Guides Cards */}
          {guides?.length > 0 && meeting?.type !== "Newsletter" &&
            guides
              // .filter((guide) => guide.isCreator !== 1)
              .map((guide, index) => (
                <div key={index} className="col-md-3">
                  <Card
                    className={`participant-card ${
                      fromMeeting ? "participant-card-meeting" : ""
                    } position-relative`}
                    style={{
                      marginTop: "4rem",
                    }}
                  >
                    <Card.Body
                      style={{
                        padding: "20px 0px 20px 0",
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <div className="participant-card-position">
                          <div className="profile-logo">
                            <Card.Img
                              className="user-img"
                              src={
                                guide.participant_image.startsWith("users/")
                                  ? Assets_URL + "/" + guide.participant_image
                                  : guide.participant_image
                              }
                              alt="Guide Avatar"
                            />
                            <Card.Img
                              className="logout-icon"
                              src="/Assets/Avatar_company.svg"
                              height="20px"
                              width="20px"
                              alt="tektime"
                            />

                            <Card.Img
                              className="logout-icon"
                              src="/Assets/Avatar_company.svg"
                              height="20px"
                              width="20px"
                              alt="Company Logo"
                            />
                          </div>
                        </div>
                      </div>

                      <Card.Title className="text-center mt-4 card-heading">
                        {guide?.first_name} {guide?.last_name}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 card-subtext">
                        {guide?.post} {guide?.team_names?.length > 0 && " - "}{" "}
                        {guide?.team_names?.map((item) => item).join(", ")}
                      </Card.Subtitle>
                      {guide?.contribution && (
                        <>
                          <Card.Subtitle className="mb-3 mt-3 card-subtext">
                            {guide.contribution}
                          </Card.Subtitle>
                        </>
                      )}
                      {guide?.user_id && (
                        <div
                          className="visiting-card-link"
                          onClick={() => {
                            handleShowGuideProfile(guide?.id);
                          }}
                        >
                          {t("viewVisitingCard")} &nbsp; <FaArrowRight />
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default HostCard;
