import { RxCross2 } from "react-icons/rx";
import MomentDetail from "./components/MomentDetails";
import { useTranslation } from "react-i18next";
import Solution from "./components/Template";
import DateandTime from "./components/DateandTime";
import Location from "./components/Location";
import AddGuests from "./components/AddGuests";
import AddSteps from "./components/AddSteps";
import Options from "./components/Options";
import Privacy from "./components/Privacy";
import { useEffect, useState } from "react"; // Use local state
import Share from "./components/Share";
import { Modal, Button } from "react-bootstrap";
import { useFormContext } from "../../../../context/CreateMeetingContext";

const NewMeetingModal = ({ meeting }) => {
  const [t] = useTranslation("global");
  const [activeTab, setActiveTab] = useState("tab1"); // Local state for tabs
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation modal
  const {
    checkId,
    deleteMeeting,
    handleInputBlur,
    saveDraft,
    open,
    handleCloseModal,
    isUpdated,
    isDuplicate,
    validateAndUpdate,
    addParticipant,
    setAddParticipant,
    setCheckId,
    setMeeting,
    setIsUpdated,
  } = useFormContext();

  const handleCancel = () => {
    setShowConfirmation(true); // Show confirmation modal
    // setAddParticipant(false);
    // setIsUpdated(false)
    // setCheckId(null);
    // setMeeting(null);
  };

  const handleClose = () => {
    // setAddParticipant(false);
    // setCheckId(null);
    // setIsUpdated(false)
    // setMeeting(null);
    setShowConfirmation(false);
  };

  const handleDelete = () => {
    deleteMeeting(checkId);
  };

  useEffect(() => {
    if (addParticipant) {
      setActiveTab("tab5");
    }
  }, [addParticipant]);

  // const isTabDisabled = (tabName) => {
  //   const tabOrder = [
  //     "tab1",
  //     "tab2",
  //     "tab3",
  //     "tab4",
  //     "tab5",
  //     "tab6",
  //     "tab7",
  //     "tab8",
  //     "tab9",
  //   ];
  //   const currentIndex = tabOrder.indexOf(activeTab);
  //   const tabIndex = tabOrder.indexOf(tabName);
  //   return tabIndex > currentIndex;
  // };
  const isTabDisabled = (tabName) => {
    // Disable all tabs except tab5 if addParticipant is true
    if (addParticipant) {
      return tabName !== "tab5";
    }

    const tabOrder = [
      "tab1",
      "tab2",
      "tab3",
      "tab4",
      "tab5",
      "tab6",
      "tab7",
      "tab8",
      "tab9",
    ];
    const currentIndex = tabOrder.indexOf(activeTab);
    const tabIndex = tabOrder.indexOf(tabName);
    if (isUpdated || isDuplicate) {
      return false;
    }

    return tabIndex > currentIndex;
  };

  return (
    <>
      {open && (
        <div id="chart-container" className="chart-content">
          <div className="modal-overlay">
            <div className="new-meeting-modal">
              <div className="modal-nav">
                <div>
                  {
                    <h4>
                      {isUpdated
                        ? t("meeting.newMeeting.UpdateMoment")
                        : isDuplicate
                        ? t("meeting.newMeeting.DuplicateMoment")
                        : addParticipant
                        ? t("meeting.newMeeting.Add new invite")
                        : t("meeting.newMeeting.CreateMoment")}
                    </h4>
                  }
                </div>
                <div className="d-flex justify-content-end">
                  <button className="cross-btn" onClick={handleCancel}>
                    <RxCross2 size={18} />
                  </button>
                </div>
              </div>
              <div className="mt-3 modal-body">
                <div
                  className="col-md-11"
                  style={{ borderBottom: "2px solid #F2F2F2" }}
                >
                  <div className="tabs" style={{ padding: "0 33px" }}>
                    <div>
                      <button
                        className={`tab ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab1")}
                        disabled={isTabDisabled("tab1")}
                      >
                        {t("meeting.NewMeetingTabs.Mission")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab2")}
                        disabled={isTabDisabled("tab2")}
                      >
                        {t("meeting.NewMeetingTabs.Solution")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab3")}
                        disabled={isTabDisabled("tab3")}
                      >
                        {t("meeting.NewMeetingTabs.Date and Time")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab4" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab4")}
                        disabled={isTabDisabled("tab4")}
                      >
                        {t("meeting.NewMeetingTabs.Location")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab5" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab5")}
                        disabled={isTabDisabled("tab5")}
                      >
                        {t("meeting.NewMeetingTabs.Add Guest")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab6" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab6")}
                        disabled={isTabDisabled("tab6")}
                      >
                        {t("meeting.NewMeetingTabs.Add Step")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab7" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab7")}
                        disabled={isTabDisabled("tab7")}
                      >
                        {t("meeting.NewMeetingTabs.Options")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab8" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab8")}
                        disabled={isTabDisabled("tab8")}
                      >
                        {t("meeting.NewMeetingTabs.Share")}
                      </button>
                      <button
                        className={`tab ${
                          activeTab === "tab9" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab9")}
                        disabled={isTabDisabled("tab9")}
                      >
                        {t("meeting.NewMeetingTabs.Privacy")}
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {activeTab === "tab1" && (
                    <MomentDetail
                      setActiveTab={setActiveTab}
                      meeting={meeting}
                    />
                  )}
                  {activeTab === "tab2" && (
                    <Solution setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab3" && (
                    <DateandTime
                      setActiveTab={setActiveTab}
                      meeting={meeting}
                    />
                  )}
                  {activeTab === "tab4" && (
                    <Location setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab5" && (
                    <AddGuests setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab6" && (
                    <AddSteps
                      setActiveTab={setActiveTab}
                      meeting={meeting}
                      // setMeeting={setMeeting}
                    />
                  )}
                  {activeTab === "tab7" && (
                    <Options setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab8" && (
                    <Share setActiveTab={setActiveTab} meeting={meeting} />
                  )}
                  {activeTab === "tab9" && (
                    <Privacy
                      setActiveTab={setActiveTab}
                      closeModal={handleCloseModal}
                      meeting={meeting}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={showConfirmation}
            onHide={handleClose}
            dialogClassName="custom-modal-size custom-modal-border modal-dialog-centered"
          >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body className="text-center p-4">
              <h2 className="w-100 text-center fs-5">{t("Are you sure")}</h2>
              <p className="mb-4" style={{ color: "#92929D" }}>
                {isUpdated || isDuplicate ? "" : addParticipant ? "" : t("saveAndDraftText")}
              </p>
              <div className="d-flex justify-content-center gap-3 mb-3">
                {isUpdated || addParticipant ? (
                  <Button
                    variant="outline-danger"
                    className="px-4 py-2 confirmation-delete"
                    onClick={handleCloseModal}
                  >
                    {t("Cancel")}
                  </Button>
                ) : (
                  <Button
                    variant="outline-danger"
                    className="px-4 py-2 confirmation-delete"
                    onClick={handleDelete}
                  >
                    {t("Delete")}
                  </Button>
                )}
                <Button
                  variant="primary"
                  className="px-4 py-2 confirmation-save"
                  onClick={
                    isDuplicate || isUpdated ? validateAndUpdate : addParticipant ? validateAndUpdate : saveDraft
                  }
                >
                  {isUpdated || isDuplicate
                    ? t("Save Meeting")
                    : addParticipant ? t("Save Meeting")
                    : t("Save Draft")}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default NewMeetingModal;
