import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScheduledMeeting from "./ScheduledMeeting";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
import DraftMeetings from "./DraftMeetings";
import CompletedMeetings from "./CompletedMeetings";
import { useMeetings } from "../../../context/MeetingsContext";
import moment from "moment";
import { useTabs } from "../../../context/TabContext";
import SignIn from "../AuthModal/SignIn";
import SignUp from "../AuthModal/SignUp";
import ForgotPassword from "../AuthModal/ForgotPassword";
import NewMeetingModal from "./CreateNewMeeting/NewMeetingModal";
import { useFormContext } from "../../../context/CreateMeetingContext";

function MeetingTabs() {
  const navigate = useNavigate();
  const { searchTerm } = useOutletContext();

  const { resetHeaderTitle } = useHeaderTitle();

  React.useEffect(() => {
    resetHeaderTitle();
  }, []);
  const { open, handleShow, handleCloseModal } = useFormContext();
  const location = useLocation();
  const [t] = useTranslation("global");

  const queryParams = new URLSearchParams(location.search);
  const fromPresentation2 = queryParams.get("from") === "presentation";
  const fromPresentation3 = queryParams.get("from") === "completeedit";

  const lastUrl = localStorage.getItem("lastURL");
  const { allMeetings, getMeetings, getClosedMeetings, allClosedMeetings } =
    useMeetings();

  useEffect(() => {
    setActiveTab("tab2");
    if (fromPresentation2) {
      setActiveTab("tab3");
    }
    if (fromPresentation3) {
      setActiveTab("Evènements terminés");
    }
    if (lastUrl === "/play") {
      setActiveTab("tab3");
      localStorage.removeItem("lastURL");
    }
    getMeetings();
    getClosedMeetings();
  }, []);

  // Filter meetings to exclude drafts
  const activeMeetingsCount = allMeetings?.filter(
    (meeting) => meeting.status !== "draft"
  ).length;
  // Filter meetings to exclude active,inprogress and complete
  const draftMeetingsCount = allMeetings?.filter(
    (meeting) => meeting.status === "draft"
  ).length;

  const completedMeetingsCount = allClosedMeetings?.filter(
    (meeting) => meeting.status === "closed" || meeting.status === "abort"
    // meeting.status === "in_progress"
  ).length;

  const tabsRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  // const [activeTab, setActiveTab] = useState("tab2");
  const { activeTab, setActiveTab } = useTabs();

  const handleScroll = () => {
    if (tabsRef.current) {
      setIsSticky(window.scrollY > 100);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filteredMeetings, setFilteredMeetings] = useState(allMeetings);
  const [draftMeetings, setDraftMeetings] = useState(allMeetings);
  console.log("filteredMeetings-> search", filteredMeetings);
  const [closedFilteredMeetings, setClosedFilteredMeetings] =
    useState(allClosedMeetings);

  const [isInviteSelected, setIsInviteSelected] = useState(false);
  const [isMyMeetingsSelected, setIsMyMeetingsSelected] = useState(false);
  const [isAllMeetingsSelected, setIsAllMeetingsSelected] = useState(false);
  const [isClosedInviteSelected, setIsClosedInviteSelected] = useState(false);
  const [isMyClosedMeetingsSelected, setIsClosedMyMeetingsSelected] =
    useState(false);

  const dropdownRef = useRef(null);
  const userId = parseInt(sessionStorage.getItem("user_id"));
  useEffect(() => {
    setIsMyMeetingsSelected(true);
    setIsClosedMyMeetingsSelected(true);
    const now = moment();
    let filtered = [...allMeetings];

    if (selectedFilter === "thisMonth") {
      filtered = filtered.filter((meeting) =>
        moment(meeting.date).isSame(now, "month")
      );
    } else if (selectedFilter === "previousMonth") {
      const previousMonth = moment().subtract(1, "month");
      filtered = filtered.filter((meeting) =>
        moment(meeting.date).isSame(previousMonth, "month")
      );
    } else if (selectedFilter === "previousYear") {
      const previousYear = moment().subtract(1, "year");
      filtered = filtered.filter((meeting) =>
        moment(meeting.date).isSame(previousYear, "year")
      );
    }

    // Further filter the meetings based on status and user_id
    filtered = filtered.filter(
      (meeting) =>
        meeting.status === "active" || meeting.status === "in_progress"
      //  &&  meeting.user_id === userId
    );
    // let myClosedMeetings = allClosedMeetings.filter(
    //   (meeting) => meeting.user_id === userId
    // );
    let myClosedMeetings = [...allClosedMeetings];

    let draftMeetings = allMeetings?.filter(
      (meeting) => meeting.status === "draft"
    );
    if (searchTerm) {
      filtered = filtered.filter((meeting) =>
        meeting.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      myClosedMeetings = myClosedMeetings.filter((meeting) =>
        meeting.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      draftMeetings = draftMeetings?.filter(
        (meeting) =>
          meeting?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          meeting?.objective?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setDraftMeetings(draftMeetings);
    setFilteredMeetings(filtered);
    setClosedFilteredMeetings(myClosedMeetings);
  }, [selectedFilter, allMeetings, userId, allClosedMeetings, searchTerm]);

  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgot, setShowForgot] = useState(false);

  const handleShowSignIn = () => {
    setShowSignUp(false);
    setShowSignIn(true);
  };

  const handleShowSignUp = () => {
    setShowSignIn(false);
    setShowSignUp(true);
  };

  const handleShowForgot = () => {
    setShowSignIn(false);
    setShowSignUp(false);
    setShowForgot(true);
  };

  const handleCloseSignIn = () => setShowSignIn(false);
  const handleCloseSignUp = () => setShowSignUp(false);
  const handleCloseForgot = () => setShowForgot(false);

  return (
    <>
      <div className="tabs-container container-fluid">
        <div
          ref={tabsRef}
          className={`tabs-header ${isSticky ? "sticky" : ""}`}
        >
          <h4 className="meeting-title">{t("meeting.title")}</h4>

          <div className={`container-fluid ${isSticky ? "" : "py-1 px-3"}`}>
            <div
              className="row align-items-center gutter-0"
              style={{ padding: "0 10px" }}
            >
              {/* Tabs Section */}
              <div
                className="col-md-11 col-12 border-bottom tabs-meeting"
                style={{ borderBottom: "2px solid #F2F2F2" }}
              >
                <div className="tabs">
                  <div className="d-flex">
                    <button
                      className={`tab ${activeTab === "tab1" ? "active" : ""}`}
                      onClick={() => setActiveTab("tab1")}
                    >
                      {t("meeting.draftTab")}
                      <span
                        className={activeTab === "tab1" ? "future" : "draft"}
                      >
                        {draftMeetingsCount}
                      </span>
                    </button>
                    <button
                      className={`tab ${activeTab === "tab2" ? "active" : ""}`}
                      onClick={() => setActiveTab("tab2")}
                    >
                      {t("meeting.activeMeetingsTab")}
                      <span
                        className={activeTab === "tab2" ? "future" : "draft"}
                      >
                        {activeMeetingsCount}
                      </span>
                    </button>
                    <button
                      className={`tab ${activeTab === "tab3" ? "active" : ""}`}
                      onClick={() => setActiveTab("tab3")}
                    >
                      {t("meeting.completedMeetingsTab")}
                      <span
                        className={activeTab === "tab3" ? "future" : "draft"}
                      >
                        {completedMeetingsCount}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Button Section */}
              <div
                className={`col-md-1 col-12 d-flex  ${
                  isSticky ? "sticky-button" : "justify-content-end p-0"
                }`}
              >
                <button
                  className={`btn moment-btn d-flex`}
                  onClick={handleShow}
                >
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 14.75V1.25M1.25 8H14.75"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  &nbsp;{t("meeting.buttons.create")}
                </button>
              </div>
              {/* <div
                className={`col-md-1 col-12 d-flex  ${
                  isSticky ? "sticky-button" : "justify-content-end p-0"
                }`}
              >
                <button
                  className={`btn moment-btn d-flex`}
                  onClick={() => navigate("/meeting/new")}
                >
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 14.75V1.25M1.25 8H14.75"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  &nbsp;{t("meeting.buttons.create")}
                </button>
              </div> */}
            </div>
          </div>
          {/* SignIn Modal */}
          <SignIn
            show={showSignIn}
            handleClose={handleCloseSignIn}
            handleShowSignUp={handleShowSignUp}
            handleShowForgot={handleShowForgot}
          />

          {/* SignUp Modal */}
          <SignUp
            show={showSignUp}
            handleClose={handleCloseSignUp}
            handleShowSignIn={handleShowSignIn}
          />
          {/* Forgot Password Modal */}
          <ForgotPassword
            show={showForgot}
            handleClose={handleCloseForgot}
            handleShowForgot={handleShowForgot}
          />
        </div>
        <div className="content">
          {activeTab === "tab1" && (
            <div>
              <DraftMeetings allMeetings={draftMeetings} />
            </div>
          )}
          {activeTab === "tab2" && (
            <div>
              <ScheduledMeeting allMeetings={filteredMeetings} />
            </div>
          )}
          {activeTab === "tab3" && (
            <div>
              <CompletedMeetings allClosedMeetings={closedFilteredMeetings} />
            </div>
          )}
        </div>
        {open && (
          <>
            <NewMeetingModal open={open} closeModal={handleCloseModal} />
          </>
        )}
      </div>
    </>
  );
}

export default MeetingTabs;
