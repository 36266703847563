import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header() {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("navbar");
      if (window.scrollY > 0) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    console.log("lang", lang);
    // updateLanguage(lang);
  };

  return (
    <div className="home-header">
      <nav
        id="navbar"
        className="container-fluid navbar fixed-top navbar-expand-lg py-3"
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="Assets/landing/logo.png" alt="" className="img-fluid" />
          </Link>
          <button
            className="navbar-toggler bg-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <div className="navbar-nav ms-auto d-flex right-dropdown-profile">
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li
                  className={`nav-item ${activeLink === "/" ? "active" : ""}`}
                >
                  <Link to="/" className="nav-link pb-1">
                    {t("navbar.home")}
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeLink === "/about" ? "active" : ""
                  }`}
                >
                  <Link to="/about" className="nav-link pb-1">
                    {t("navbar.about_us")}
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeLink === "/privacy-policy" ? "active" : ""
                  }`}
                >
                  <Link to="/privacy-policy" className="nav-link pb-1">
                  {t("footer.privacy_policy")}

                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeLink === "/terms&conditions" ? "active" : ""
                  }`}
                >
                  <Link to="/terms&conditions" className="nav-link pb-1">
                  {t("footer.terms_conditions")}
                  </Link>
                </li>
                {/* <li
                  className={`nav-item ${
                    activeLink === "/solutions" ? "active" : ""
                  }`}
                >
                  <Link className="nav-link pb-1">Solutions</Link>
                </li>
                <li
                  className={`nav-item ${
                    activeLink === "/product" ? "active" : ""
                  }`}
                >
                  <Link className="nav-link pb-1">Product</Link>
                </li> */}
                {/* <li
                  className={`nav-item ${
                    activeLink === "/contactus" ? "active" : ""
                  }`}
                >
                  <Link to="/contactus" className="nav-link pb-1">
                    Contact
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link to="/login" className="nav-link ps-0 pb-1 ps-md-3">
                    <div class="container-door">
                      {t("navbar.login")}

                      <div class="flipbox">
                        <div class="flipbox-active"></div>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <div className="mt-1 swtich">
                    <label
                      className="form-check-label mr-2"
                      htmlFor="languageSwitch"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "13px",
                        lineHeight: "15.73px",
                        textAlign: "left",
                        color: "#4C4C4C",
                        fontWeight: i18n.language === "fr" ? 200 : "bold",
                      }}
                    >
                      {/* {i18n.language === "en" && "En"} */}
                      En
                    </label>

                    <div
                      className="form-check form-switch p-0"
                      style={{ minHeight: "0px" }}
                    >
                      <input
                        className="form-check-input m-0"
                        type="checkbox"
                        id="languageSwitch"
                        role="switch"
                        checked={i18n.language === "fr"} // Set the checked state based on the current language
                        onChange={() =>
                          handleChangeLanguage(
                            i18n.language === "fr" ? "en" : "fr"
                          )
                        }
                      />
                    </div>
                    <label
                      className="form-check-label"
                      htmlFor="languageSwitch"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "13px",
                        lineHeight: "15.73px",
                        textAlign: "left",
                        color: "#4C4C4C",
                        fontWeight: i18n.language === "en" ? 200 : "bold",
                      }}
                    >
                      {/* {i18n.language === "fr" && "Fr"} */}
                      Fr
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
