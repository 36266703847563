import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./style/main.scss";
// import "./style/LandindPages.scss"
import { HeaderTitleProvider } from "./context/HeaderTitleContext";
import { DraftMeetingsProvider } from "./context/DraftMeetingContext";
import { TotalTimeProvider } from "./context/TotalTimeContext";
import { StepProvider } from "./context/stepContext";
import { StepProvider1 } from "./context/Step";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MeetingsProvider } from "./context/MeetingsContext";
import { TabsProvider } from "./context/TabContext";
import { UserProvider } from "./context/UserContext";
import { DestinationTabsProvider } from "./context/DestinationTabContext";
import { MeetingTabsProvider } from "./context/MeetingTabsContext";
import { SidebarProvider } from "./context/SidebarContext";
import { DestinationsProvider } from "./context/DestinationsContext";
import { FormProvider } from "./context/CreateMeetingContext";
import { I18nextProvider } from "react-i18next";
// import { ChartProvider } from "./context/ChartContext";
import i18next from "i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="50204800014-ldiqa8nnkv4d8jukk59d6qkgpi0bsljt.apps.googleusercontent.com">
    {/* <ChartProvider> */}
    <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <SidebarProvider>
        <DestinationsProvider>
          <MeetingsProvider>
            <TabsProvider>
              <MeetingTabsProvider>
                <DestinationTabsProvider>
                  <UserProvider>
                    <StepProvider1>
                      <StepProvider>
                        <TotalTimeProvider>
                          <DraftMeetingsProvider>
                            <HeaderTitleProvider>
                              <FormProvider>
                                {/* <BrowserRouter> */}
                                  <App />
                                {/* </BrowserRouter> */}
                              </FormProvider>
                            </HeaderTitleProvider>
                          </DraftMeetingsProvider>
                        </TotalTimeProvider>
                      </StepProvider>
                    </StepProvider1>
                  </UserProvider>
                </DestinationTabsProvider>
              </MeetingTabsProvider>
            </TabsProvider>
          </MeetingsProvider>
        </DestinationsProvider>
      </SidebarProvider>
      </BrowserRouter>
    </I18nextProvider>

    {/* </ChartProvider> */}
  </GoogleOAuthProvider>
);
