import React from 'react'
import ActionTabs from './ActionTabs'

const ParticipantToAction = () => {
  return (
    <div>
        <ActionTabs/>
    </div>
  )
}

export default ParticipantToAction