import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { RiEditBoxLine } from "react-icons/ri";
import { useHeaderTitle } from "../../../../context/HeaderTitleContext";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import { Assets_URL } from "../../../Apicongfig";
import EditParticipantModal from "./EditParticipantModal";
import { AiOutlineEye } from "react-icons/ai";

const Participants = ({ participants, isLoading, refreshedParticipants }) => {
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const navigate = useNavigate();
  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };
  const [t] = useTranslation("global");
  const { title, pushHeaderTitle, popHeaderTitle, setHeaderTitle } =
    useHeaderTitle();
  const [show, setShow] = useState(false);

  // const refreshedParticipants = async () => {
  //   // setIsLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${API_BASE_URL}/get-destination-with-meeting/${id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       const data = response?.data?.data;
  //       setDestination(data);
  //       setMeetings(data?.meetings);

  //       // Sort meetings by created_at date
  //       const sortedMeetings = data?.meetings?.sort(
  //         (a, b) => new Date(a.created_at) - new Date(b.created_at)
  //       );
  //       // Get the first created meeting
  //       const firstMeeting = sortedMeetings[0];
  //       setFirstMeetingDate(firstMeeting?.created_at);

  //       // Sort meetings by created_at date in descending order for newest meeting
  //       const sortedMeetingsDesc = data?.meetings?.sort(
  //         (a, b) => new Date(b.created_at) - new Date(a.created_at)
  //       );
  //       // Get the newest created meeting
  //       const newestMeeting = sortedMeetingsDesc[0];
  //       setLastMeetingDate(newestMeeting?.created_at);

  //       // Extract participants from each meeting and filter out creators
  //       const allParticipants = data?.meetings?.flatMap((meeting) =>
  //         meeting.participants.filter(
  //           (participant) => participant.isCreator !== 1
  //         )
  //       );

  //       // Combine participants for meetings with the same objective and remove duplicates
  //       const combinedParticipants = allParticipants?.reduce(
  //         (acc, participant) => {
  //           const key = `${participant.email}-${participant.post}`;
  //           if (!acc[key]) {
  //             acc[key] = { ...participant, meeting_count: 1 };
  //           } else {
  //             acc[key].meeting_count += 1;
  //           }
  //           return acc;
  //         },
  //         {}
  //       );

  //       const participants = Object.values(combinedParticipants);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };
  const handleClose = () => {
    setShow(!show);
    // refreshedParticipants()
  };

  const [participant, setParticipant] = useState(null);
  const updateParticipant = (item) => {
    // navigate(`/updateParticipant/${item.id}`);
    setParticipant(item);
    setShow(true);
  };
  const userId = parseInt(sessionStorage.getItem("user_id"));

  const handleShowProfileLink = (id) => {
    window.open(`/heroes/${id}`, "_blank");
  };
  return (
    <div className="destination-to-participants table-responsive">
      <table className="team-members-table">
        <thead>
          <tr className="table-header-row">
            <th>#</th>
            <th>{t("invities.name")}</th>
            <th>{t("invities.email")}</th>
            <th>{t("invities.enterprise")}</th>
            <th>{t("invities.post")}</th>
            <th>Moments</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {participants?.map((item, index) => (
            <tr key={index} className="team-member-row">
              <td>{index + 1}.</td>
              <td>
                <Avatar
                  src={
                    item?.participant_image?.startsWith("users/")
                      ? Assets_URL + "/" + item?.participant_image
                      : item?.participant_image
                  }
                />
                &nbsp; &nbsp;
                {item?.first_name + " " + item?.last_name}
              </td>
              <td>{item?.email}</td>
              <td>{item?.user?.enterprise?.name}</td>
              <td>{item?.post}</td>
              {/* <td>
                {item?.user_id
                  ? item?.user?.teams?.length
                    ? item.user.teams.map((team) => team.name)
                    : "Invité"
                  : "Invité"}
              </td> */}
              <td>{item?.meeting_count}</td>
              <td className="text-center">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={openDropdownId === item.id}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "0px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(item.id);
                  }}
                >
                  <BiDotsVerticalRounded color="black" size={"25px"} />
                </button>
                <ul
                  className={`dropdown-menu ${
                    openDropdownId === item.id ? "show" : ""
                  }`}
                >
                  <li>
                    <a
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      // onClick={(e) => {
                      //   updateParticipant(item);
                      // }}
                      onClick={(e) =>
                        item?.user_id !== null && item?.user_id !== userId
                          ? handleShowProfileLink(item?.user.nick_name)
                          : updateParticipant(item)
                      }
                    >
                      {item?.user_id !== null && item?.user_id !== userId ? (
                        <>
                          <AiOutlineEye
                            size={"20px"}
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   handleShowProfileLink(item?.uuid);
                            // }}
                          />{" "}
                          &nbsp;
                          {t("dropdown.Review the detail")}
                        </>
                      ) : (
                        <>
                          <RiEditBoxLine size={"20px"} /> &nbsp;
                          {t("dropdown.To modify")}
                        </>
                      )}
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {
        <EditParticipantModal
          show={show}
          handleClose={handleClose}
          participant={participant}
        />
      }
    </div>
  );
};

export default Participants;
