import React, { createContext, useContext, useState } from 'react';

const DraftMeetingsContext = createContext();

export const useDraftMeetings = () => useContext(DraftMeetingsContext);

export const DraftMeetingsProvider = ({ children }) => {
  const [language, setLanguage] = useState("");

  const updateLanguage = (language) => {
    setLanguage(language);
  };

  return (
    <DraftMeetingsContext.Provider value={{ language, updateLanguage }}>
      {children}
    </DraftMeetingsContext.Provider>
  );
};
