import React from 'react'
import Abouttektime from '../Components/Elements/landingPages/Abouttektime'
import Wayofsupporting from '../Components/Elements/landingPages/Wayofsupporting'
import Ourkeyvalues from '../Components/Elements/landingPages/Ourkeyvalues'

function About() {
  return (
    <div>
        <Abouttektime/>
        <Wayofsupporting/>
        <Ourkeyvalues/>
    </div>
  )
}

export default About