import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL, Assets_URL } from "../Apicongfig";
import { FaUserPen } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../context/HeaderTitleContext";
import axios from "axios";
import { useDraftMeetings } from "../../context/DraftMeetingContext";
// import { HiDotsVertical } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BiHomeSmile, BiUser } from "react-icons/bi";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { FiSettings } from "react-icons/fi";
import { AiOutlineRollback } from "react-icons/ai";
import { MdMenu, MdClose } from "react-icons/md";
import MobileSidebar from "./MobileSidebar";
import { useMeetings } from "../../context/MeetingsContext";

function Header({ onSignin, onLogout, isAuthenticated, onSearch }) {
  const { updateLanguage } = useDraftMeetings();
  const { profileImage, setProfileImage } = useHeaderTitle();

  const [imageError, setImageError] = useState(false);
  const { title, popHeaderTitle } = useHeaderTitle();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  // console.log('')
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [profileImage, setProfileImage] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const [t, i18n] = useTranslation("global");

  const { status } = useMeetings();
  console.log("status", status);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    console.log("lang", lang);
    updateLanguage(lang);
  };

  const getHeaderText = () => {
    switch (location.pathname) {
      case "/":
        return t("header.home");
      case "/Enterprises":
        return t("header.enterprises");
      case location.pathname.startsWith("/invite/"):
        return " ";
      case "/enterprises":
        return t("header.enterprises");

      case `/invite/${params.id}`:
        if (status === "active" || status === "in_progress") {
          return t("Make Moments Great Again");
        }
      case `/present/invite/${params.id}`:
        if (status === "closed") {
          return t("Take Me To The Magic Of The Moment");
        }
      // case "/meeting":
      // return t("header.myEvents");
      case "/meeting/new":
        return t("header.This is Your Moment");
      case `/updateMeeting/${params.id}`:
        return t("header.This is Your Moment");
      case `/copyMeeting/${params.id}`:
        return t("header.This is Your Moment");
      case location.pathname.startsWith("/tektime/"):
        if (params.id) {
          return t("header.trainingWithId", { id: params.id });
        } else {
          return t("header.training");
        }
      case "/graph":
        return t("header.newMeeting");
      case location.pathname.startsWith("/presentation/"):
        return t("header.automatedReport");
      // case "/profile":
      //   return t("header.myProfile");
      case "/updatepassword":
        return t("header.passwordtitle");
      case "/Team":
        return t("header.teams");
      // case "/Invities":
      //   return "this is my moment";
      case "/invitiesToMeeting":
        return t("header.guests");
      case "/contract":
        return t("header.contract");
      case "/drafts":
        return t("header.drafts");
      case `/validateMeeting/${params.id}`:
        return t("header.validateMeeting");
      case `/validateMeeting2/${params.id}`:
        return t("header.validateMeeting");
      // DYNAMIC ROUTES
      case "/CopyContract":
        return t("header.duplicateContract");
      case `/CopyContract/${params.id}`:
        return t("header.duplicateContract");
      case `/ModifierContract/${params.id}`:
        return t("header.modifyContract");
      case `/ModifierEnterprises/${params.id}`:
        return t("header.modifyEnterprise");
      case `/ModifierTeam/${params.id}`:
        return t("header.modifyTeam");
      case `/readContract/${params.id}`:
        // Contract Details
        return t("header.contractDetails");
      case `/CopyClosedContract/${params.id}`:
        return t("header.duplicateContract");
      case `/ModifierUser/${params.id}`:
        return t("header.modifyUser");
      case `/view/${params.id}`:
        return t("header.preview");
      case `/updateParticipant/${params.id}`:
        return t("header.modifyParticipant");
      case `/participant/${params.id}`:
        return t("header.partcipantDetail");
      case `/completeedit/${params.id}`:
        return t("header.modifyMeeting");
      case `/meetingDetail/${params.id}`:
        return t("header.meetingDetail");
      case `/play/${params.id}`:
        return <img src="/Assets/Tek.png" width="60px" alt="Tektime" />;
      case `/draft/${params.id}`:
        return t("header.draftMeeting");
      case `/presentation/${params.id}`:
        return t("header.reporting");
      case `/presentationreport/${params.id}`:
        return t("header.reporting");
      case `/step-details/${params.id}`:
        return "Détails de l'étape";
      case `/customer-support`:
        return t("sidebar.customer-support");
      // return t("header.stepDetails");
      default:
      //   return t("header.default");
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const userID = sessionStorage.getItem("user_id");
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/users/${userID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status === 200) {
          // console.log("response in header", response);
          setProfileImage(response.data.data?.image);
        }
      } catch (error) {
        // console.log(error?.message);
      }
    };
    getUser();
  }, [userID]);

  console.log("location-base", location);
  let fromMeeting = false;
  if (location?.state?.from === "meeting") {
    fromMeeting = true;
  }
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  // useClickAway(ref, () => setOpen(false))
  const toggleSidebar = () => setOpen((prev) => !prev);


  // Check if the current route matches the conditions to hide the menu button
  const hideMenuButton =
    location.pathname === "/newsletter/terms-and-conditions" ||
    /^\/destination\/[^/]+\/[^/]+$/.test(location.pathname);

  return (
    <div className={` ${scrolled ? "header" : "header"}`}>
      <div className="container-fluid">
        <div
          className={`pt-3 ${
            (window.location.href.includes("/invite") &&
              window.location.href.includes("/destination")) ||
            "pb-3"
          } row`}
          style={{ borderBottom: "1px solid #D4D4D4" }}
        >
          <div className="my d-flex justify-content-between align-items-center" style={{paddingLeft:'0px'}}>
            <div>
              {(window.location.href.includes("/invite") && !fromMeeting) ||
              window.location.href.includes("/destination") ? (
                <>
                  <a
                    href="https://www.tektime.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/Assets/sidebar-invite-logo.svg"
                      alt="tektime"
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                </>
              ) : (
                ""
              )}

              {(window.location.href.includes("/meeting") ||
                window.location.href.includes("/Invities")) &&
                !window.location.href.includes("/meeting/new") &&
                !window.location.href.includes("/meetingDetail") && (
                  <div className="search-container">
                    <input
                      type="search"
                      className="search-bar"
                      placeholder={t("header.search")}
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    {/* <FaSearch className="search-icon" /> */}
                  </div>
                )}
              {/* Mobile Sidebar for smaller screens */}
                {/* Mobile Sidebar for smaller screens */}
                {!hideMenuButton && !window.location.href.includes("/heroes") && (
                <div className="mobile-sidebar">
                  <button onClick={toggleSidebar}>
                    {open ? <MdClose size={25} /> : <MdMenu size={25} />}
                  </button>
                </div>
              )}
              {/* {!window.location.href.includes("/heroes") && (
                <div className="mobile-sidebar">
                  <button onClick={toggleSidebar}>
                    {open ? <MdClose size={25} /> : <MdMenu size={25} />}
                  </button>
                </div>
              )} */}
            </div>
            {/* <div> */}
            <div>
              {getHeaderText() ? (
                <h3>{getHeaderText()}</h3>
              ) : (
                <h5>
                  {title.length > 0 &&
                    Array.isArray(title) &&
                    title.map((item, index) => {
                      const isLast = index === title.length - 1;
                      return (
                        <span className="breadcrumbs">
                          <Link
                            className={`${isLast ? "opacity-50" : ""}`}
                            to={item.link}
                            onClick={() => {
                              const items_to_pop = title.length - index - 1;
                              for (let i = 0; i < items_to_pop; i++) {
                                popHeaderTitle();
                              }
                            }}
                          >
                            {item.titleText}
                          </Link>
                          <span>&nbsp; {!isLast && ">"} &nbsp;</span>
                        </span>
                      );
                    })}
                </h5>
              )}
            </div>
            {/* </div> */}
            {/* <div> */}
            <div className="mt-1 swtich">
              <label
                className="form-check-label mr-2"
                htmlFor="languageSwitch"
                style={{
                  fontFamily: "Inter",
                  fontSize: "13px",
                  lineHeight: "15.73px",
                  textAlign: "left",
                  color: "#4C4C4C",
                  fontWeight: i18n.language === "fr" ? 200 : "bold",
                }}
              >
                {/* {i18n.language === "en" && "En"} */}
                En
              </label>

              <div
                className="form-check form-switch p-0"
                style={{ minHeight: "0px" }}
              >
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  id="languageSwitch"
                  role="switch"
                  checked={i18n.language === "fr"} // Set the checked state based on the current language
                  onChange={() =>
                    handleChangeLanguage(i18n.language === "fr" ? "en" : "fr")
                  }
                />
              </div>
              <label
                className="form-check-label"
                htmlFor="languageSwitch"
                style={{
                  fontFamily: "Inter",
                  fontSize: "13px",
                  lineHeight: "15.73px",
                  textAlign: "left",
                  color: "#4C4C4C",
                  fontWeight: i18n.language === "en" ? 200 : "bold",
                }}
              >
                {/* {i18n.language === "fr" && "Fr"} */}
                Fr
              </label>
            </div>
            {/* </div> */}
          </div>
        </div>

        {!window.location.href.includes("/destination") || !fromMeeting || (
          <hr
            style={{
              marginLeft: !fromMeeting && "3rem",
            }}
          />
        )}
      </div>

      {open && (
        <>
          <div aria-hidden="true" className="mobile-sidebar1"></div>
          <div className="mobile-sidebar-panel" ref={ref} aria-label="Sidebar">
            <div
              className="flex items-center justify-between border-b-2 border-zinc-800"
              style={{
                justifyContent: "end",
                display: "flex",
                fontSize: "26px",
                padding: "27px",
              }}
            >
              {/* <span>Welcome</span> */}
              <button
                onClick={toggleSidebar}
                className="border-2 border-zinc-800 rounded-xl"
                aria-label="close sidebar"
                // style={{ padding: "19px" }}
              >
                <MdClose />
              </button>
            </div>

            <MobileSidebar open={open} handleClose={toggleSidebar} />
          </div>
        </>
      )}
    </div>
  );
}

export default Header;
