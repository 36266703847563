import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

function BasePage(props) {
  const location = useLocation();
  const routesWithBasePage = [
    "/",
    "/about",
    "/contact",
    "/privacy-policy",
    "/terms&conditions",
    "/pricing",
    "/useCase/1",
    "/useCase/2",
    "/useCase/3",
    "/useCase/4",
  ];

  const shouldShowHeaderFooter = routesWithBasePage.includes(location.pathname);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);

  return (
    <div>
      {shouldShowHeaderFooter && <Navbar />}
      {props.children}
      {shouldShowHeaderFooter && <Footer />}
    </div>
  );
}

export default BasePage;
