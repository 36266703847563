// Development Server
// const API_BASE_URL = "https://tektime-mo.digitalisolutions.net/api";
// const Assets_URL = "https://tektime-mo.digitalisolutions.net/";
// const NODE_API = `https://file.tektime.io/api/upload`;
//NODE_API(CLOUDINARY)
const NODE_API = `https://tektime-cloudinary-api.onrender.com/api/upload`;
//CLOUDINARY_API(FRONTEND)
const Cloudinary_URL = 'https://api.cloudinary.com/v1_1/drrk2kqvy/upload'

// Stage Server
// const API_BASE_URL = "https://stage-tektime.digitalisolutions.net/api"
// const Assets_URL = "https://stage-tektime.digitalisolutions.net/";

// Production Server
const API_BASE_URL = "https://api.tektime.io/api";
// const Assets_URL = "https://api.tektime.io/";
const Assets_URL = "https://tektime-storage.s3.eu-north-1.amazonaws.com";


export { API_BASE_URL , Assets_URL, NODE_API,Cloudinary_URL };