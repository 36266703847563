import { RxCross2 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useFormContext } from "../../../../../../context/CreateMeetingContext";
import { useEffect, useState } from "react";

const EditGuest = ({ editModal, GuestId, closeEditModal }) => {
  const {
    loading,
    GetSingleGuestData,
    singleGuestData,
    setSingleGuestData,
    updateGuest,
  } = useFormContext();
  console.log(singleGuestData, "singleGuestData");
  const [t] = useTranslation("global");

  useEffect(() => {
    if (GuestId) {
      GetSingleGuestData(GuestId);
    }
  }, [GuestId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSingleGuestData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    const response = await updateGuest(GuestId);
    if (response?.data?.success) {
      closeEditModal();
    } else {
      console.log(response?.success, "vvvvv");
    }
  };

  return (
    <>
      {editModal && (
        <div id="chart-container" className="chart-content">
          <div className="modal-overlay">
            <div className="new-meeting-modal">
              <div className="modal-nav">
                <div>
                  {<h4>{t("meeting.formState.Edit Guest")}</h4>}
                  <p
                    className="solutioncards px-2"
                    style={{ color: "#92929D" }}
                  >
                    {t("meeting.formState.Update Invite Information")}
                  </p>
                </div>

                <div className="d-flex justify-content-end">
                  <button className="cross-btn" onClick={closeEditModal}>
                    <RxCross2 size={18} />
                  </button>
                </div>
              </div>
              <div className="mt-3 modal-body">
                <Container className="create-moment-modal">
                  <Row>
                    <Col xs={12} md={6} className="mb-2 form">
                      <label className="form-label">Email</label>
                      <small
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </small>
                      <input
                        type="text"
                        name="email"
                        required
                        className="form-control"
                        value={singleGuestData?.email}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col xs={12} md={6} className="mb-2 form">
                      <label className="form-label">
                        {" "}
                        {t("meeting.formState.post")}
                      </label>
                      <small
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </small>
                      <input
                        type="text"
                        name="post"
                        required
                        className="form-control"
                        value={singleGuestData.post}
                        onChange={handleInputChange}
                        // placeholder="Enter your post"
                      />
                    </Col>
                    <Col xs={12} md={6} className="mb-2 form">
                      <label className="form-label">
                        {t("meeting.formState.firstName")}
                      </label>
                      <small
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </small>
                      <input
                        type="text"
                        name="first_name"
                        required
                        className="form-control"
                        value={singleGuestData.first_name}
                        onChange={handleInputChange}
                        placeholder={t("Enter Name")}
                      />
                    </Col>
                    <Col xs={12} md={6} className="mb-2 form">
                      <label className="form-label">
                        {t("meeting.formState.lastName")}
                      </label>
                      <small
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </small>
                      <input
                        type="text"
                        name="last_name"
                        required
                        className="form-control"
                        value={singleGuestData.last_name}
                        onChange={handleInputChange}
                        placeholder="Enter your Last Name"
                      />
                    </Col>
                    <Col xs={12} md={12} className="mb-2 form">
                      <label className="form-label">{t("Contribution")}</label>
                      <textarea
                        type="text"
                        name="contribution"
                        required
                        className="form-control"
                        rows={3}
                        value={singleGuestData.contribution}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <div
                    className={`col-md-12 d-flex justify-content-end px-4 mt-5 `}
                    onClick={handleUpdate}
                  >
                    {loading ? (
                      <button className={`btn moment-btn px-2 py-0`}>
                        <span
                          class="spinner-border spinner-border-sm text-white"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button className={`btn moment-btn`}>
                        &nbsp;{t("meeting.formState.Update")}
                      </button>
                    )}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditGuest;
