import React from "react";
import { useTranslation } from "react-i18next";

function Testimonials() {
  const [t] = useTranslation("global");

  return (
    <div className="mt-5 testimonials py-5">
      <div className="container">
        <p className="description fs-6 green-txtcolor text-center">
          {t('testimonials.title')}
        </p>
        <div>
          <h4 className="main-heading fs-4 fw-bold text-white border-bottom pb-3">
            {`“${t('testimonials.quote')}”`}
          </h4>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-3 mt-3 align-items-center">
              <div>
                <img
                  src="Assets/landing/1564908244965.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div>
                <p className="mb-0">{t('testimonials.author')}</p>
                <span className="mb-0">{t('testimonials.position')}</span>
              </div>
            </div>
            {/* <div className="d-flex gap-2 align-items-center mt-3">
              <img src="Assets/arrow-left.svg" alt="" className="img-fluid" />
              <img src="Assets/arrow-right.svg" alt="" className="img-fluid" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
