import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { RiDeleteRow } from "react-icons/ri";
import { HiUserCircle } from "react-icons/hi2";
import { API_BASE_URL } from "../../../Apicongfig";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import NoContent from "../../Meeting/NoContent";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHeaderTitle } from "../../../../context/HeaderTitleContext";

const InProgress = () => {
  const [t] = useTranslation("global");
  const { setHeaderTitle } = useHeaderTitle();
  console.log("setHeaderTitle", setHeaderTitle);

  const navigate = useNavigate();
  const { id } = useParams();
  const [action, setAction] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getParticipantAction = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/participants/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response) {
        setAction(response.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const handleUpdateEnterprises = () => {
    navigate("/ModifierEnterprises");
  };
  useEffect(() => {
    getParticipantAction();
  }, []);

  let time = 0;

  const checkStatus = (item) => {
    // setHeaderTitle({titleText:" "})
    console.log("item", item);
    if (item.status === "active" || item.status === "in_progress") {
      window.open(`/invite/${item?.id}`, "_blank");
      // navigate(`/invite/${item?.id}`);
    } else {
      window.open(
        `/presentationreport/${item?.user_id}/${item?.id}/${item?.objective}`,
        "_blank"
      );
    }
  };

  return (
    <div className="scheduled">
      <div className="container-fluid px-3">
        <div className="row justify-content-center">
          {isLoading ? (
            <Spinner
              animation="border"
              role="status"
              className="center-spinner"
            ></Spinner>
          ) : (
            <>
              {action?.meetings?.map((item, index) => {
                return (
                  <>
                    <div className="col-md-12 py-3" key={index}>
                      <div className="card">
                        <div className="card-body">
                          <div className="cardbody">
                            <div className="row subtitle card2 py-4 px-3">
                              <div className="col-md-1 text-start obj">
                                <span>
                                  {" "}
                                  {t("meeting.activeMeetings.criticality")}
                                </span>
                              </div>
                              <div className="col-md-2 text-center obj">
                                <span>{t("meeting.activeMeetings.type")}</span>
                              </div>
                              <div className="col-md-2 text-center obj">
                                <span>{t("meeting.activeMeetings.niche")}</span>
                              </div>
                              <div
                                className={` ${
                                  item.status === "abort"
                                    ? "col-md-2"
                                    : "col-md-2"
                                } text-center obj`}
                              >
                                <span>
                                  {t("meeting.activeMeetings.destinations")}
                                </span>
                              </div>
                              <div
                                className={`col-md-2 text-center obj`}
                              >
                                <span>
                                  {t("meeting.activeMeetings.objective")}
                                </span>
                              </div>
                              <div className={`col-md-2 ${item.status === 'abort' ? 'text-end' : 'text-center'} obj `}>
                                <span>
                                  {t("meeting.activeMeetings.duration")}
                                </span>
                              </div>
                              {item.status !== "abort" &&
                                item.status !== "cancel" && (
                                  <div className="col-md-1 text-end obj">
                                    <span>
                                      {t("meeting.activeMeetings.actions")}
                                    </span>
                                  </div>
                                )}
                            </div>
                            <div className="row py-4 pb-5 text-body-dark px-3">
                              <div className="col-md-1 text-start obj1">
                                {/* <h6>{item.order}</h6> */}
                                <h6>{item.priority}</h6>
                              </div>
                              <div className="col-md-2 text-center obj1">
                                <h6>{item.type}</h6>
                              </div>
                              <div className="col-md-2 text-center obj1">
                                <h6>
                                  {item.start_time !== null
                                    ? moment(item.start_time, "HH:mm").format(
                                        "hh:mm A"
                                      )
                                    : ""}
                                </h6>
                              </div>
                              <div
                                className={` ${
                                  item.status === "abort"
                                    ? "col-md-2"
                                    : "col-md-2"
                                } text-center obj1`}
                              >
                                <h6>{item.objective}</h6>
                              </div>
                              <div
                                className={`col-md-2 text-center obj1`}
                              >
                                <h6>{item.title}</h6>
                              </div>
                              <div className={`col-md-2 ${item.status === 'abort' ? 'text-end' : 'text-center'} obj1`}>
                                <h6>
                                  {
                                    (time =
                                      item?.steps?.reduce(
                                        (total, step) => total + step.count2,
                                        0
                                      ) + " Mins")
                                  }
                                </h6>
                              </div>
                              {item.status !== "abort" &&
                                item.status !== "cancel" && (
                                  <div className="col-md-1 text-end obj1">
                                    <IoEyeOutline
                                      size={"22px"}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => checkStatus(item)}
                                      // onClick={() => {
                                      //   setHeaderTitle([
                                      //     {
                                      //       titleText: "Stratégie de",
                                      //       link: "/Invities",
                                      //     },
                                      //     {
                                      //       titleText: `${item.first_name} ${item.last_name} sur `,

                                      //       link: `/participantToAction/${item.id}`,
                                      //     },
                                      //   ]);
                                      //   checkStatus(item)
                                      // }}
                                    />
                                  </div>
                                )}
                            </div>
                            {item.status === "active" && (
                              <span className="mx-2 badge bg-success">
                                {t("badge.active")}
                              </span>
                            )}
                            {item.status === "abort" && (
                              <span className="mx-2 badge bg-danger">
                                {t("badge.cancel")}
                              </span>
                            )}
                            {item.status === "closed" && (
                              <span className="mx-2 badge bg-success">
                                {t("badge.finished")}
                              </span>
                            )}
                            {item.status === "in_progress" && (
                              <span className="mx-2 badge bg-warning">
                                {t("badge.inprogress")}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <button
                className="btn btn-primary w-25 my-4"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Revenir à la page précédente
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InProgress;
