import React, { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Editor } from "@tinymce/tinymce-react";
import { API_BASE_URL, Assets_URL, NODE_API } from "../../Apicongfig";
import { useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { useTotalTime } from "../../../context/TotalTimeContext";
import cheerio from "cheerio";
import { useTranslation } from "react-i18next";
import { Button, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { set } from "lodash";
import { useSteps } from "../../../context/Step";

// Function to extract base64 image sources from HTML string
function extractBase64SrcFromHTML(htmlString) {
  const base64SrcArray = [];

  // Load the HTML string into cheerio
  const $ = cheerio.load(htmlString);

  // Find all elements with 'src' attribute
  $("[src]").each((index, element) => {
    const srcValue = $(element).attr("src");

    // Check if the src starts with 'data:image'
    if (srcValue && srcValue?.startsWith("data:image")) {
      // If src is a base64 image, push it into base64SrcArray
      base64SrcArray.push(srcValue);
    }
  });

  return base64SrcArray;
}

// Function to replace base64 image sources with cloud URLs in HTML string
function replaceBase64SrcWithLinks(htmlString, imageLinks) {
  // Load the HTML string into cheerio
  const $ = cheerio.load(htmlString);

  // Find all elements with 'src' attribute
  $("[src]").each((index, element) => {
    const srcValue = $(element).attr("src");

    // Check if the src starts with 'data:image'
    if (srcValue && srcValue?.startsWith("data:image")) {
      // Replace the src with the corresponding link from imageLinks
      $(element).attr("src", imageLinks[index]);
    }
  });

  // Return the modified HTML string
  return $.html();
}

//FrontEnd - Cloudinary
export const optimizeEditorContent = async (editorContent) => {
  if (!editorContent) {
    return "";
  }
  //-------- CLOUD LOGIC ------------------------------
  let optimizedEditorContent = "";
  const base64Array = extractBase64SrcFromHTML(editorContent);
  if (!base64Array.length > 0) {
    optimizedEditorContent = editorContent;
    return optimizedEditorContent;
  } else {
    const cloudinaryUploads = base64Array.map(async (base64Image) => {
      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/drrk2kqvy/upload",
          {
            method: "POST",
            body: JSON.stringify({
              file: base64Image,
              upload_preset: "chat-application",
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        return data.secure_url;
      } catch (error) {
        console.error("Error uploading image to Cloudinary:", error);
        return null;
      }
    });

    const uploadedImageUrls = await Promise.all(cloudinaryUploads);
    const editorContentWithCloudLinks = replaceBase64SrcWithLinks(
      editorContent,
      uploadedImageUrls
    );

    optimizedEditorContent = editorContentWithCloudLinks;
    return optimizedEditorContent;
  }
  //   // ------- CLOUD LOGIC END -------------------------
};

//----------------------------------------------

const InviteStepChartModal = ({
  meetingId,
  show,
  closeModal,
  stepId,
  stepIndex,
  meeting,
}) => {
  console.log("stepIndex", stepIndex);
  console.log("stepId", stepId);
  console.log("meeting", meeting);
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  // console.log("participants", participants);
  const location = window.location.href;
  const fromReport = location.includes("step-details")
    ? true
    : location.includes("meetingDetail")
    ? true
    : false;
  const [t] = useTranslation("global");

  const [isDisabled, setIsDisabled] = useState(false);
  const id = useParams().id;
  const [inputData, setInputData] = useState({});
  // const [loading, setLoading] = useState(false);
  const [selectedBar, setSelectedBar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const stepRef = useRef();
  const [accumulatedSelectedCounts, setAccumulatedSelectedCounts] = useState(
    []
  );
  const [storedStartTime, setStoredStartTime] = useState(null);
  const [storedStartDate, setStoredStartDate] = useState(null);
  const [storedStartDateForHour, setStoredStartDateForHour] = useState(null);

  console.log("storedStartTime", storedStartTime);
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [countSum, setCountSum] = useState(0);
  const [modifiedFileText, setModifiedFileText] = useState();
  const [modalType, setModalType] = useState("Editeur");
  const [editorContent, setEditorContent] = useState("");
  const [validateBtnText, setValidateBtnText] = useState("Valider");

  const inputDataRef = useRef(inputData);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [timeUnit, setTimeUnit] = useState("minutes");
  const { updateSteps } = useSteps();

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    // console.log("file: ", file);
    setIsUpload(true);
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    // Check file size (6 MB = 6 * 1024 * 1024 bytes)
    if (file.size > 6 * 1024 * 1024) {
      toast.error(t("meeting.chart.error.file"));
      setIsUpload(false);
      return;
    }
    if (file && allowedFileTypes.includes(file.type)) {
      try {
        setIsDisabled(false);

        // const updatedSteps = [...(inputData?.steps || [])];
        // const selectedStep = updatedSteps[selectedIndex];

        const filePayload = {
          title: selectedValue,
          count1: selectedCount,
          count2: selectedCount,
          time: selectedCount,
          editor_type: "File",
          file: file,
          editor_content: null,
          _method: "put",
        };

        const formData = new FormData();
        formData.append("title", filePayload.title);
        formData.append("count1", filePayload.count1);
        formData.append("count2", filePayload.count2);
        formData.append("time", filePayload.time);
        formData.append("editor_type", filePayload.editor_type);
        formData.append("file", filePayload.file);
        formData.append("editor_content", filePayload.editor_content);
        formData.append("_method", filePayload._method);

        const response = await axios.post(
          `${API_BASE_URL}/steps/${stepId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          setFileName(response.data?.data?.file);
          setIsUpload(false);
        }
      } catch (error) {
        console.log("error while uploading file", error);
        setIsUpload(false);
      }
    } else {
      alert(
        "Please select a valid file type: PDF, Excel, PowerPoint, or Word."
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ".pdf,.xlsx,.xls,.ppt,.pptx,.doc,.docx",
    accept: ".pdf,.xlsx,.xls,.ppt,.pptx,.doc,.docx",
    onDrop,
  });

  const [count2, setCount2] = useState(0);
  const [title, setTitle] = useState(null);
  const [step, setStep] = useState({});
  console.log("step", step);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getStep = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/steps/${stepId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status === 200) {
          const stepData = response.data?.data;
          setLink(stepData?.url);
          setShowPreview(true);
          setPreviewUrl(stepData?.url);
          setStep(response.data?.data);
          //   setModalType(stepData?.editor_type);
          //   setTimeUnit(stepData?.time_unit);
          //   setFileName(stepData?.file);
          //   setModifiedFileText(stepData?.editor_content);
          //   setAssignUser(response.data?.data?.assigned_to_name);
          //   setUser(response.data?.data?.assigned_to);
          //   setCount2(stepData?.count2 || 0);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getStep();
  }, [stepId]);
  const [user, setUser] = useState(null);
  const handleUserSelect = (e) => {
    setUser(e.target.value);
  };
  const [assignUser, setAssignUser] = useState(null);
  const [time, setTime] = useState(null);

  const getMeeting = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/meetings/${meetingId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log("response--> getMeeting Edit", response.data.data);
      if (response.status === 200) {
        const steps = response?.data?.data?.steps;
        updateSteps(steps);
        setInputData(response?.data?.data || {});
        setTime(response?.data?.data?.start_time);
        console.log("count2", count2);

        // let sumCount2 = 0;
        // for (let i = 0; i < stepIndex; i++) {
        //   sumCount2 += response.data?.data?.steps[i]?.count2 || 0; // Make sure to handle cases where count2 might be undefined
        // }

        // if (stepIndex === 0) {
        //   setStoredStartTime(
        //     moment(response.data?.data?.start_time, "HH:mm").format("hh:mm a")
        //   );
        //   setStoredStartDate(
        //     moment(response?.data?.data?.date).format("YYYY-MM-DD")
        //   );
        //   setStoredStartDateForHour(
        //     moment(response?.data?.data?.date).format("DD/MM/YYYY")
        //   );
        // } else {
        //   let sumCount2Minutes = 0;
        //   let totalSeconds = 0;
        //   for (let i = 0; i < stepIndex; i++) {
        //     const step = response.data?.data?.steps[i];
        //     if (step) {
        //       if (step.time_unit === "hours") {
        //         sumCount2Minutes += step.count2 * 60;
        //       } else if (step.time_unit === "minutes") {
        //         sumCount2Minutes += step.count2;
        //       } else if (step.time_unit === "seconds") {
        //         totalSeconds += step.count2;
        //       }
        //     }
        //   }

        //   if (totalSeconds > 0) {
        //     const newStoredStartTime = moment(
        //       response?.data?.data?.start_time,
        //       "HH:mm"
        //     )
        //       .add(sumCount2Minutes, "minutes")
        //       .add(totalSeconds, "seconds")
        //       .format("hh:mm:ss a");
        //     setStoredStartTime(newStoredStartTime);

        //     const newStoredStartDate =
        //       inputData?.steps[selectedIndex]?.time_unit === "seconds"
        //         ? moment(inputData?.date)
        //             .add(sumCount2Minutes, "minutes")
        //             .format("DD/MM/YYYY") // Format to HH:mm:ss
        //         : moment(inputData?.date)
        //             .add(sumCount2Minutes, "minutes")
        //             .format("DD/MM/YYYY"); // Format to HH:mm if not seconds
        //     setStoredStartDateForHour(newStoredStartDate);
        //     // setStoredStartDateForHour(moment(response?.data?.data?.date).format("DD/MM/YYYY"));
        //   } else {
        //     const newStoredStartTime = moment(
        //       response?.data?.data?.start_time,
        //       "HH:mm"
        //     )
        //       .add(sumCount2Minutes, "minutes")
        //       .format("hh:mm a");
        //     setStoredStartTime(newStoredStartTime);

        //     const startDate = new Date(
        //       `${inputData?.date}T${inputData?.start_time}`
        //     );

        //     const newStoredStartDate =
        //       inputData?.steps[selectedIndex]?.time_unit === "seconds"
        //         ? moment(startDate) // Use startDate here
        //             .add(sumCount2Minutes, "minutes")
        //             .format("DD/MM/YYYY") // Format to DD/MM/YYYY HH:mm:ss for seconds
        //         : moment(startDate) // Use startDate here
        //             .add(sumCount2Minutes, "minutes")
        //             .format("DD/MM/YYYY"); // Format to DD/MM/YYYY HH:mm for other units

        //     setStoredStartDateForHour(newStoredStartDate);
        //   }

        //   setStoredStartDate(
        //     moment(response?.data?.data?.date)
        //       .add(sumCount2, "days")
        //       .format("YYYY-MM-DD")
        //   );
        // }
      }
    } catch (error) {
      console.log("error while getting steps", error);
    }
  };

  // useEffect to handle meeting data from props
  useEffect(() => {
    if (meeting) {
      const steps = meeting.steps || [];
      const start_time = meeting.start_time;
      const date = meeting.date;
      setCount2(steps[stepIndex]?.count2);
      setTitle(steps[stepIndex]?.title);
      setTimeUnit(steps[stepIndex]?.time_unit);
      setModalType(steps[stepIndex]?.editor_type);
      setFileName(steps[stepIndex]?.file);
      setModifiedFileText(steps[stepIndex]?.editor_content);
      setAssignUser(steps[stepIndex]?.assigned_to_name);
      setUser(steps[stepIndex]?.assigned_to);

      let sumCount2 = 0;
      for (let i = 0; i < stepIndex; i++) {
        sumCount2 += meeting?.steps[i]?.count2 || 0; // Make sure to handle cases where count2 might be undefined
      }

      if (stepIndex === 0) {
        setStoredStartTime(moment(start_time, "HH:mm").format("hh:mm a"));
        setStoredStartDate(moment(date).format("YYYY-MM-DD"));
        setStoredStartDateForHour(moment(date).format("DD/MM/YYYY"));
      } else {
        let sumCount2Minutes = 0;
        let totalSeconds = 0;
        for (let i = 0; i < stepIndex; i++) {
          const step = meeting?.steps[i];
          if (step) {
            if (step.time_unit === "hours") {
              sumCount2Minutes += step.count2 * 60;
            } else if (step.time_unit === "minutes") {
              sumCount2Minutes += step.count2;
            } else if (step.time_unit === "seconds") {
              totalSeconds += step.count2;
            }
          }
        }

        if (totalSeconds > 0) {
          const newStoredStartTime = moment(start_time, "HH:mm")
            .add(sumCount2Minutes, "minutes")
            .add(totalSeconds, "seconds")
            .format("hh:mm:ss a");
          setStoredStartTime(newStoredStartTime);

          const newStoredStartDate =
            meeting?.steps[selectedIndex]?.time_unit === "seconds"
              ? moment(meeting?.date)
                  .add(sumCount2Minutes, "minutes")
                  .format("DD/MM/YYYY") // Format to HH:mm:ss
              : moment(meeting?.date)
                  .add(sumCount2Minutes, "minutes")
                  .format("DD/MM/YYYY"); // Format to HH:mm if not seconds
          setStoredStartDateForHour(newStoredStartDate);
          // setStoredStartDateForHour(moment(response?.data?.data?.date).format("DD/MM/YYYY"));
        } else {
          const newStoredStartTime = moment(start_time, "HH:mm")
            .add(sumCount2Minutes, "minutes")
            .format("hh:mm a");
          setStoredStartTime(newStoredStartTime);

          const startDate = new Date(`${meeting?.date}T${meeting?.start_time}`);

          const newStoredStartDate =
            meeting?.steps[selectedIndex]?.time_unit === "seconds"
              ? moment(startDate) // Use startDate here
                  .add(sumCount2Minutes, "minutes")
                  .format("DD/MM/YYYY") // Format to DD/MM/YYYY HH:mm:ss for seconds
              : moment(startDate) // Use startDate here
                  .add(sumCount2Minutes, "minutes")
                  .format("DD/MM/YYYY"); // Format to DD/MM/YYYY HH:mm for other units

          setStoredStartDateForHour(newStoredStartDate);
        }

        setStoredStartDate(
          moment(meeting.date).add(sumCount2, "days").format("YYYY-MM-DD")
        );
      }
    }
  }, [stepIndex]);

  useEffect(() => {
    getMeeting();
  }, [meetingId, show, storedStartTime]);

  const handleChange1 = (event) => {
    setTitle(event.target.value);
  };
  const handleIncrementCount = () => {
    setCount2((prevCount) => prevCount + 1);
  };

  const handleDecrementCount = () => {
    setCount2((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const [isValidate, setIsValidate] = useState(false);
  const validateStep = async () => {
    const Steps = [...(inputData?.steps || [])];

    // Check if step title is unique
    // const isUnique = Steps?.every(
    //   (item, index) => index === selectedIndex || item?.title !== step?.title
    // );
    // console.log('isUnique:', isUnique); // Debug log

    // if (!isUnique) {
    //   toast.error(t("messages.stepNames"));
    //   setIsValidate(false);
    //   return;
    // }

    if (!step?.title?.trim()) {
      toast.error(t("messages.stepTitle"));
      return;
    }

    if (count2 === 0) {
      toast.error(t("messages.stepTime"));
      return;
    }
    const optimizedEditorContent = await optimizeEditorContent(
      modifiedFileText
    );

    const payload = {
      ...step,
      title: title,
      count1: count2,
      count2: count2,
      time: count2,
      // time_unit: inputData?.type === "Action" ? "days" : "minutes",
      time_unit:
      meeting?.type === "Action1"
          ? "days"
          : meeting?.type === "Task"
          ? "hours"
          : meeting?.type === "Quiz"
          ? "seconds"
          : "minutes",
      editor_type: modalType,
      editor_content:
        modalType === "Editeur" ? optimizedEditorContent || "" : null,
      file: modalType === "File" ? (fileName ? fileName : null) : null,
      url: modalType === "Url" ? (link ? link : null) : null,
      assigned_to: user,
      status: "active",
      //   order_no: stepOrder,
      _method: "put",
      meeting_id: meetingId,
    };
    try {
      setIsValidate(true);
      const response = await axios.post(
        `${API_BASE_URL}/steps/${stepId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        console.log("step updated successfully", response.data.data);
        // await getMeeting();
        closeModal();
      }
    } catch (error) {
      console.log("error while updating step", error);
      closeModal();
    } finally {
      setIsValidate(false);
    }
  };
  const closeStepX = async () => {
    closeModal();

    // comment this for now

    // const Steps = [...(inputData?.steps || [])];
    // const isUnique = Steps?.every(
    //   (step, index) => index === selectedIndex || step?.title !== selectedValue
    // );

    // const optimizedEditorContent = await optimizeEditorContent(
    //   modifiedFileText
    // );

    // const payload = {
    //   ...step,
    //   title: step.title,
    //   count1: count2,
    //   count2: count2,
    //   time: count2,
    //   time_unit: timeUnit,
    //   editor_type: modalType,
    //   editor_content:
    //     modalType === "Editeur" ? optimizedEditorContent || "" : null,
    //   file: modalType === "File" ? (fileName ? fileName : null) : null,
    //   url: modalType === "Url" ? (link ? link : null) : null,

    //   status: "active",
    //   assigned_to: user,
    //   //   order_no: stepOrder,
    //   _method: "put",
    //   meeting_id: meetingId,
    // };
    // try {
    //   const response = await axios.post(
    //     `${API_BASE_URL}/steps/${stepId}`,
    //     payload,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //       },
    //     }
    //   );

    //   if (response.status) {
    //     console.log("step updated successfully", response.data.data);
    //     await getMeeting();
    //     closeModal();
    //   }
    // } catch (error) {
    //   console.log("error while updating step", error);
    //   closeModal();
    // }
  };
  const [isClose, setIsClose] = useState(false);
  const closeStep = async () => {
    // const newStoredStartTime = moment(time, "HH:mm")
    //   .add(selectedCount, "minutes")
    //   .format("hh:mm a");
    // console.log("newStoredStartTime--->", newStoredStartTime);
    // setStoredStartTime(newStoredStartTime);
    const Steps = [...(inputData?.steps || [])];
    const isUnique = Steps?.every(
      (step, index) => index === selectedIndex || step?.title !== selectedValue
    );
    if (!isUnique) {
      toast.error(t("messages.stepNames"));
      setIsClose(false);
      return;
    }

    if (!step?.title?.trim()) {
      toast.error(t("messages.stepTitle"));
      setIsClose(false);
      return;
    }
    if (count2 === 0) {
      toast.error(t("messages.stepTime"));
      setIsClose(false);
      return;
    }
    const optimizedEditorContent = await optimizeEditorContent(
      modifiedFileText
    );

    const payload = {
      ...step,
      title: title,
      count1: count2,
      count2: count2,
      time: count2,
      // time_unit: timeUnit,
      // time_unit: inputData?.type === "Action" ? "days" : "minutes",
      time_unit:
      meeting?.type === "Action1"
          ? "days"
          : meeting?.type === "Task"
          ? "hours"
          : meeting?.type === "Quiz"
          ? "seconds"
          : "minutes",
      editor_type: modalType,
      editor_content:
        modalType === "Editeur" ? optimizedEditorContent || "" : null,
      file: modalType === "File" ? (fileName ? fileName : null) : null,
      url: modalType === "Url" ? (link ? link : null) : null,
      assigned_to: user,
      status: "active",
      _method: "put",
      meeting_id: meetingId,
    };
    try {
      setIsClose(true);
      const response = await axios.post(
        `${API_BASE_URL}/steps/${stepId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status) {
        console.log("step updated successfully", response.data.data);
        await getMeeting();
        closeModal();
      }
    } catch (error) {
      console.log("error while updating step", error);
      closeModal();
    } finally {
      setIsClose(false);
    }
  };

  const [isDeleted, setIsDeleted] = useState(false);
  const deleteStep = async () => {
    try {
      setIsDeleted(true);

      const response = await axios.delete(`${API_BASE_URL}/steps/${stepId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      if (response.data) {
        toast.success(t("messages.stepDeleted"));
        // await getMeeting();
        closeModal();
      }
    } catch (error) {
      console.log("error while deleting step", error);
      closeModal();
    } finally {
      setIsDeleted(false);
    }
  };

  const [link, setLink] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  const handleLinkUpload = (event) => {
    setLink(event.target.value);
    setShowPreview(event.target.value);
    setShowPreview(true);
  };

  return (
    <>
      {show && (
        <div id="chart-container" className="chart-content">
          {/* {isModalOpen && selectedBar !== null && ( */}
          <div>
            <div className="modal-overlay">
              <div className="modal-content">
                <div className="modal-nav">
                  <div>{<h4>{t("meeting.newMeeting.Edit a step")}</h4>}</div>
                  <div className="d-flex justify-content-end">
                    <button
                      //   disabled={isDisabled}
                      className="cross-btn"
                      onClick={closeStepX}
                    >
                      <RxCross2 size={18} />
                    </button>
                  </div>
                </div>
                <>
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-7">
                      <div className="d-flex justify-content-arround align-items-center gap-4">
                        <div className="input-field">
                          <div
                            style={{
                              flexGrow: 1,
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            <img
                              src="/Assets/Vector.svg"
                              alt="Edit"
                              className="img-fluid edit-icon"
                            />
                          </div>
                          <div style={{ flexGrow: 9, textAlign: "center" }}>
                            <input
                              className="text-center step-name"
                              type="text"
                              placeholder={t("stepModal.title")}
                              value={title}
                              onChange={handleChange1}
                              disabled={
                                (meeting?.steps?.[stepIndex]?.participant
                                  ?.userPID !==
                                  parseInt(sessionStorage.getItem("user_id")) &&
                                  parseInt(
                                    sessionStorage.getItem("user_id")
                                  ) !== meeting?.user_id) ||
                                window.location.href.includes(
                                  "/meetingDetail"
                                ) ||
                                fromReport
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                        {/* <br /> */}
                        <select
                          className="form-select"
                          style={{
                            width: "13rem",
                          }}
                          value={
                            modalType === "Editeur"
                              ? "Editeur"
                              : modalType === "File"
                              ? "File"
                              : "Url"
                          }
                          onChange={(e) => setModalType(e.target.value)}
                          disabled={
                            fromReport ||
                            // (parseInt(sessionStorage.getItem("user_id")) !==
                            //   inputData?.user_id &&
                            //   !inputData?.steps[selectedIndex]?.userPID)
                            (meeting?.steps?.[stepIndex]?.participant
                              ?.userPID !==
                              parseInt(sessionStorage.getItem("user_id")) &&
                              parseInt(sessionStorage.getItem("user_id")) !==
                                meeting?.user_id)
                              ? true
                              : false
                          }
                        >
                          <option value={"Editeur"}>
                            {t("stepModal.editor")}
                          </option>
                          <option value={"File"}>{t("stepModal.pdf")}</option>
                          {/* <option value={"Url"}>Url</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 modal-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="text-center col-md-2 col-6">
                          <div className="p-2 card timecard">
                            <p>{t("meeting.newMeeting.The stage starts at")}</p>

                            {/* <h5>
                               
                                {storedStartTime === "Invalid date"
                                  ? "Date à compléter"
                                  : storedStartTime}
                              </h5> */}
                            {inputData?.type === "Action1" ? (
                              <>
                                <h6 style={{ fontSize: "14px" }}>
                                  {storedStartDate === "Invalid date"
                                    ? "Date à compléter"
                                    : storedStartDate}
                                </h6>
                              </>
                            ) : (
                              <>
                                <h5>
                                  {storedStartTime === "Invalid date"
                                    ? "Date à compléter"
                                    : storedStartTime}
                                </h5>
                                {storedStartDateForHour}
                              </>
                            )}
                          </div>
                          <br />
                          {!window.location.href.includes("/meetingDetail") && (
                            <div className="p-2 card timecard ">
                              <p>
                                {t(
                                  "meeting.newMeeting.Estimated time of the stage"
                                )}
                              </p>
                              <div className="d-flex justify-content-around">
                                <div>
                                  <img
                                    src="/Assets/minus1.svg"
                                    alt="minus"
                                    className="img-fluid "
                                    width={"15px"}
                                    style={{ cursor: "pointer" }}
                                    // onClick={handleDecrementCount}
                                    onClick={() => {
                                      if (
                                        fromReport ||
                                        // (parseInt(
                                        //   sessionStorage.getItem("user_id")
                                        // ) !== inputData?.user_id &&
                                        //   !step?.userPID)
                                        (meeting?.steps?.[stepIndex]
                                          ?.participant?.userPID !==
                                          parseInt(
                                            sessionStorage.getItem("user_id")
                                          ) &&
                                          parseInt(
                                            sessionStorage.getItem("user_id")
                                          ) !== meeting?.user_id)
                                          ? true
                                          : false
                                      )
                                        return;
                                      handleDecrementCount();
                                    }}
                                  />
                                  <span>{count2}</span>
                                  <img
                                    src="/Assets/plus1.svg"
                                    alt="plus"
                                    className="img-fluid"
                                    width={"15px"}
                                    style={{ cursor: "pointer" }}
                                    // onClick={handleIncrementCount}
                                    onClick={() => {
                                      if (
                                        fromReport ||
                                        // (parseInt(
                                        //   sessionStorage.getItem("user_id")
                                        // ) !== inputData?.user_id &&
                                        //   !step?.userPID)
                                        (meeting?.steps?.[stepIndex]
                                          ?.participant?.userPID !==
                                          parseInt(
                                            sessionStorage.getItem("user_id")
                                          ) &&
                                          parseInt(
                                            sessionStorage.getItem("user_id")
                                          ) !== meeting?.user_id)
                                          ? true
                                          : false
                                      ) {
                                        return;
                                      }
                                      handleIncrementCount();
                                    }}
                                  />
                                </div>
                                <div>
                                  {meeting?.type === "Action1" ? (
                                    <span> {t("days")} </span>
                                  ) : meeting?.type === "Task" ? (
                                    <span> {t("hours")} </span>
                                  ) : meeting?.type === "Quiz" ? (
                                    <span> {t("sec")} </span>
                                  ) : (
                                    <span> mins</span>

                                    // <select
                                    //   className="select-dropdown"
                                    //   value={timeUnit}
                                    //   onChange={(e) =>
                                    //     setTimeUnit(e.target.value)
                                    //   }
                                    // >
                                    //   {/* <option
                                    //     value="seconds"
                                    //     className="option-dropdown"
                                    //   >
                                    //     Seconds
                                    //   </option> */}
                                    //   <option
                                    //     value="minutes"
                                    //     className="option-dropdown"
                                    //     // selected
                                    //   >
                                    //     Minutes
                                    //   </option>
                                    //   {/* <option
                                    //     value="hours"
                                    //     className="option-dropdown"
                                    //   >
                                    //     Hours
                                    //   </option> */}
                                    // </select>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="p-2 mt-3 card timecard">
                            <p>Guide</p>

                            {/* <label className="form-label">
                                {assignUser === null
                                  ? 
                                  `${inputData?.user?.name} ${
                                      inputData?.user?.last_name !== null
                                        ? inputData?.user?.last_name
                                        : " "
                                    }`
                                  : assignUser}
                              </label> */}

                            {meeting?.user_with_participants?.length > 0 && (
                              // {filteredParticipantsLength > 0 &&
                              // !filteredParticipants?.every(
                              //   (participant) => participant?.isCreator === 1
                              // ) &&
                              //  (
                              <select
                                hidden={fromReport}
                                className="select"
                                value={user}
                                onChange={handleUserSelect}
                                disabled={
                                  window.location.href.includes(
                                    "/meetingDetail"
                                  ) ||
                                  fromReport ||
                                  // (parseInt(
                                  //   sessionStorage.getItem("user_id")
                                  // ) !== inputData?.user_id &&
                                  //   !step?.userPID)
                                  (meeting?.steps?.[stepIndex]?.participant
                                    ?.userPID !==
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) &&
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) !== meeting?.user_id)
                                    ? true
                                    : false
                                }
                              >
                                {/* <option value="">
                              {participants?.length === 0
                                ? t("No Guests Available")
                                : t("meeting.newMeeting.Select Guests")}
                            </option> */}
                                <option value="" disabled>
                                  {meeting?.user_with_participants?.length === 0
                                    ? t("No Guests Available")
                                    : t("meeting.newMeeting.Select Guests")}
                                </option>
                                {meeting?.user_with_participants
                                  //  ?.filter(
                                  //   (participant) =>
                                  //     `${participant.first_name} ${participant.last_name}` !== assignUser
                                  // )
                                  ?.reduce((uniqueParticipants, item) => {
                                    const isDuplicate = uniqueParticipants.some(
                                      (participant) =>
                                        participant.first_name ===
                                          item.first_name &&
                                        participant.last_name ===
                                          item.last_name &&
                                        participant.email === item.email &&
                                        participant.post === item.post
                                    );

                                    if (!isDuplicate) {
                                      uniqueParticipants.push(item);
                                    }
                                    return uniqueParticipants;
                                  }, [])
                                  .map((item, index) => {
                                    // if (item?.isCreator === 1) {
                                    //   return;
                                    // }
                                    return (
                                      <>
                                        {(item.first_name === null) &
                                          (item.last_name === null) &&
                                        item.email === null &&
                                        item.post === null ? (
                                          <>
                                            <option value="" disabled>
                                              {t(
                                                "meeting.newMeeting.No Guest Available"
                                              )}
                                            </option>
                                          </>
                                        ) : (
                                          <option key={index} value={item.id}>
                                            {/* {item.first_name} */}
                                            {`${item.first_name} ${item.last_name}`}
                                          </option>
                                        )}
                                      </>
                                    );
                                  })}

                                {/* {inputData?.participants &&
                              inputData?.participants?.map((item, index) => (
                                <>
                                  {(item.first_name === null) &
                                    (item.last_name === null) &&
                                  item.email === null &&
                                  item.post === null ? (
                                    <>
                                      <option value="" disabled>
                                        {t(
                                          "meeting.newMeeting.No Guest Available"
                                        )}
                                      </option>
                                    </>
                                  ) : (
                                    <option key={index} value={item.id}>
                                      {`${item.first_name} ${item.last_name}`}
                                    </option>
                                  )}
                                </>
                              ))} */}
                              </select>
                            )}
                          </div>
                          <br />
                          <div className="mt-2">
                            {isDeleted ? (
                              <>
                                <Button
                                  variant="dark"
                                  disabled
                                  style={{
                                    backgroundColor: "#3aa5ed",
                                    border: "none",
                                  }}
                                  className="w-100 mt-3"
                                >
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </Button>
                              </>
                            ) : (
                              <button
                                className="btn btn-danger"
                                onClick={deleteStep}
                                style={{ width: "100%" }}
                                disabled={
                                  // parseInt(
                                  //   sessionStorage.getItem("user_id")
                                  // ) !== inputData?.user_id && !step?.userPID
                                  meeting?.steps?.[stepIndex]?.participant
                                    ?.userPID !==
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) &&
                                  parseInt(
                                    sessionStorage.getItem("user_id")
                                  ) !== meeting?.user_id
                                    ? true
                                    : false
                                }
                              >
                                {t("meeting.chart.buttons.delete")}
                              </button>
                            )}
                          </div>
                          <br />

                          <div>
                            {isValidate ? (
                              <Button
                                variant="dark"
                                disabled
                                style={{
                                  backgroundColor: "#3aa5ed",
                                  border: "none",
                                }}
                                className="w-100 mt-3"
                              >
                                <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"
                                />
                              </Button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                onClick={validateStep}
                                style={{ width: "100%" }}
                                disabled={
                                  // parseInt(
                                  //   sessionStorage.getItem("user_id")
                                  // ) !== inputData?.user_id && !step?.userPID
                                  meeting?.steps?.[stepIndex]?.participant
                                    ?.userPID !==
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) &&
                                  parseInt(
                                    sessionStorage.getItem("user_id")
                                  ) !== meeting?.user_id
                                    ? true
                                    : false
                                }
                              >
                                {t("meeting.chart.buttons.validate")}
                              </button>
                            )}
                          </div>
                          <br />
                          <div className="mt-2">
                            {isClose ? (
                              <>
                                <Button
                                  variant="dark"
                                  disabled
                                  style={{
                                    backgroundColor: "#3aa5ed",
                                    border: "none",
                                  }}
                                  className="w-100 mt-3"
                                >
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </Button>
                              </>
                            ) : (
                              <button
                                className="btn btn-danger"
                                onClick={closeStep}
                                style={{ width: "100%" }}
                              >
                                {t("meeting.chart.buttons.cancel")}
                              </button>
                            )}
                          </div>
                        </div>
                        {modalType === "Editeur" ? (
                          <div className="col-md-10">
                            {/* {inputData?.steps?.map((step, index) => ( */}
                            <div
                            // key={index}
                            // style={{
                            //   display:
                            //     index === selectedIndex ? "block" : "none",
                            // }}
                            >
                              <Editor
                                disabled={
                                  fromReport ||
                                  // (parseInt(
                                  //   sessionStorage.getItem("user_id")
                                  // ) !== inputData?.user_id &&
                                  //   !step?.userPID)
                                  (meeting?.steps?.[stepIndex]?.participant
                                    ?.userPID !==
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) &&
                                    parseInt(
                                      sessionStorage.getItem("user_id")
                                    ) !== meeting?.user_id)
                                    ? true
                                    : false
                                }
                                // apiKey="d37lz7euudv3qj0gxw0v2ki9hxit30psx226v35l2v6y7nlv"
                                apiKey={TINYMCEAPI}
                                value={modifiedFileText}
                                name="text"
                                init={{
                                  statusbar: false,
                                  branding: false,
                                  height: 600,
                                  menubar: true,
                                  language: "fr_FR",
                                  // language: "en_EN",
                                  plugins:
                                    "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                  toolbar:
                                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | image | imagePicker link media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                  image_advtab: true,
                                  file_picker_types: "image",

                                  file_picker_callback: function (
                                    callback,
                                    value,
                                    meta
                                  ) {
                                    if (meta.filetype === "image") {
                                      const input =
                                        document.createElement("input");
                                      input.setAttribute("type", "file");
                                      input.setAttribute("accept", "image/*");

                                      input.onchange = function () {
                                        const file = input.files[0];
                                        const reader = new FileReader();

                                        reader.onload = function (e) {
                                          const img = new Image();
                                          img.src = e.target.result;

                                          img.onload = function () {
                                            const canvas =
                                              document.createElement("canvas");
                                            const ctx = canvas.getContext("2d");
                                            const maxWidth = 700;
                                            const maxHeight = 394;

                                            let newWidth = img.width;
                                            let newHeight = img.height;

                                            if (img.width > maxWidth) {
                                              newWidth = maxWidth;
                                              newHeight =
                                                (img.height * maxWidth) /
                                                img.width;
                                            }

                                            if (newHeight > maxHeight) {
                                              newHeight = maxHeight;
                                              newWidth =
                                                (img.width * maxHeight) /
                                                img.height;
                                            }

                                            canvas.width = newWidth;
                                            canvas.height = newHeight;

                                            ctx.drawImage(
                                              img,
                                              0,
                                              0,
                                              newWidth,
                                              newHeight
                                            );

                                            const resizedImageData =
                                              canvas.toDataURL(file.type);

                                            // Pass the resized image data to the callback function
                                            callback(resizedImageData, {
                                              alt: file.name,
                                            });
                                          };

                                          img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                      };

                                      input.click();
                                    }
                                  },
                                }}
                                //   onEditorChange={(content) => {
                                //     const updatedModifiedFileText = [
                                //       ...modifiedFileText,
                                //     ];
                                //     updatedModifiedFileText[index] = content;
                                //     setModifiedFileText(updatedModifiedFileText);
                                //   }}
                                onEditorChange={(content) => {
                                  setModifiedFileText(content);
                                }}
                              />
                            </div>
                            {/* ))} */}
                            <div></div>
                          </div>
                        ) : modalType === "File" ? (
                          <>
                            <div className="col-md-10">
                              {!isUpload ? (
                                <>
                                  {/* <div className="d-flex align-items-center gap-4">
                                  <input
                                    accept=".pdf,.xlsx,.xls,.ppt,.pptx,.doc,.docx"
                                    type="file"
                                    placeholder="https://www.google.com"
                                    onChange={(e) => {
                                      handleFileUpload(e);
                                      e.target.value = "";
                                    }}
                                    style={{
                                      border: "1px solid #cccccc",
                                      padding: "5px 7px",
                                      width: "auto",
                                      borderRadius: "6px",
                                      outline: "none",
                                    }}
                                  />
                                  {fileName && (
                                    <div>Selected file: {fileName}</div>
                                  )}
                                </div>
                                <div>
                                  <div className="pdf-preview">
                                    {fileName !== "" && fileName !== null && (
                                      <iframe
                                        title="PDF Preview"
                                        src={Assets_URL + fileName}
                                        width="100%"
                                        height="500px"
                                      />
                                    )}
                                  </div>
                                </div> */}
                                  <div
                                    className={`d-flex align-items-center gap-4 ${
                                      fileName ? "" : "h-100"
                                    }`}
                                  >
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        border: "1px solid #cccccc",
                                        padding: "5px 7px",
                                        width: fileName ? "auto" : "100%", // Set width to auto when a file is uploaded
                                        borderRadius: "6px",
                                        outline: "none",
                                        margin: fileName ? "" : "0 auto",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <input {...getInputProps()} />
                                      {isUpload ? (
                                        <p>Uploading...</p>
                                      ) : fileName ? (
                                        <div>Selected file: {fileName}</div>
                                      ) : (
                                        <p
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "inherit",
                                          }}
                                        >
                                          Drag 'n' drop files here, or click to
                                          select files
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  {fileName && (
                                    <div className="mt-2">
                                      <div className="pdf-preview">
                                        <iframe
                                          title="PDF Preview"
                                          // src={Assets_URL + fileName}
                                          src={`${Assets_URL}/${fileName}#toolbar=0&view=fitH`}
                                          width="100%"
                                          height="500px"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Spinner
                                    animation="border"
                                    role="status"
                                    className="center-spinner"
                                  ></Spinner>
                                </>
                              )}
                            </div>
                          </>
                        ) : modalType === "Url" ? (
                          <>
                            <div className="col-md-10">
                              <div className="box">
                                <input
                                  type="text"
                                  placeholder="https://www.google.com"
                                  value={link}
                                  onChange={handleLinkUpload}
                                  // width={100}
                                  style={{ width: "50%" }}
                                  name="url"
                                />
                                {/* <div className="text-center">
                                    <button
                                      disabled={isDisabled}
                                      className="my-3 btn btn-danger"
                                      onClick={previewUrlResult}
                                    >
                                      Afficher la page
                                    </button>
                                  </div> */}
                                {/* {showPreview && ( */}
                                <div className="preview-container mt-3">
                                  <iframe
                                    title="Preview"
                                    src={link}
                                    width="100%"
                                    height="500px"
                                  />
                                </div>
                                {/* )} */}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      )}
    </>
  );
};

export default InviteStepChartModal;
